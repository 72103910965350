
import {ErrorResponse} from "@/types/types"
import axios, {AxiosResponse} from "axios"
import {Component, Vue} from 'vue-property-decorator'

@Component
export default class SupportLayout extends Vue {
    async resendVerify(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'email/resendVerify');
        await axios.post<void | ErrorResponse>(
            url,
            null,
            {validateStatus: (): boolean => true}
        )
            .then<void, never>((res: AxiosResponse<void | ErrorResponse>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.$store.state.verifySent = true
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }

    get showAlert(): boolean {
        return !this.$store.state.verifySent &&
            this.$store.getters['Users/hasUser'] &&
            this.$store.getters['Users/hasEmail'] &&
            !this.$store.getters['Users/hasVerifyEmail']
    }
}
