
import axios, {AxiosResponse} from "axios";
import { Component, Vue, Prop } from "vue-property-decorator"
import TableItem from "@/interfaces/TableItem";
import TreeView from "@/components/Tree/TreeView.vue";

@Component({
    components: {TreeView}
})
export default class TDAddTableCategory extends Vue {
    @Prop() tableId?: number;

    static componentName = "TDAddTableCategory"

    creating = false;
    treeData: any = null;
    selectedNodes: string[] = [];

    treeModalOpened: boolean = false;

    resultModalOpened = false;
    resultModalText = "";

    get icon(): string {
        return this.creating ? 'three-dots' : 'columns-gap'
    }

    get animation(): string {
        return this.creating ? 'cylon' : ''
    }

    get buttonText(): string {
        return this.creating ? 'Категории добавляются' : 'Добавить категории';
    }

    get categoriesLabel(): string {
        return this.treeData ? 'Категории:' : 'Список категорий загружается...';
    }

    get isCreateButtonDisabled(): boolean {
        return this.selectedNodes.length === 0;
    }

    async addCategory() {
        if (this.creating) {
            return null
        }
        this.creating = true;

        this.showResultModal("Подождите, пока добавляются новые категории");

        const params = {
            categories: this.selectedNodes,
            tableId: this.tableId
        };

        let result: (TableItem | null) = null;
        const url = await this.$store.dispatch('getFullUrl', 'tables/gapi/add-category');
        await axios.post(
            url + this.$store.getters.hashQuery,
            params,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<TableItem>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        result = res.data;
                        this.showResultModal("Категории успешно добавлены");
                        break;
                    case 404:
                        this.showResultModal("Таблица не найдена");
                        break;
                    case 422:
                        this.showResultModal("Ошибка в запросе");
                        break;
                    case 500:
                        this.showResultModal("Произошла ошибка при добавлении категорий, попробуйте еще раз");
                        break;
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.creating = false;
            })
        return result;
    }

    async openTreeModal() {
        this.selectedNodes = [];
        this.treeData = null;
        this.treeModalOpened = true;
        await this.fetchTreeData();
    }

    async fetchTreeData() {
        try {
            const response = await axios.get(
                await this.$store.dispatch("getFullUrl", "tables/gapi/available/" + this.tableId)
            );
            this.treeData = response.data;
        } catch (error) {
            console.error('Error fetching tree data:', error);
        }
    }

    showResultModal(message: string) {
        this.resultModalText = message;
        this.resultModalOpened = true;
    }

    handleSelectTreeCategory(data) {
        console.log(data)

        if (data.checked) {
            if (!this.selectedNodes.includes(data.category_id)) {
                this.selectedNodes.push(data.category_id);
            }
        } else {
            const index = this.selectedNodes.indexOf(data.category_id);
            if (index !== -1) {
                this.selectedNodes.splice(index, 1);
            }
        }
    }
};
