
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDAddTag extends Vue {
    @Prop() userId?: number;

    static componentName = "TDAddTag"

    get disabledButton(): boolean {
        return this.$store.getters['Tags/issetTagsAvitoNewTag'];
    }

    addTag(): void {
        this.$store.dispatch('Tags/addEmptyTag');
        this.$emit('add-empty-tag');
    }
}
