
import { invitation } from "@/types/invitations"
import axios, { AxiosResponse } from "axios"
import { BvModal } from "bootstrap-vue"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDDiscount extends Vue {
    @Prop() item!: invitation;

    static componentName = "TDDiscount"

    wait = false
    $bvModal!: BvModal

    get icon(): string {
        return this.wait ? 'three-dots' : 'pencil-square'
    }

    get animation(): string {
        return this.wait ? 'cylon' : ''
    }

    get wrongClass(): string {
        return (
            !/\d+/.test(String(this.item.discount)) ||
            ((this.item.income - this.item.discount) < 0)
        ) ? 'text-danger' : ''
    }

    async onSubmit(): Promise<void> {
        const { discount } = this.item
        const url = await this.$store.dispatch('getFullUrl', 'users/invitations/' + this.item.invitationHash);
        await axios.put<void>(
            url,
            { discount },
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void>) => {
                const statusCode = res.request.status;
                switch (statusCode) {
                    case 200:
                        this.$bvModal.msgBoxOk(
                            'Изменено.',
                            {
                                centered: true
                            }
                        )
                        break
                    case 400:
                        this.$bvModal.msgBoxOk(
                            'Скидка не может быть больше максимального процента от вашего статуса!',
                            {
                                centered: true,
                                bodyTextVariant: 'danger'
                            }
                        )
                        break
                    case 422:
                        this.$bvModal.msgBoxOk(
                            'Введите корректное значение!',
                            {
                                centered: true,
                                bodyTextVariant: 'danger'
                            }
                        )
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }
}
