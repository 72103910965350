
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class AvitoAuthPage extends Vue {
    redirectPath = '';

    get showRedirect(): boolean {
        return this.redirectPath !== '';
    }

    created(): void {
        document.title = 'Авторизация приложения Авито'

        const state = this.$route.query.state;
        const code = this.$route.query.code;

        if (state && code && (state === 'messengers')) {
            this.redirectPath = '/messengers?code=' + code;

            this.redirect();
        }
    }

    redirect(): void {
        if (this.redirectPath) {
            window.location.replace(this.redirectPath);
        }
    }
}
