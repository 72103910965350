
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoRepubRule_v2 from "@/interfaces/AvitoRepubRule_v2";

@Component
export default class TDAvitoRepubEnabled_v2 extends Vue {
    @Prop() item!: AvitoRepubRule_v2

    static componentName = 'TDAvitoRepubEnabled_v2'
}
