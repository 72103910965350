import { render, staticRenderFns } from "./KontaktPage.vue?vue&type=template&id=4c3dc798&scoped=true"
import script from "./KontaktPage.vue?vue&type=script&lang=ts"
export * from "./KontaktPage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c3dc798",
  null
  
)

export default component.exports