
import TableHeaderComponent from "@/components/TableList/TableHeaderComponent.vue";
import TableItemComponent from "@/components/TableList/TableItemComponent.vue"
import TableColumn from "@/interfaces/TableColumn"
import { invitation } from "@/types/invitations"
import { referral } from "@/types/referrals"
import { transaction } from "@/types/transactions"
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        TableHeaderComponent,
        TableItemComponent,
    },
})
export default class TableList extends Vue {
    @Prop() columns!: TableColumn[];
    @Prop() items!: transaction[] | referral[] | invitation[];
    // @ts-expect-error whatever
    @Prop() summary: [keyof transaction | keyof referral | keyof invitation];
    @Prop() hideTableHead?: boolean;
    @Prop() perPage?: number;
    @Prop() currentPage?: number;

    get itemsPaginated(): transaction[] | referral[] | invitation[] {
        if (!!this.perPage && !!this.currentPage && (this.perPage < this.items.length)) {
            const start = (this.currentPage - 1) * this.perPage
            const end = start + this.perPage
            const items = this.items.slice(start, end)

            /*if (items.length < this.perPage) {
                const stub = Array(this.perPage - items.length).fill([])
                return [ ...items, ...stub]
            }*/
            return items
        }

        return this.items
    }

    summaryValue(column: TableColumn): number | void {
        const index = this.summary.findIndex((needle) => needle === column.code)
        if (index === -1) {
            return
        }

        const needle = this.summary[index]

        return this.items
            .map((item) => item[needle])
            .reduce((previousValue: number, currentValue: number): number => {
                previousValue += currentValue
                return previousValue
            })
    }
}
