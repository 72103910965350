
import { Component, Vue, Prop } from "vue-property-decorator"
import TableItem from "@/interfaces/TableItem";

@Component
export default class TDEditAvitoRules extends Vue {
    @Prop() item!: TableItem;

    static componentName = 'TDEditAvitoRules'

    showModalRepub = false;
    showModalImagesTransform = false;

    clickRepub() {
        const avitoGenerator = this.item.generators?.find(generator => generator.targetPlatform === 'Avito');
            if (avitoGenerator && avitoGenerator.maxAds > 100) {
                this.$store.commit("AvitoRepub/SET_SELECTED_TABLE_ID", this.item.tableId);
                this.$router.push({ name: 'avito-repub' });
            } else {
                this.showModalRepub = true;
            }
    }

    clickRepub_v2() {
        const avitoGenerator = this.item.generators?.find(generator => generator.targetPlatform === 'Avito');
        if (this.$store.getters['Users/userIsPremium'] || (avitoGenerator && avitoGenerator.maxAds > 100)) {
            this.$store.commit("AvitoRepub_v2/SET_SELECTED_TABLE_ID", this.item.tableId);
            const routeURL = this.$router.resolve({ name: 'avito-repub-v2', params: { table_id: String(this.item.tableId) }}).href;
            window.open(routeURL, '_blank');
        } else {
            this.showModalRepub = true;
        }
    }

    clickImagesTransform() {
        const avitoGenerator = this.item.generators?.find(generator => generator.targetPlatform === 'Avito');
        if (this.$store.getters['Users/userIsPremium'] || (avitoGenerator && avitoGenerator.maxAds > 100)) {
            const routeURL = this.$router.resolve({ name: 'images-transform', params: { table_id: String(this.item.tableId) }}).href;
            window.open(routeURL, '_blank');
        } else {
            this.showModalImagesTransform = true;
        }
    }

    resetModal() {
      this.showModalRepub = false;
      this.showModalImagesTransform = false;
    }
}
