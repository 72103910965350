import { render, staticRenderFns } from "./SmsSendPage.vue?vue&type=template&id=3dd71ad6&scoped=true"
import script from "./SmsSendPage.vue?vue&type=script&lang=ts"
export * from "./SmsSendPage.vue?vue&type=script&lang=ts"
import style0 from "./SmsSendPage.vue?vue&type=style&index=0&id=3dd71ad6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dd71ad6",
  null
  
)

export default component.exports