
import { Component, Vue, Prop } from "vue-property-decorator"
import User from "@/interfaces/User";
import axios, { AxiosResponse } from "axios";

@Component
export default class TDArchiveUser extends Vue {
    @Prop() item!: User;

    static componentName = "TDArchiveUser"

    wait = false;

    get animation(): string {
        return this.wait ? 'cylon' : ''
    }

    async archiveUser(userId: number): Promise<void> {
        if (this.wait) {
            return
        }
        this.wait = true;

        const user = this.item
        user.isBlocked = true

        const url = await this.$store.dispatch('getFullUrl', 'users/' + userId);
        await axios.put<void>(
            url + this.$store.getters.hashQuery,
            user,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void>) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    this.$store.dispatch('UwT/filterUsersInUsersWithTables', userId)
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.wait = false)
    }
}
