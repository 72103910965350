
import { Vue, Component } from "vue-property-decorator";
import AvitoRepubHistoryTableListComponent_v2 from "@/components/TableList/AvitoRepubHistoryTableListComponent_v2.vue";
import TableColumn from "@/interfaces/TableColumn";
import AvitoRepubHistory_v2 from "@/interfaces/AvitoRepubHistory_v2";
import axios, {AxiosResponse} from "axios";

@Component({
    components: {
        AvitoRepubHistoryTableListComponent_v2
    }
})
export default class AvitoRepubPage_v2 extends Vue {
    columns: TableColumn[] = [];
    currentPage = 1;
    perPage = 100;
    perPageVariants = [
        { active: true, perPage: 100 },
        { active: false, perPage: 500 },
        { active: false, perPage: 1000 }
    ];
    searchedText = "";
    filterText = "";
    loading = false;
    timeout?: number;
    history?: AvitoRepubHistory_v2[];

    get icon(): string {
        return this.loading ? "arrow-clockwise" : "search";
    }

    get animation(): string {
        return this.loading ? "spin" : "";
    }

    get rows(): number {
        return this.filteredHistory ? this.filteredHistory.length : 0;
    }

    get filteredHistory(): AvitoRepubHistory_v2[] | undefined {
        if (!this.history) {
            return undefined;
        }

        let filteredHistory = this.history;

        if (this.filterText !== "") {
            filteredHistory = filteredHistory.filter((item): boolean => {
                if (!item) {
                    return false;
                }

                const { avito_id, ad_id, new_id } = item;

                const avitoIdString = avito_id ? avito_id.toString() : '';
                const adIdLowerCase = ad_id ? ad_id.toLowerCase() : '';
                const newIdLowerCase = new_id ? new_id.toLowerCase() : '';

                return (
                    (!!avitoIdString && avitoIdString.includes(this.filterText)) ||
                    (!!adIdLowerCase && adIdLowerCase.includes(this.filterText)) ||
                    (!!newIdLowerCase && newIdLowerCase.includes(this.filterText))
                );
            });
        }

        return filteredHistory;
    }


    search(): void {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            setTimeout(() => (this.loading = true));

            setTimeout(() => {
                this.filterText = this.searchedText.trim().toLowerCase();

                this.loading = false;
            });
        }, 1000);
    }


    async created(): Promise<void> {
        document.title = "История перепубликации объявлений Авито 2.0";

        this.$store.commit("SET_LOADING", true);

        const url = await this.$store.dispatch('getFullUrl', 'avito-repub_v2/report/'
            + this.$route.params.rule_id);
        await axios.get(
            url + this.$store.getters.hashQuery)
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    this.history = res.data;
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => {
                console.error(err);
                this.$router.push({ name: 'autoload'});
            })
            .finally(() => {
                this.$store.commit("SET_LOADING", false);
            })
    }


    changePaginate(variant: { active: boolean; perPage: number }): void {
        this.perPageVariants.forEach((variant) => (variant.active = false));
        this.perPage = variant.perPage;
        variant.active = true;
    }
}
