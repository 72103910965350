import RootState from "@/interfaces/RootState"
import AvitoMonitoringState from "@/interfaces/AvitoMonitoringState"
import AvitoMonitoringTask from "@/interfaces/AvitoMonitoringTask";
import axios, { AxiosResponse } from "axios"
import _ from "lodash-es";
import { ActionContext, Module } from "vuex"

const AvitoMonitoring: Module<AvitoMonitoringState, RootState> = {
    namespaced: true,
    state: {
        projectId: null,
        editedTaskId: null,
        validationError: false,
        avitoMonitoringTasks: [] as AvitoMonitoringTask[],
    },
    mutations: {
        SET_AVITO_MONITORING_TASKS(state: AvitoMonitoringState, avitoMonitoringTasks: AvitoMonitoringTask[]) {
            state.avitoMonitoringTasks = avitoMonitoringTasks
        },
        SET_AVITO_MONITORING_PROJECT_ID(state: AvitoMonitoringState, payload: number): void {
            state.projectId = payload
        },
        SET_AVITO_MONITORING_EDITED_TASK_ID(state: AvitoMonitoringState, payload: number | null): void {
            state.editedTaskId = payload
        },
    },
    actions: {
        createTask(
            { commit }: ActionContext<AvitoMonitoringState, RootState>
        ): void {
            commit('SET_AVITO_MONITORING_EDITED_TASK_ID', 0)
        },
        async setTasks(
            { commit, dispatch, state }: ActionContext<AvitoMonitoringState, RootState>
        ): Promise<void> {
            const url = await dispatch('getFullUrl', 'monitoring/tasks/' + state.projectId,
                { root: true });
            await axios.get<AvitoMonitoringTask[]>(
                url,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse<AvitoMonitoringTask[]>) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 201:
                            console.log(res.data);
                            commit('SET_AVITO_MONITORING_TASKS', res.data)
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        },
        async editTask(
            { commit, dispatch, state }: ActionContext<AvitoMonitoringState, RootState>,
            taskData: AvitoMonitoringTask
        ): Promise<void> {
            const url = await dispatch('getFullUrl', 'monitoring/tasks', { root: true });

            state.validationError = false;

            await axios.post<void>(
                url,
                taskData,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse) => {
                    const statusCode = res.request.status;
                    if (statusCode === 201) {
                        if (res.data.success) {
                            commit('SET_AVITO_MONITORING_EDITED_TASK_ID', null);
                            dispatch('setTasks');
                        } else {
                            state.validationError = true;
                            console.log(res.data.response);
                        }
                    } else {
                        commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        },
        async deleteTask(
            { commit, dispatch, state }: ActionContext<AvitoMonitoringState, RootState>,
            taskId: number
        ): Promise<void> {
            const url = await dispatch('getFullUrl', 'monitoring/tasks/' + taskId,
                { root: true });

            await axios.delete(
                url,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 201:
                            dispatch('setTasks');
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        }
    },
    getters: {
        getTasks(state: AvitoMonitoringState): AvitoMonitoringTask[] {
            return state.avitoMonitoringTasks
        },
        getTasksCount(state: AvitoMonitoringState): number {
            return state.avitoMonitoringTasks.length
        },
        getEditedTaskData(state: AvitoMonitoringState): AvitoMonitoringTask | undefined {
            return _.find(state.avitoMonitoringTasks, {id: state.editedTaskId});
        },
        getProjectId(state: AvitoMonitoringState): number | null {
            return state.projectId
        },
        getEditedTaskId(state: AvitoMonitoringState): number | null {
            return state.editedTaskId
        },
        getValidationError(state: AvitoMonitoringState): boolean {
            return state.validationError
        },
    }
}

export default AvitoMonitoring
