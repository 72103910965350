
import {Vue, Component, Prop} from 'vue-property-decorator';
import axios, {AxiosResponse} from 'axios';
import moment from 'moment';
import TreeView from "@/components/Tree/TreeView.vue";
import TableItem from "@/interfaces/TableItem";

@Component({
    components: {
        TreeView
    }
})
export default class TDAddTable_v4 extends Vue {
    @Prop() userId?: number;

    static componentName = "TDAddTable_v4"

    creating = false;
    treeData: any = null;
    selectedNodes: string[] = [];
    tableName = "";

    treeModalOpened: boolean = false;

    resultModalOpened = false;
    resultModalText = "";

    get icon(): string {
        return this.creating ? 'three-dots' : 'clipboard-plus'
    }

    get animation(): string {
        return this.creating ? 'cylon' : ''
    }

    get buttonText(): string {
        if (this.creating) {
            return 'Таблица создается';
        }

        switch (this.$router.currentRoute.name) {
            case 'tables':
                return 'Создать таблицу'
            case 'users':
                return 'Создать таблицу + Google'
            default:
                return 'Создать таблицу'
        }
    }

    get categoriesLabel(): string {
        return this.treeData ? 'Категории:' : 'Список категорий загружается...';
    }

    get isCreateButtonDisabled(): boolean {
        return !this.tableName || this.selectedNodes.length === 0;
    }

    async openTreeModal() {
        this.selectedNodes = [];
        this.treeData = null;
        this.tableName = 'ADVIZ ' + moment().format('DD.MM.YYYY HH:mm:ss');
        this.treeModalOpened = true;
        await this.fetchTreeData();
    }

    async fetchTreeData() {
        try {
            const response = await axios.get(
                await this.$store.dispatch("getFullUrl", "tables/gapi/available")
            );
            this.treeData = response.data;
        } catch (error) {
            console.error('Error fetching tree data:', error);
        }
    }

    async confirmTreeModal() {
        this.treeModalOpened = false;
        await this.createTable();
    }

    async createTable() {
        if (this.creating) {
            return null
        }
        this.creating = true;

        this.showResultModal("Подождите, пока таблица создается, это может занять некоторое время");

        const gapiParams = {
            categories: this.selectedNodes,
            tableName: this.tableName,
        };

        let userId = this.userId;
        const url = await this.$store.dispatch('getFullUrl', 'tables');
        const userIdQuery = userId ? "userId=" + userId : ''
        const querySymbol = this.$store.getters.hashQuery ? '&' : '?'

        await axios.post(
            url + this.$store.getters.hashQuery + querySymbol + userIdQuery,
            { gapi: gapiParams },
            { validateStatus: (): boolean => true }
        )
            .then<void, never>(async (res: AxiosResponse<TableItem>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 201:
                        let result = res.data;

                        if (!result) {
                            this.showResultModal("Произошла ошибка при создании таблицы");
                            return
                        }

                        if (!userId) {
                            await this.$store.dispatch('addNewTable', result)
                        } else {
                            await this.$store.dispatch('UwT/addTableInUsersWithTables', { userId: userId, newTable: result })
                        }

                        this.showResultModal("Таблица успешно создана");

                        break;
                    case 403:
                        this.$store.state.alert = this.$store.state.text.tooManyTables
                        setTimeout(() => this.$store.state.alert = '', 5000)
                        this.showResultModal("Создано максимальное количество таблиц");
                        break;
                    case 422:
                        this.showResultModal("Ошибка в запросе");
                        break;
                    case 500:
                        this.showResultModal("Произошла ошибка при создании таблицы, попробуйте еще раз");
                        break;
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.creating = false;
                this.$store.state.newTableCreated = true;
            })
    }

    showResultModal(message: string) {
        this.resultModalText = message;
        this.resultModalOpened = true;
    }

    handleSelectTreeCategory(data) {
        if (data.checked) {
            if (!this.selectedNodes.includes(data.category_id)) {
                this.selectedNodes.push(data.category_id);
            }
        } else {
            const index = this.selectedNodes.indexOf(data.category_id);
            if (index !== -1) {
                this.selectedNodes.splice(index, 1);
            }
        }
    }
}
