
import { Component, Vue, Prop } from "vue-property-decorator"
import Tag from "@/interfaces/Tag";
import axios, { AxiosResponse } from "axios";

@Component
export default class TDSaveTag extends Vue {
    @Prop() item!: Tag;

    static componentName = "TDSaveTag"

    wait = false;

    saveTarError = '';

    async saveTag(id: number): Promise<void> {
        if (this.wait) {
            return
        }
        this.wait = true;

        const tag = this.item

        const url = await this.$store.dispatch('getFullUrl', 'tags/avito');
        await axios.put<void>(
            url + this.$store.getters.hashQuery,
            tag,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void>) => {
                const statusCode = res.request.status
                if (statusCode === 201) {
                    var response = JSON.parse(res.request.response);
                    if (response.status == 'error') {
                        this.saveTarError = response.text;
                        this.$bvModal.show('saveTagError' + id);
                    } else {
                        this.$store.dispatch('Tags/setTagsAvito');
                    }
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.wait = false)
    }

    async deleteTag(id: number): Promise<void> {
        if (this.wait) {
            return;
        }
        this.wait = true;

        if (id === 0) {
            this.$store.dispatch('Tags/setTagsAvito');
            return;
        }

        const url = await this.$store.dispatch('getFullUrl', 'tags/avito/' + id);
        await axios.delete(
            url + this.$store.getters.hashQuery)
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                if (statusCode === 201) {
                    this.$store.dispatch('Tags/setTagsAvito');
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.wait = false)
    }
}
