export default [
    {
        name: 'id',
        code: 'userId',
        class: 'd-flex align-items-center justify-content-center text-my-primary'
    },
    {
        name: 'Имя',
        code: 'adminUserName',
    },
    {
        name: 'Роль',
        code: 'roleId',
    },
    {
        name: 'Почта',
        code: 'email',
    },
    {
        name: 'Создан',
        code: 'created_at',
    },
    {
        name: 'Обновлен',
        code: 'updated_at',
    },
    {
        name: 'Баланс',
        code: 'balanceTransaction',
    }
]
