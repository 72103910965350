export default [
    {
        name: 'Дата и время',
        code: 'created_at',
    },
    {
        name: 'Тип',
        code: 'type',
    },
    {
        name: 'Таблица',
        code: 'table',
    },
    {
        name: 'Сумма',
        code: 'amount',
        class: 'coin d-flex align-items-center justify-content-end',
    },
]
