import TypeResolver from "@/helpers/TypeResolver"
import RootState from "@/interfaces/RootState"
import TableItem from "@/interfaces/TableItem"
import User from "@/interfaces/User"
import UserTables from "@/interfaces/UserTables"
import UwTState from "@/interfaces/UwTState"
import { UserWithTables, UwTActionContext } from "@/types/types"
import { ActionContext, Module } from "vuex"

const UwT: Module<UwTState, RootState> = {
    namespaced: true,
    state: {
        userWithTables: [] as UserWithTables,
        userWithTablesNoFlat: [] as { user: User, userTables: UserTables }[]
    },
    mutations: {
        SET_USER_TOKEN_IN_USERS_WITH_TABLES(
            state: UwTState,
            { index, token }: { index: number, token: string }
        ): void {
            const selected = TypeResolver.userType(state.userWithTables[index])
            selected.token = token;
        },
        SET_USERS_WITH_TABLES(state: UwTState, userWithTables: UserWithTables): void {
            state.userWithTables = userWithTables
        },
        SET_TABLES_IN_USERS_WITH_TABLES(
            state: UwTState,
            { tables, index }: { tables: TableItem[], index: number }
        ): void {
            const selected = TypeResolver.userTablesType(state.userWithTables[index]);
            selected.tables = tables;
        },
        PUSH_NEW_USER_IN_USERS_WITH_TABLES(state: UwTState, newUser: User): void {
            newUser.type = 'user'
            if (
                (state.userWithTables.length > 0)
                &&
                TypeResolver.isUserType(state.userWithTables[state.userWithTables.length - 2])
            ) {
                const index = TypeResolver.userType(state.userWithTables[state.userWithTables.length - 2]).index
                newUser.index = index ? index + 1 : undefined;
            } else {
                newUser.index = 0;
            }
            state.userWithTables.push(newUser)

            state.userWithTables.push(
                {
                    userId: newUser.userId,
                    show: false,
                    tables: [] as TableItem[]
                }
            )
        },
        PUSH_NEW_TABLE_IN_USERS_WITH_TABLES(
            state: UwTState,
            { newTable, index }: { newTable: TableItem, index: number }
        ): void {
            const selected = TypeResolver.userTablesType(state.userWithTables[index]);
            selected.tables.push(newTable);
        },
        TOGGLE_SHOW_TABLE(state: UwTState, index: number): void {
            const selected = TypeResolver.userTablesType(state.userWithTables[index])
            selected.show = !selected.show
        },
    },
    actions: {
        setUserWithTables(
            { state, commit, rootState, rootGetters }: ActionContext<UwTState, RootState>,
            showBlocked: boolean
        ): void {
            const usersTable = rootGetters["Users/getUsersTable"];
            const userTableMap = new Map<number, TableItem[]>(); // userId -> tables mapping

            rootState.tables.forEach((tableItem: TableItem) => {
                const userId = Number(tableItem.userId);
                if (!userTableMap.has(userId)) {
                    userTableMap.set(userId, []);
                }
                userTableMap.get(userId)?.push(tableItem);
            });

            const userWithTablesNoFlat: { user: User; userTables: UserTables }[] = usersTable.map((user: User, index: number) => {
                if ((showBlocked && !user.isBlocked) || (!showBlocked && user.isBlocked)) {
                    return [];
                }

                const userId = Number(user.userId);
                const tables = userTableMap.get(userId) || [];

                user.type = 'user';
                user.index = index;

                if (tables.length === 0) {
                    return [];
                }

                const userTables: UserTables = {
                    userId: userId,
                    show: false,
                    tables: tables
                };

                return [
                    user,
                    userTables
                ];
            }).filter(item => item !== null);

            state.userWithTablesNoFlat = userWithTablesNoFlat

            commit('SET_USERS_WITH_TABLES', userWithTablesNoFlat.flat())
        },

        setUserWithTablesNormal({ dispatch }: ActionContext<UwTState, RootState>): void {
            dispatch('setUserWithTables', false)
        },

        setUserWithTablesArchived({ dispatch }: ActionContext<UwTState, RootState>): void {
            dispatch('setUserWithTables', true)
        },

        changeUserTokenInUsersWithTables(
            { state, commit }: ActionContext<UwTState, RootState>,
            { userId, token }: { userId: number; token: string }
        ): void {
            state.userWithTables.forEach((item: (User | UserTables), index: number) => {
                if (
                    TypeResolver.isUserType(item)
                    &&
                    (TypeResolver.userType(item).userId === userId)
                ) {
                    commit('SET_USER_TOKEN_IN_USERS_WITH_TABLES', { index, token })
                }
            });
        },

        toggleShowTable(
            { state, commit }: UwTActionContext,
            userId: number
        ): void {
            state.userWithTables.forEach((item: (User | UserTables), index: number) => {
                if (
                    TypeResolver.isUserTablesType(item)
                    &&
                    (TypeResolver.userTablesType(item).userId === userId)
                ) {
                    commit('TOGGLE_SHOW_TABLE', index)
                }
            });
        },

        toggleShowTableAll({ state }: UwTActionContext, open: boolean): void {
            state.userWithTables.forEach((item: (User | UserTables), index: number) => {
                if (TypeResolver.isUserTablesType(item)) {
                    const selected = TypeResolver.userTablesType(state.userWithTables[index])
                    selected.show = open
                }
            })
        },

        filterUsersInUsersWithTables(
            { state, commit }: UwTActionContext,
            userId: number | string
        ): void {
            const userWithTables = state.userWithTables.filter(
                (item: User | UserTables) => {
                    return (
                        (
                            (TypeResolver.isUserType(item))
                            &&
                            (TypeResolver.userType(item).userId !== userId)
                        )
                        ||
                        (
                            (TypeResolver.isUserTablesType(item))
                            &&
                            (TypeResolver.userTablesType(item).userId !== userId)
                        )
                    )
                });
            commit('SET_USERS_WITH_TABLES', userWithTables);
        },

        filterTablesInUsersWithTables(
            { state, commit }: UwTActionContext,
            { tableId, userId }: { tableId: number | string, userId: number }
        ): void {
            state.userWithTables.forEach((item: User | UserTables, index: number) => {
                let needleItem: UserTables;
                if (
                    TypeResolver.isUserTablesType(item)
                    &&
                    ((needleItem = TypeResolver.userTablesType(item)).userId === userId)
                ) {
                    const tables = needleItem.tables.filter((table: TableItem) => {
                        return table.tableId !== tableId
                    })
                    commit('SET_TABLES_IN_USERS_WITH_TABLES', { tables, index });
                }
            })
        },

        addTableInUsersWithTables(
            { state, commit }: UwTActionContext,
            { userId, newTable }: { userId: number, newTable: TableItem }
        ): void {
            if (!newTable) {
                return
            }

            state.userWithTables.forEach((item: User | UserTables, index: number) => {
                if (
                    TypeResolver.isUserTablesType(item)
                    &&
                    (TypeResolver.userTablesType(item).userId === userId)
                ) {
                    commit('PUSH_NEW_TABLE_IN_USERS_WITH_TABLES', { newTable, index });
                }
            })
        },

        addUsersInUsersWithTables(
            { commit }: UwTActionContext,
            newUsers: User[]
        ): void {
            if (!newUsers) {
                return
            }
            newUsers.forEach((newUser: User) => {
                commit('PUSH_NEW_USER_IN_USERS_WITH_TABLES', newUser);
            })
        },
    }
}
export default UwT
