import { render, staticRenderFns } from "./PasswordForgotPage.vue?vue&type=template&id=7c48b156&scoped=true"
import script from "./PasswordForgotPage.vue?vue&type=script&lang=ts"
export * from "./PasswordForgotPage.vue?vue&type=script&lang=ts"
import style0 from "./PasswordForgotPage.vue?vue&type=style&index=0&id=7c48b156&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c48b156",
  null
  
)

export default component.exports