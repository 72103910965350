
import { maxAdsSelectOption, maxAdsSelectOptions } from "@/types/generators"
import { BvModal } from "bootstrap-vue"
import { Component, Vue, Prop } from "vue-property-decorator"
import Generator from "@/interfaces/Generator"
import TableItem from "@/interfaces/TableItem";

@Component
export default class TDAdminMaxAds extends Vue {
    @Prop() item!: TableItem;

    static componentName = "TDAdminMaxAds"

    $bvModal!: BvModal

    loading = false
    originalMaxAds = {} as { [key: string]: number }
    options = {} as maxAdsSelectOptions

    get generators(): Generator[] {
        return this.item.generators ? this.item.generators : []
    }

    get generator(): Generator | null {
        const generator = this.generators
            .find((generator: Generator): boolean => {
                return generator.targetPlatform === 'Avito' ||
                    generator.targetPlatform === 'OZON' // marketplaces
            })
        if (!generator) {
            return null
        }

        return generator
    }

    get showSubscribed(): boolean {
        return !!(this.generator && this.generator.subscribed)
    }

    get maxAds(): number {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.generator.subscribedMaxAds || this.generator.maxAds
    }

    created(): void {
        const selectOptions = this.$store.getters['Generators/getMaxAdsSelectOptions']

        this.generators.forEach((generator: Generator) => {
            const { targetPlatform, maxAds } = generator
            this.originalMaxAds[targetPlatform] = maxAds
            this.options[targetPlatform] = this.getOptions(generator, selectOptions)
        })
    }

    getOptions(generator: Generator, selectOptions: maxAdsSelectOptions): maxAdsSelectOption[] {
        const { maxAds, targetPlatform } = generator
        const options = selectOptions[targetPlatform]

        if (options) {
            const originalIndex = options.findIndex((option: maxAdsSelectOption): boolean => option.value === maxAds)
            if (originalIndex === -1) {
                return [{text: maxAds, value: maxAds, discount: 0}, ...options]
            }
        }

        return options
    }

    showGeneratorModal(generator: Generator): void {
        const originalMaxAds = this.originalMaxAds[generator.targetPlatform]
        const tableId = this.item.tableId
        const dateExpired = this.item.dateExpired
        this.$store.dispatch('Generators/setSelected', { generator, originalMaxAds, tableId, dateExpired })

        this.$bvModal.show('admin_purchase_max_ads_modal')
    }
}
