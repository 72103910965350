
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoServicesRule from "@/interfaces/AvitoServicesRule";

@Component
export default class TDAvitoServicesDateStart extends Vue {
    @Prop() item?: AvitoServicesRule

    static componentName = 'TDAvitoServicesDateStart'

    get text(): string {
        if (this.item) {
            const date = new Date(this.item.date_start * 1000);
            return date.toLocaleDateString();
        }

        return '';
    }
}
