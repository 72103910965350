
import { Vue, Component } from "vue-property-decorator";
import axios, {AxiosResponse} from "axios";

@Component
export default class Autopayment extends Vue {
    autopaymentStatus = false
    autopaymentCheckboxDisabled = true

    async changeAutopaymentStatus(checked: (boolean | null)): Promise<void> {
        this.autopaymentCheckboxDisabled = true;

        const data = (checked === null) ? null : { enabled: checked }

        const url = await this.$store.dispatch('getFullUrl', 'transactions/autopayment/status');
        await axios.put<string>(
            url,
            data,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 201:
                        if (res.data.enabled !== null) {
                            if (checked && !res.data.rebill_id) {
                                this.$bvModal.show('modal-enable-autopayment-first')
                            }
                            this.autopaymentCheckboxDisabled = false
                            this.autopaymentStatus = res.data.enabled
                        } else {
                            this.autopaymentStatus = true
                        }
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }

    async enableAutopayment(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'transactions/autopayment/enable');
        await axios.post<string>(
            url,
            null,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 201:
                        if (res.data.PaymentURL) {
                            location.href = res.data.PaymentURL;
                        }
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }

    created(): void {
        this.changeAutopaymentStatus(null);
    }
}
