import { render, staticRenderFns } from "./TablesPage.vue?vue&type=template&id=151caf8a&scoped=true"
import script from "./TablesPage.vue?vue&type=script&lang=ts"
export * from "./TablesPage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "151caf8a",
  null
  
)

export default component.exports