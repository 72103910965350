export default [
    {
        name: 'Заголовок',
        code: 'avitoRepubTitle',
    },
    {
        name: 'Город',
        code: 'avitoRepubCity',
    },
    {
        name: 'Перепубликация',
        code: 'avitoRepubRepubRule',
    },
    {
        name: 'Удаление',
        code: 'avitoRepubDeleteRule',
    },
    {
        name: 'Параметры',
        code: 'avitoRepubParams',
    },
    {
        name: '',
        code: 'avitoRepubSave',
    },
]
