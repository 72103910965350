import { render, staticRenderFns } from "./TDAvitoServicesCity.vue?vue&type=template&id=6eb0d7bf&scoped=true"
import script from "./TDAvitoServicesCity.vue?vue&type=script&lang=ts"
export * from "./TDAvitoServicesCity.vue?vue&type=script&lang=ts"
import style0 from "./TDAvitoServicesCity.vue?vue&type=style&index=0&id=6eb0d7bf&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eb0d7bf",
  null
  
)

export default component.exports