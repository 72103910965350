import HelpState from "@/interfaces/HelpState"
import RootState from "@/interfaces/RootState"
import { Help } from "@/types/types"
import axios from "axios"
import { ActionContext, Module } from "vuex"

const Help: Module<HelpState, RootState> = {
    namespaced: true,
    state: {
        data: [] as Help[]
    },
    mutations: {
        SET_DATA(state: HelpState, data: Help[]): void {
            state.data = data
        },
    },
    actions: {
        async setHelp({ getters, commit, rootState }: ActionContext<HelpState, RootState>): Promise<void> {
            if (getters.hasData) {
                return
            }
            
            const res = await axios.get<Help[]>(rootState.helpPath);
            if (!Array.isArray(res.data)) {
                commit('SET_MSG_FROM_STATUS_CODE', 500, { root: true})
                return
            }
            commit('SET_DATA', res.data)
        }
    },
    getters: {
        getData(state: HelpState): Help[] {
            return state.data
        },
        hasData(state: HelpState): boolean {
            return state.data.length > 0
        },
        getTags(state: HelpState): string[] {
            return state.data.map((item) => item.tags)
                .flat()
                .filter((tag: string, index, self) => self.indexOf(tag) === index)
                .sort()
        },
        getBlocks(state: HelpState): string[] {
            return state.data.map((item) => item.blocks)
                .flat()
                .filter((block: string, index, self) => self.indexOf(block) === index)
                .sort()
        }
    }
}

export default Help
