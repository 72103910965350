
import axios, { AxiosResponse } from "axios"
import { Component, Vue } from 'vue-property-decorator'

interface Option {
    value: string;
    text: string;
}

@Component({
    components: {}
})
export default class SystemSettingsPage extends Vue {
    selectedXMLloadType: string | null = null;

    changeXMLloadTypeOptions: Option[] = [
        { value: '1', text: 'По расписанию' },
        { value: '0', text: 'По уведомлению' },
    ];

    async created(): Promise<void> {
        document.title = 'Настройки системы'

        this.$store.state.msg = ''
        this.$store.commit('SET_LOADING', true)
        Promise.all([
            this.getSystemSettings()
        ])
            .finally(() => this.$store.commit('SET_LOADING', false))
    }

    private async getSystemSettings(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'system/settings');
        await axios.get(
            url + this.$store.getters.hashQuery,
            { validateStatus: (): boolean => true }
        )
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    if (res.data && res.data.xmlLoadBySchedule !== undefined) {
                        this.selectedXMLloadType = res.data.xmlLoadBySchedule;
                    }
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }


    private async changeXMLloadType(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'system/settings');
        await axios.post(
            url + this.$store.getters.hashQuery,
            { 'xmlLoadBySchedule': this.selectedXMLloadType }
        )
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    this.$bvModal.show('changeXMLloadTypeModal');
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }


    private async updateAvitoProfilesNow(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'system/settings');
        await axios.post(
            url + this.$store.getters.hashQuery,
            { 'updateAvitoProfilesNow': 1 }
        )
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    this.$bvModal.show('updateAvitoProfilesNowModal');
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }
}
