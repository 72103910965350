
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoRepubHistory_v2 from "@/interfaces/AvitoRepubHistory_v2";

@Component
export default class TDAvitoRepubHistoryConditions_v2 extends Vue {
    @Prop() item!: AvitoRepubHistory_v2

    static componentName = 'TDAvitoRepubHistoryConditions_v2'

    get useAll(): string {
        return this.item.conditions.use_all ? 'Все' : 'Любое';
    }

    get day(): number | null {
        return this.item.conditions.day ?? null;
    }

    get views(): number | null {
        return this.item.conditions.views ?? null;
    }

    get contacts(): number | null {
        return this.item.conditions.contacts ?? null;
    }

    get favorites(): number | null {
        return this.item.conditions.favorites ?? null;
    }

    get conversions(): number | null {
        return this.item.conditions.conversions ?? null;
    }
}
