
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoRepubRule_v2 from "@/interfaces/AvitoRepubRule_v2";

@Component
export default class TDAvitoRepubCities_v2 extends Vue {
    @Prop() item!: AvitoRepubRule_v2

    static componentName = 'TDAvitoRepubCities_v2'

    get formattedCities(): string {
        if (!this.item) return '';
        if (this.item.any_cities) return 'Все';
        if (!this.item.cities || this.item.cities.length === 0) return '';
        return this.item.cities.join(', ');
    }

    get formattedCitiesExclude(): string {
        if (!this.item) return '';
        if (!this.item.cities_exclude || this.item.cities_exclude.length === 0) return '';
        return this.item.cities_exclude.join(', ');
    }
}
