export default [
    {
        name: 'Включено',
        code: 'imagesTransformEnable',
    },
    {
        name: 'Заголовки',
        code: 'imagesTransformTitles',
    },
    {
        name: 'Города',
        code: 'imagesTransformCities',
    },
    {
        name: 'Исключить ID',
        code: 'imagesTransformIdsExclude',
    },
    {
        name: 'Исключить вкладки',
        code: 'imagesTransformSheetsExclude',
    },
    {
        name: 'Степень преобразования',
        code: 'imagesTransformStrength',
    },
    {
        name: '',
        code: 'imagesTransformActions',
    },
]
