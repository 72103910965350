export default [
    {
        name: '#',
        code: 'index',
    },
    {
        name: 'Название задачи',
        code: 'name',
    },
    {
        name: 'Статус',
        code: 'monitoringStatus',
    },
    {
        name: 'Целевая позиция',
        code: 'monitoringPosition',
    },
    {
        name: 'Уведомления',
        code: 'monitoringNotifications',
    },
    {
        name: '',
        code: 'monitoringButtons',
    },
]
