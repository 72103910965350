
import axios, { AxiosResponse } from "axios"
import { Component, Vue } from 'vue-property-decorator'
import moment from "moment/moment";

@Component({
    components: {},
})
export default class SmsSendPage extends Vue {
    smsText: string = '';

    phoneNumbers: string = '';
    mode: string = 'filter';
    modes: Array<{ text: string, value: string }> = [
        { text: 'По фильтрам', value: 'filter' },
        { text: 'По списку номеров', value: 'list' }
    ];

    canSend = true;

    includedTags: string[] = [];
    excludedTags: string[] = [];

    selectedIncludedTags: string[] = [];
    selectedExcludedTags: string[] = [];

    registrationFrom = '';
    registrationTo = '';

    characterCountMap: { [key: string]: number } = {};

    selectedDate: string | null = null;
    selectedTime: string | null = null;
    selectedDateTime: Date | null = null;

    selectedUsers: number = 0;


    updateCharacterCount(key: string): void {
        this.characterCountMap[key] = this[key].length;
    }


    characterCount(key: string): number {
        return this.characterCountMap[key] || 0;
    }


    blockCount(key: string): number {
        const characterCount = this.characterCount(key);
        return Math.ceil(characterCount / 70);
    }


    sendButtonDisabled(): boolean {
        if (this.mode === 'filter') {
            return this.smsText.length === 0 || !this.canSend || this.selectedUsers === 0;
        } else {
            return this.smsText.length === 0 || !this.canSend || this.phoneNumbers.length === 0;
        }
    }


    async created(): Promise<void> {
        document.title = 'Отправка SMS';

        this.$store.state.msg = '';
        this.$store.commit('SET_LOADING', true);
        Promise.all([
            this.getFilterData()
        ]).finally(() => this.$store.commit('SET_LOADING', false));

        this.setDates();
        this.openDateTimeModal();

        this.$watch(
            () => ({
                selectedIncludedTags: this.selectedIncludedTags.slice(),
                selectedExcludedTags: this.selectedExcludedTags.slice(),
                registrationFrom: this.registrationFrom,
                registrationTo: this.registrationTo,
            }),
            (newValues, oldValues) => {
                if (
                    JSON.stringify(newValues) !== JSON.stringify(oldValues) ||
                    this.registrationFrom !== oldValues.registrationFrom ||
                    this.registrationTo !== oldValues.registrationTo
                ) {
                    this.sendSms(true);
                }
            },
            {
                deep: true,
            }
        );
    }


    isSelected(tag: string, block: string): boolean {
        if (block === 'included') {
            return this.selectedIncludedTags.includes(tag);
        } else if (block === 'excluded') {
            return this.selectedExcludedTags.includes(tag);
        }
        return false;
    }


    toggleTag(tag: string, block: string): void {
        if (block === 'included') {
            if (this.selectedIncludedTags.includes(tag)) {
                const index = this.selectedIncludedTags.indexOf(tag);
                this.selectedIncludedTags.splice(index, 1);
            } else {
                this.selectedIncludedTags.push(tag);
            }
        } else if (block === 'excluded') {
            if (this.selectedExcludedTags.includes(tag)) {
                const index = this.selectedExcludedTags.indexOf(tag);
                this.selectedExcludedTags.splice(index, 1);
            } else {
                this.selectedExcludedTags.push(tag);
            }
        }
    }


    setDates(): void {
        this.registrationFrom = moment('2000-01-01').format('YYYY-MM-DD')
        this.registrationTo = moment().format('YYYY-MM-DD')
    }


    get formattedDateTime(): string {
        if (this.selectedDateTime) {
            return moment(this.selectedDateTime).format("DD.MM.YYYY HH:mm");
        }
        return "";
    }


    openDateTimeModal(): void {
        this.selectedDate = moment().add(1, 'day').format('YYYY-MM-DD');
        this.selectedTime = moment().set({hour: 12, minute: 0, second: 0}).format('HH:mm:ss');
        this.$bvModal.show('dateTimeModal');
    }


    saveDateTime(): void {
        if (this.selectedDate && this.selectedTime) {
            const date = moment(this.selectedDate).format("YYYY-MM-DD");
            const time = moment(this.selectedTime, "HH:mm:ss").format("HH:mm:ss");
            this.selectedDateTime = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss").toDate();
        }
    }


    resetDateTime(): void {
        this.selectedDateTime = null;
    }


    private async getFilterData(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'sms/send/filter');
        await axios
            .get(url + this.$store.getters.hashQuery, {validateStatus: (): boolean => true})
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status;
                if (statusCode === 200) {
                    if (res.data) {
                        if (res.data['tags'] !== undefined) {
                            this.includedTags = res.data['tags'];
                            this.excludedTags = res.data['tags'];
                        }
                    }
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode);
                }
            })
            .catch((err) => console.error(err));
    }


    private async sendSms(calcOnly: boolean): Promise<void> {
        this.canSend = false;

        const url = await this.$store.dispatch('getFullUrl', 'sms/send');
        const payload: any = {
            'smsText': this.smsText,
            'sendDateTime': this.selectedDateTime,
            'mode': this.mode
        };

        if (this.mode === 'filter') {
            payload.includedTags = this.selectedIncludedTags;
            payload.excludedTags = this.selectedExcludedTags;
            payload.registrationFrom = this.registrationFrom;
            payload.registrationTo = this.registrationTo;
            payload.calcOnly = calcOnly;
        } else {
            payload.phoneNumbers = this.phoneNumbers;
        }

        await axios.post(
            url + this.$store.getters.hashQuery,
            payload
        )
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status;
                if (statusCode === 200) {
                    if (calcOnly) {
                        this.selectedUsers = res.data;
                    } else {
                        this.$bvModal.show('smsSent');
                    }
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => { this.canSend = true; });
    }
}
