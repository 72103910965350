
import axios from "axios"
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AboutPage extends Vue {
    data = ''

    async created(): Promise<void> {
        document.title = 'О сервисе'
        this.$store.state.msg = ''

        if (this.data !== '') {
            return
        }

        const res = await axios.get<string>('/data/about.html')
        this.data = res.data
    }
}
