
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoRepubHistory_v2 from "@/interfaces/AvitoRepubHistory_v2";

@Component
export default class TDAvitoRepubHistoryAdId_v2 extends Vue {
    @Prop() item!: AvitoRepubHistory_v2

    static componentName = 'TDAvitoRepubHistoryAdId_v2'
}
