
import { maxAdsSelectOption, maxAdsSelectOptions } from "@/types/generators"
import { BvModal } from "bootstrap-vue"
import { Component, Vue, Prop } from "vue-property-decorator"
import Generator from "@/interfaces/Generator"
import TableItem from "@/interfaces/TableItem";

@Component
export default class TDGenerators extends Vue {
    @Prop() item!: TableItem;

    static componentName = "TDGenerators"

    $bvModal!: BvModal

    originalMaxAds = {} as { [key: string]: number }
    options = {} as maxAdsSelectOptions

    get generators(): Generator[] {
        return this.item.generators ? this.item.generators : []
    }

    created(): void {
        const selectOptions = this.$store.getters['Generators/getMaxAdsSelectOptions']

        this.generators.forEach((generator: Generator) => {
            const { targetPlatform, maxAds } = generator
            this.originalMaxAds[targetPlatform] = maxAds
            this.options[targetPlatform] = this.getOptions(generator, selectOptions)
        })
    }

    getOptions(generator: Generator, selectOptions: maxAdsSelectOptions): maxAdsSelectOption[] {
        const { maxAds, targetPlatform } = generator
        const options = selectOptions[targetPlatform]

        const originalIndex = options.findIndex((option: maxAdsSelectOption): boolean => option.value === maxAds)
        if (originalIndex === -1) {
            return [{ text: maxAds, value: maxAds, discount: 0 }, ...options]
        }

        return options
    }

    showModal(generator: Generator): void {
        const originalMaxAds = this.originalMaxAds[generator.targetPlatform]
        const tableId = this.item.tableId
        const dateExpired = this.item.dateExpired
        this.$store.dispatch('Generators/setSelected', { generator, originalMaxAds, tableId, dateExpired })

        this.$bvModal.show('purchase_max_ads_modal')
    }
}
