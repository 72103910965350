
import { invitation } from "@/types/invitations"
import axios, { AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator"

@Component
export default class ButtonCreateInvitation extends Vue {
    creating = false;

    text = 'Создать приглашение'

    get icon(): string {
        return this.creating ? 'three-dots' : 'envelope'
    }

    get animation(): string {
        return this.creating ? 'cylon' : ''
    }

    async createInvitation(): Promise<void> {
        if (this.creating) {
            return
        }
        this.creating = true;

        const url = await this.$store.dispatch('getFullUrl', 'users/invitations');
        await axios.post(
            url,
            null,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<invitation>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 201:
                        this.$store.dispatch('Invitations/addInvitation', res.data)
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.creating = false)
    }
}
