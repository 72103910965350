import { render, staticRenderFns } from "./TDMessengerNotes.vue?vue&type=template&id=052978c6&scoped=true"
import script from "./TDMessengerNotes.vue?vue&type=script&lang=ts"
export * from "./TDMessengerNotes.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "052978c6",
  null
  
)

export default component.exports