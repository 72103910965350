
import {Vue, Component} from "vue-property-decorator";
import axios, {AxiosResponse} from "axios";
import TableItem from "@/interfaces/TableItem";

@Component({
    components: {

    }
})

export default class TagsAvitoPage extends Vue {
    logsBaseUrl = 'https://api.adviz.pro/logs/'

    selected = null

    options = []

    selectedItemText() {
        return this.options.find(option => option.value === this.form.option)
    }

    created(): void {
        document.title = 'Просмотр логов'

        this.$store.dispatch('Users/redirectIfNotAdminOrManager')
            .then(async () => {
                const url = await this.$store.dispatch('getFullUrl', 'logs');
                await axios.get(
                    url + this.$store.getters.hashQuery,
                    { validateStatus: (): boolean => true }
                )
                    .then((res) => {
                        const statusCode = res.request.status
                        if (statusCode === 200) {
                            for (const [sectionName, secData] of Object.entries(res.data)) {
                                let sectionOptions = [];

                                for (const item of secData) {
                                    sectionOptions.push({
                                        value: {
                                            url: this.logsBaseUrl + sectionName + '/' + item,
                                            text: item.replace('/', ' -> ')
                                        },
                                        text: item.replace('/', ' -> ')
                                    });
                                }

                                this.options.push({
                                    label: sectionName,
                                    options: sectionOptions
                                });
                            }

                        }
                    })
            })
    }
}
