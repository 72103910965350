
import { Component, Vue, Prop } from "vue-property-decorator"
import TableItem from "@/interfaces/TableItem";
import axios, {AxiosResponse} from "axios";
import {ErrorResponse} from "@/types/types";
import { maska } from "maska";

@Component({
    components: {},
    directives: {
        maska
    }
})
export default class TDBalanceTransaction extends Vue {
    // eslint-disable-next-line
    @Prop() item!: TableItem | any;

    static componentName = 'TDBalanceTransaction'

    types = [
        { value: 'admin_top_up_balance_s', text: 'Пополнение (С)' },
        { value: 'admin_top_up_balance_p', text: 'Пополнение (П)' },
        { value: 'admin_compensation', text: 'Компенсация' },
        { value: 'admin_gift', text: 'Подарок' },
        { value: 'admin_payment_to_partner', text: 'Выплата партнеру' },
        { value: 'admin_bonus_payment', text: 'Выплата премии' },
        { value: 'admin_fine', text: 'Штраф' },
        { value: 'admin_refund', text: 'Возврат' },
    ];

    transactionType: (string | null) = null
    transactionAmount = ''

    responseText = ''

    get userId(): number {
        if (this.item.userId) {
            return this.item.userId
        }

        if (this.item.referralId) {
            return this.item.referralId
        }

        return 0;
    }

    get transactionModalText(): string {
        switch (this.transactionType) {
            case 'admin_compensation':
            case 'admin_gift':
            case 'admin_top_up_balance_s':
            case 'admin_top_up_balance_p':
                return 'Укажите сумму, на которую будет <strong>пополнен</strong> баланс'
            case 'admin_payment_to_partner':
            case 'admin_bonus_payment':
            case 'admin_fine':
            case 'admin_refund':
                return 'Укажите сумму, которая будет <strong>списана</strong> с баланса'
            default:
                return 'Выберите тип транзакции'
        }
    }

    resetModal(): void {
        this.transactionType = null
        this.transactionAmount = ''
    }

    async newTransaction(userId: number): Promise<void> {
        if (!this.transactionType) {
            this.responseText = 'Необходимо выбрать тип транзакции'
            this.$bvModal.show('modal-transaction-response-' + userId)
            return;
        }

        if (!this.transactionAmount) {
            this.responseText = 'Необходимо указать сумму'
            this.$bvModal.show('modal-transaction-response-' + userId)
            return;
        }

        const url = await this.$store.dispatch('getFullUrl', 'transactions/add');

        let transactionAmount = parseFloat(this.transactionAmount)

        await axios.post<void | ErrorResponse>(
            url,
            { userId: userId, type: this.transactionType, amount: transactionAmount },
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void | ErrorResponse>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 201:
                        this.responseText = 'Транзакция сохранена'
                        switch (this.transactionType) {
                            case 'admin_payment_to_partner':
                            case 'admin_bonus_payment':
                            case 'admin_fine':
                            case 'admin_refund':
                                transactionAmount = 0 - transactionAmount
                        }

                        this.item.balance = parseFloat(this.item.balance) + transactionAmount
                        if (String(this.item.balance).search(/\./) === -1) {
                            this.item.balance = this.item.balance + '.00'
                        }
                        this.$store.dispatch('Wallet/setBalance');
                        break
                    default:
                        this.responseText = 'Не удалось сохранить транзакцию, проверьте введенные данные и повторите попытку.'
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }

                this.$bvModal.show('modal-transaction-response-' + userId)
            })
            .catch(err => console.error(err))
    }
}
