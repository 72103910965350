import AuthState from "@/interfaces/AuthState"
import NavLink from "@/interfaces/NavLink"
import RootState from "@/interfaces/RootState"
import router from "@/router"
import { authToken } from "@/types/auth"
import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios"
import { ActionContext, Module } from "vuex"

const Auth: Module<AuthState, RootState> = {
    namespaced: true,
    state: {
        tokenKey: 'token',
        token: {} as authToken
    },
    mutations: {
        SET_TOKEN(state: AuthState, token: authToken): void {
            axios.defaults.headers.common['Authorization'] = token.token_type + ' ' + token.access_token
            state.token = token
        },
        CLEAR_TOKEN(state: AuthState): void {
            axios.defaults.headers.common['Authorization'] = ''
            state.token = {} as authToken
        }
    },
    actions: {
        saveToken({ state, dispatch }: ActionContext<AuthState, RootState>, token: authToken): void {
            localStorage.setItem(state.tokenKey, JSON.stringify(token))
            dispatch('setTokenFromStorage')
        },
        
        setTokenFromStorage({ state, commit }: ActionContext<AuthState, RootState>): void {
            const tokenJSON = localStorage.getItem(state.tokenKey)
            if (!tokenJSON) {
                return
            }
            commit('SET_TOKEN', JSON.parse(tokenJSON))
        },
        
        async refreshToken(
            { state, commit, dispatch }: ActionContext<AuthState, RootState>,
            originalRequest: AxiosRequestConfig | null
        ): Promise<void | AxiosPromise> {
            commit('SET_MSG', '', { root: true })
            const url = await dispatch('getFullUrl', 'auth/refresh', { root: true });
            await axios.post<authToken>(
                url,
                { refresh_token: state.token.refresh_token },
                { validateStatus: (): boolean => true }
            )
                .then((res: AxiosResponse<authToken>) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 200:
                            dispatch('saveToken', res.data)
                            if (originalRequest) {
                                const token = res.data
                                originalRequest.headers['Authorization'] = token.token_type + ' ' + token.access_token
                                return axios(originalRequest)
                            }
                            break
                        case 403:
                            dispatch('logout')
                            dispatch('Users/logout', null, { root: true })
                            dispatch('setLinks', [] as NavLink[], { root: true })
                            commit('SET_SHOW_HEADER', false, { root: true })
                            
                            router.push({ name: 'login' })
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        },
        
        logout({ state, commit }: ActionContext<AuthState, RootState>): void {
            localStorage.removeItem(state.tokenKey)
            commit('CLEAR_TOKEN')
        }
    },
    getters: {
        hasToken(state: AuthState): boolean {
            return !!state.token.access_token
        },
        
        tokenExpired(state: AuthState): boolean {
            return state.token.expires_on < (Date.now() / 1000)
        }
    }
}

export default Auth
