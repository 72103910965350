
import { credentialsRegister } from "@/types/auth"
import { ErrorResponse } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class RegisterComponent extends Vue {
    @Prop() invitation?: string

    credentials: credentialsRegister = {
        email: '',
        password: '',
        password_confirmation: '',
        invitation: null
    }

    errorEmail = ''

    get validationEmail(): boolean {
        const { email } = this.credentials
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return email === '' || re.test(String(email).toLowerCase());
    }

    get validationPassword(): boolean {
        const { password } = this.credentials
        return password === '' || password.length >= 8
    }

    get validationPasswordConformation(): boolean {
        const { password, password_confirmation } = this.credentials
        return password_confirmation === '' || password === password_confirmation
    }

    async onSubmit(): Promise<void> {
        if (!this.validationEmail || !this.validationPassword || !this.validationPasswordConformation) {
            return
        }

        let data = this.credentials;
        data.invitation = this.invitation || null

        const utmParamsFromStorage = localStorage.getItem('utmParams');
        if (utmParamsFromStorage) {
            try {
                const utmParams = JSON.parse(utmParamsFromStorage);
                for (const key in utmParams) {
                    if (key.startsWith('utm_')) {
                        data[key] = utmParams[key];
                    }
                }
            } catch (error) {
                console.error('Error parsing utmParams from local storage:', error);
            }
        }

        const url = await this.$store.dispatch('getFullUrl', 'auth/register');
        await axios.post<void | ErrorResponse>(
            url,
            data,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void | ErrorResponse>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.$router.push({ name: 'login' })
                        window.ym(95006984, 'hit', '/register-success');
                        break
                    case 422:
                        this.errorEmail = this.$store.state.validationErrors.emailAlreadyTaken
                        window.ym(95006984, 'hit', '/register-error');
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }
}
