
import NavLink from "@/interfaces/NavLink"
import User from "@/interfaces/User"
import { credentialsRegister } from "@/types/auth"
import { ErrorResponse } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import { Vue, Component } from "vue-property-decorator";

@Component
export default class UpdateComponent extends Vue {
    credentials: credentialsRegister = {
        email: '',
        password: '',
        password_confirmation: '',
        invitation: null
    }

    errorEmail = ''

    get validationEmail(): boolean {
        const { email } = this.credentials
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return email === '' || re.test(String(email).toLowerCase());
    }

    get validationPassword(): boolean {
        const { password } = this.credentials
        return password === '' || password.length >= 8
    }

    get validationPasswordConformation(): boolean {
        const { password, password_confirmation } = this.credentials
        return password_confirmation === '' || password === password_confirmation
    }

    async onSubmit(): Promise<void> {
        if (!this.validationEmail || !this.validationPassword || !this.validationPasswordConformation) {
            return
        }

        const user: User = this.$store.getters['Users/getUser']
        const url = await this.$store.dispatch('getFullUrl', 'users/' + user.userId + '/update');
        await axios.put<void | ErrorResponse>(
            url + this.$store.getters.hashQuery,
            this.credentials,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void | ErrorResponse>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.$store.dispatch('Users/logout')
                        this.$store.dispatch('setLinks', [] as NavLink[])
                        this.$store.commit('SET_SHOW_HEADER', false)

                        this.$router.push({ name: 'login' })
                        break
                    case 422:
                        this.errorEmail = this.$store.state.validationErrors.emailAlreadyTaken
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }
}
