
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoRepubRule_v2 from "@/interfaces/AvitoRepubRule_v2";

@Component
export default class TDAvitoRepubIdsExclude_v2 extends Vue {
    @Prop() item!: AvitoRepubRule_v2

    static componentName = 'TDAvitoRepubIdsExclude_v2'

    get formattedIdsExclude(): string {
        if (!this.item || typeof this.item.ids_exclude !== 'object') return '';

        let result = '';
        for (let key in this.item.ids_exclude) {
            if (this.item.ids_exclude.hasOwnProperty(key)) {
                result += this.item.ids_exclude[key] + ', ';
            }
        }

        result = result.slice(0, -2);

        return result;
    }
}
