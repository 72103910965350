
import { Component, Vue, Prop } from "vue-property-decorator"
import MessengersItem from "@/interfaces/MessengersItem";

@Component
export default class TDMessengerStatus extends Vue {
    @Prop() item!: MessengersItem;

    static componentName = "TDMessengerStatus"

    get statusText(): string {
        switch(this.item['linked']) {
            case 1:
                return 'Подключен'
            case 2:
                return 'Приостановлен'
            default:
                return 'Отключен. Добавьте токен Боту'
        }
    }

    get statusClass(): string {
        switch(this.item['linked']) {
            case 1:
                return 'telegram-linked'
            case 2:
                return 'telegram-suspended'
            default:
                return 'telegram-unlinked'
        }
    }
}
