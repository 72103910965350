
import axios from "axios"
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class ListOfServicesPage extends Vue {
    data = ''

    async created(): Promise<void> {
        document.title = 'Перечень услуг'
        this.$store.state.msg = ''

        if (this.data !== '') {
            return
        }

        const res = await axios.get<string>('/data/listofservices.html')
        this.data = res.data
    }
}
