
import { Component, Vue } from 'vue-property-decorator'
import axios, {AxiosResponse} from "axios";

@Component
export default class PopupBannerLayout extends Vue {
    modalVisible = false;

    closeModal(): void {
        this.modalVisible = false;
    }

    async saveVote(): Promise<void> {
        let data = {
            "id": "new-tariff-20240920",
            "value": "completed"
        };

        const url = await this.$store.dispatch('getFullUrl', 'surveys/save-vote');

        await axios.put<void>(
            url + this.$store.getters.hashQuery,
            data,
            {validateStatus: (): boolean => true}
        )
            .then<void, never>((res: AxiosResponse<void>) => {
                const statusCode = res.request.status

                if (statusCode !== 200) {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => {
                console.error(err);
            })
    }


    async created() {
        await this.waitForInitLoading();
        await this.waitForUser();

        if (!this.$store.getters['Users/userIsAdmin']
            && !this.$store.getters['Users/userIsAdminLite']
            && !this.$store.getters['Users/userIsManager'])
        {
            const surveysCompletedList = this.$store.getters['Users/surveysCompletedList'];

            if (surveysCompletedList.indexOf('new-tariff-20240920') === -1) {
                this.modalVisible = true;
            }
        }
    }


    waitForInitLoading(): Promise<void> {
        return new Promise<void>(resolve => {
            const checkInitLoading = () => {
                if (!this.$store.state.initLoading) {
                    resolve();
                } else {
                    setTimeout(checkInitLoading, 1000);
                }
            };
            checkInitLoading();
        });
    }


    async waitForUser(): Promise<void> {
        return new Promise(resolve => {
            const checkUser = () => {
                if (this.$store.getters['Users/hasUser']) {
                    resolve();
                } else {
                    setTimeout(checkUser, 1000);
                }
            };
            checkUser();
        });
    }
}


