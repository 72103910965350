
import { referralStatistics } from "@/types/referrals"
import { adminTransaction, transaction } from "@/types/transactions"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDAmount extends Vue {
    @Prop() value!: number
    @Prop() item!: transaction | adminTransaction | referralStatistics

    static componentName = 'TDAmount'

    get amount(): string | number {
        if ('debit' in this.item) {
            const sing = this.item.debit ? '-' : ''
            return sing + this.value
        }

        return this.value
    }

    get text(): string {
        return this.amount ? '<span class="coin">'+this.amount+'</span>' : '<div style="width: 0;height: 1.5rem"></div>'
    }
}
