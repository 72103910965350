
import TDAddMessenger from "@/components/TableList/TableData/TDAddMessenger.vue"
import TableListComponent from "@/components/TableList/TableListComponent.vue";
import MessengersItem from "@/interfaces/MessengersItem";
import axios, { AxiosResponse } from "axios";
import Vue from "vue";
import Component from 'vue-class-component'

@Component({
    components: {
        TableListComponent,
        TDAddMessenger
    }
})
export default class MessengersPage extends Vue {
    currentPage = 1
    perPage = 10
    perPageVariants = [
        { active: true, perPage: 10 },
        { active: false, perPage: 30 },
        { active: false, perPage: 50 },
        { active: false, perPage: 100 }
    ]

    avitoCode = this.$route.query.code;

    get rows(): number {
        return this.$store.state.messengers.length
    }

    created(): void {
        document.title = 'Центр сообщений Авито'

        if (!this.$store.getters['Users/hasUser']) {
            return
        }

        this.$store.state.alert = ''

        this.$store.state.msg = ''
        this.$store.commit('SET_LOADING', true)
        Promise.all([
            this.getData()
        ])
            .finally(() => this.$store.commit('SET_LOADING', false))
    }

    private async getData(): Promise<void> {
        if (this.avitoCode) {
            const url = await this.$store.dispatch('getFullUrl', 'messengers');

            await axios.post(url, {code: this.avitoCode})
                .then((res: AxiosResponse) => {
                    const statusCode = res.request.status
                    if (statusCode !== 201) {
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                    }
                })
                .catch(err => console.error(err))

            this.$router.push({ name: 'messengers' })
        }

        const url = await this.$store.dispatch('getFullUrl', 'messengers');

        await axios.get<MessengersItem[]>(url)
            .then((res: AxiosResponse<MessengersItem[]>) => {
                const statusCode = res.request.status
                if (statusCode === 201) {
                    this.$store.commit('SET_MESSENGERS', res.data)
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }

    changePaginate(variant: { active: boolean, perPage: number }): void {
        this.perPageVariants.forEach((variant) => variant.active = false)
        this.perPage = variant.perPage
        variant.active = true
    }
}
