
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ChildTreeNode from './TreeNode.vue';

@Component({
    components: { TreeNode, ChildTreeNode },
    name: 'ChildTreeNode'
})
export default class TreeNode extends Vue {
    @Prop({ required: true }) node!: any;
    @Prop({ required: true }) nodeName!: any;
    @Prop({ type: Array, required: true }) selectedNodes!: string[];
    isOpen: boolean = false;
    nodeChecked: boolean = false;

    @Watch('selectedNodes', { immediate: true, deep: true })
    onSelectedNodesChange(newVal: string[], oldVal: string[]) {
        this.updateNodeChecked();
    }

    created() {
        this.updateNodeChecked();

        if (!this.hasParent()) {
            this.isOpen = true;
        }
    }

    hasParent() {
        return this.$parent instanceof TreeNode;
    }

    updateNodeChecked() {
        this.nodeChecked = this.selectedNodes.includes(this.node.category_id);
    }

    handleCheckboxChange(checked) {
        if (this.node.category_id) {
            this.$emit('selectTreeCategory', {
                category_id: this.node.category_id,
                checked: checked
            });
        }
    }

    handleSelectTreeCategory(data) {
        this.$emit('selectTreeCategory', data);
    }

    toggleNode() {
        this.isOpen = !this.isOpen;
    }

    get hasChildren(): boolean {
        return this.node.children && Object.keys(this.node.children).length;
    }
}
