
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoRepubRule_v2 from "@/interfaces/AvitoRepubRule_v2";

@Component
export default class TDAvitoRepubTitles_v2 extends Vue {
    @Prop() item!: AvitoRepubRule_v2

    static componentName = 'TDAvitoRepubTitles_v2'

    get formattedTitles(): string {
        if (!this.item) return '';
        if (this.item.any_titles) return 'Все';
        if (!this.item.titles || this.item.titles.length === 0) return '';
        return this.item.titles.join(', ');
    }

    get formattedTitlesExclude(): string {
        if (!this.item) return '';
        if (!this.item.titles_exclude || this.item.titles_exclude.length === 0) return '';
        return this.item.titles_exclude.join(', ');
    }
}
