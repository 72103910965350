export default [
    {
        name: '',
        code: 'show_table',
    },
    {
        name: '#',
        code: 'index',
    },
    {
        name: 'id',
        code: 'userId',
        class: 'd-flex align-items-center justify-content-center text-my-primary'
    },
    {
        name: 'Почта',
        code: 'email',
    },
    {
        name: 'Имя',
        code: 'name',
    },
    {
        name: 'Номер',
        code: 'phoneNumber',
    },
    {
        name: 'Telegram',
        code: 'socialNetworkUrl',
    },
    {
        name: 'Примечание',
        code: 'notes',
    },
    {
        name: 'Премиум',
        code: 'premiumSwitch',
    },
    {
        name: 'Баланс',
        code: 'balanceTransaction',
    },
    {
        name: '💾',
        code: 'save_user',
    },
    {
        name: 'Восстановить',
        code: 'restore_user',
    },
]
