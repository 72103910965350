
import User from "@/interfaces/User"
import axios, { AxiosResponse } from "axios"
import { Vue, Prop, Component } from "vue-property-decorator";
import { maska } from 'maska'

@Component({
    components: {},
    directives: {
        maska
    }
})
export default class UserInfoComponent extends Vue {
    @Prop() user!: User
    @Prop() submitText = 'Сохранить'
    @Prop() modalId?: string

    headerMessage = 'Пожалуйста заполните информацию'
    name: string | null = ''

    created(): void {
        this.$store.commit('SET_HEADER_MESSAGE', this.headerMessage)
        this.name = this.user.name
    }

    async onSubmit(): Promise<void> {
        if (this.modalId) {
            this.$bvModal.show(this.modalId)
            return
        }

        window.open('https://t.me/AutoZ_Support_Bot', '_blank');

        this.user.name = this.name
        this.$store.commit('SET_LOADING', true)
        const url = await this.$store.dispatch('getFullUrl', 'users/' + this.user.userId);
        await axios.put<User>(
            url + this.$store.getters.hashQuery,
            this.user,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<User>) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    this.$store.commit('SET_SHOW_HEADER', true)
                    this.$router.push({ name: 'home', query: { hash: this.$store.state.hash } })
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.$store.commit('SET_LOADING', false))
    }
}
