
import TableListComponent from "@/components/TableList/TableListComponent.vue";
import { Help } from "@/types/types"
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        TableListComponent
    }
})
export default class HelpPage extends Vue {
    public readonly ALL_TAGS = 'Все Теги'
    public readonly ALL_BLOCKS = 'Все блоки'

    public searchedText = ''
    public data: Help[] = [] as Help[]
    public selectedTag: string = this.ALL_TAGS
    public selectedBlock: string = this.ALL_BLOCKS

    public async created(): Promise<void> {
        document.title = 'База Знаний'

        this.$store.state.msg = ''

        await this.$store.dispatch('Help/setHelp');
        this.data = this.$store.getters["Help/getData"]
    }

    public search(): void {
        let data = this.$store.getters["Help/getData"]
        data = this.filterDataByTag(data)
        data = this.filterDataByBlock(data)

        if (!this.searchedText.trim()) {
            this.data = data
            return
        }

        const searchedText = this.searchedText.trim().toLowerCase()

        this.data = data.filter((item: Help): boolean => {
            const { title, announce, body } = item

            return (title.toLowerCase().indexOf(searchedText) !== -1) ||
                (announce.toLowerCase().indexOf(searchedText) !== -1) ||
                (body
                        .toLowerCase()
                        .replace(/(<([^>]+)>)/gi, "")
                        .indexOf(searchedText)
                    !== -1
                )
        })
    }

    public searchByTag(selected: string): void {
        this.selectedTag = (this.selectedTag === selected) ? this.ALL_TAGS : selected

        this.search()
    }

    public searchByBlock(selected: string): void {
        this.selectedBlock = (this.selectedBlock === selected) ? this.ALL_BLOCKS : selected

        this.search()
    }

    private filterDataByTag(data: Help[]): Help[] {
        if (this.selectedTag === this.ALL_TAGS) {
            return data
        }

        return data.filter((item: Help): boolean => {
            return !!item.tags.find((tag: string) => tag === this.selectedTag)
        })
    }

    private filterDataByBlock(data: Help[]): Help[] {
        if (this.selectedBlock === this.ALL_BLOCKS) {
            return data
        }

        return data.filter((item: Help): boolean => {
            return !!item.blocks.find((tag: string) => tag === this.selectedBlock)
        })
    }

}
