
import AvitoMonitoringTask from "@/interfaces/AvitoMonitoringTask"
import { Component, Vue, Prop } from "vue-property-decorator"
import axios, {AxiosResponse} from "axios";

@Component
export default class TDMonitoringSettings extends Vue {
    @Prop() index!: number;
    @Prop() item!: AvitoMonitoringTask;

    wait = false

    static componentName = "TDMonitoringButtons"

    get enabledEditTask(): boolean {
        return (this.$store.getters['AvitoMonitoring/getEditedTaskId'] !== null);
    }

    async onSubmit(): Promise<void> {
        if (this.wait) {
            return
        }
        this.wait = true;

        this.$store.state.msg = ''
        this.$store.commit('SET_LOADING', true)
        Promise.all([this.$store.dispatch('AvitoMonitoring/deleteTask', this.item.id)])
            .finally(() => this.$store.commit('SET_LOADING', false))

        this.wait = false;
    }

    onEditTask(taskId: number): void {
        this.$store.commit('AvitoMonitoring/SET_AVITO_MONITORING_EDITED_TASK_ID', taskId)
    }
}
