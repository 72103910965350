
import { invitation } from "@/types/invitations"
import { referral } from "@/types/referrals"
import { Vue, Component } from "vue-property-decorator";

@Component
export default class ProfitWidget extends Vue {
    get hasSummaryProfit(): boolean {
        return this.summaryProfit !== ''
    }

    get summaryProfit(): number | string {
        if (!this.$store.getters['Referrals/hasReferrals']) {
            return '0.00'
        }

        const referrals: referral[] = this.$store.getters['Referrals/getReferrals']

        let sum = 0;

        referrals.forEach(item => {
            sum += item.profit;
        })

        return sum.toFixed(2);
    }

    get status(): number {
        const invitations: invitation[] = this.$store.getters['Invitations/getInvitations']
        if (!invitations.length) {
            return 0
        }
        const income = invitations.map((invitation) => invitation.income)
        return Math.max.apply(null, income)
    }

    async created(): Promise<void> {
        await Promise.all([
            this.$store.dispatch('Invitations/setInvitations'),
            this.$store.dispatch('Referrals/setReferrals')
        ])
            .finally(() => this.$store.commit('SET_LOADING', false))
    }
}
