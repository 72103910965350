import { render, staticRenderFns } from "./TDMessengerStatus.vue?vue&type=template&id=52423a4e&scoped=true"
import script from "./TDMessengerStatus.vue?vue&type=script&lang=ts"
export * from "./TDMessengerStatus.vue?vue&type=script&lang=ts"
import style0 from "./TDMessengerStatus.vue?vue&type=style&index=0&id=52423a4e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52423a4e",
  null
  
)

export default component.exports