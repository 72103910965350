
import { Help } from "@/types/types"
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HelpDetailPage extends Vue {
    public detail: Help = {} as Help

    public async created(): Promise<void> {
        document.title = 'База знаний'

        await this.ensureAndSetHelp()
        await this.setDetailPage()
        await this.handleAnchors()
    }

    private async ensureAndSetHelp(): Promise<void> {
        if (this.$store.getters["Help/hasData"]) {
            return
        }

        this.$store.state.msg = ''
        await this.$store.dispatch('Help/setHelp');
    }

    private setDetailPage(): void {
        const data: Help[] = this.$store.getters['Help/getData']
        const slug = this.$route.params.article
        this.detail = data.filter((item: Help): boolean => item.slug === slug)[0]

        document.title = this.detail.title
    }

    private handleAnchors(): void {
        const links = document.querySelectorAll('a')
        if (links.length > 0) {
            const anchorLinks = [].filter.call(links, (link: HTMLLinkElement) => link.href.indexOf('#') !== -1)
            anchorLinks.forEach((anchorLink: HTMLLinkElement) => anchorLink.addEventListener('click', (e) => {
                const selector = anchorLink.href.match(/(#[^/?]+)/)
                if (!selector) {
                    return
                }

                e.preventDefault()

                const anchor: HTMLLinkElement | null = document.querySelector(selector[1]);
                if (anchor) {
                    this.scrollToAnchor(anchor)
                }
            }))
        }

        this.scroll()
    }

    private scroll(): void {
        const selector = this.$route.hash.match(/(#[^/?]+)/)
        if (!selector) {
            return
        }

        const anchor: HTMLLinkElement | null = document.querySelector(selector[1]);
        if (anchor) {
            this.scrollToAnchor(anchor)
        }
    }

    private scrollToAnchor(anchor: HTMLLinkElement): void {
        window.scrollTo({ top: anchor.offsetTop - 100, behavior: 'smooth' });
    }
}
