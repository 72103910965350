import RootState from "@/interfaces/RootState"
import AvitoRepubState from "@/interfaces/AvitoRepubState"
import AvitoRepubRule from "@/interfaces/AvitoRepubRule"
import axios, { AxiosResponse } from "axios"
import { Module } from "vuex"

const AvitoRepubRules: Module<AvitoRepubState, RootState> = {
    namespaced: true,
    state: {
        avitoRepubRules: [] as AvitoRepubRule[],
        selectedTableId: null,
        selectedRowIndex: null,
    },
    mutations: {
        SET_AVITO_REPUB_RULES(state: AvitoRepubState, avitoRepubRules: AvitoRepubRule[]): void {
            state.avitoRepubRules = avitoRepubRules
        },
        SET_SELECTED_ROW_INDEX(state, rowIndex) {
            state.selectedRowIndex = rowIndex;
        },
        PUSH_NEW_RULE(state: AvitoRepubState, newRule: AvitoRepubRule): void {
            state.avitoRepubRules.push(newRule)
        },
        SET_SELECTED_TABLE_ID(state: AvitoRepubState, tableId: number | null): void {
            state.selectedTableId = tableId;
        }
    },
    actions: {
        async setAvitoRepubRules({ commit, dispatch, rootState, rootGetters }): Promise<void> {
            const url = await dispatch('getFullUrl', 'avito-repub', { root: true });
            await axios.get<AvitoRepubRule[]>(
                url + rootGetters.hashQuery,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse<AvitoRepubRule[]>) => {
                    const statusCode = res.request.status;
                    switch (statusCode) {
                        case 200:
                            commit('SET_AVITO_REPUB_RULES', res.data);
                            rootState.hashValidated = true;
                            break;
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true });
                    }
                })
                .catch(err => console.error(err));
        },
    },
    getters: {
        getAvitoRepubRules(state: AvitoRepubState): AvitoRepubRule[] {
            return state.avitoRepubRules
        },
        getAvitoRepubRule: (state) => (id: number): AvitoRepubRule | undefined => {
            return state.avitoRepubRules.find((rule) => rule.id === id);
        },
        getSelectedRowIndex(state: AvitoRepubState): number | null {
            return state.selectedRowIndex
        },
        getSelectedTableId(state: AvitoRepubState): number | null {
            return state.selectedTableId
        }
    }
}

export default AvitoRepubRules
