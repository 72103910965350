
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDUserIsBlocked extends Vue {
    @Prop() value?: string | number

    static componentName = 'TDUserIsBlocked'

    get valueText(): string {
        return this.value ? 'Да' : 'Нет';
    }
}
