export default [
    {
        name: '#',
        code: 'index',
    },
    {
        name: 'Токен для связи с Telegram',
        code: 'messengerToken',
    },
    {
        name: '↺',
        code: 'messengerRefreshToken',
    },
    {
        name: 'Статус',
        code: 'messengerStatus',
    },
    {
        name: 'Примечание',
        code: 'messengerNotes',
    },
    {
        name: 'Удалить',
        code: 'messengerDelete',
    },
]
