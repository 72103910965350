
import { invitation } from "@/types/invitations"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDIncome extends Vue {
    @Prop() item!: invitation;

    static componentName = "TDIncome"

    get income(): number {
        const profit = this.item.income - this.item.discount
        return (profit < 0) ? 0 : profit
    }
}
