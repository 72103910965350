export default [
    {
        name: 'Ссылка',
        code: 'invitationHash',
    },
    {
        name: 'Дата и время',
        code: 'created_at',
    },
    {
        name: 'Почта реферала',
        code: 'email',
    },
    {
        name: 'Примечание',
        code: 'referralsNotes',
    },
    {
        name: 'Сумма заработка',
        code: 'profit',
        class: 'coin d-flex align-items-center justify-content-end',
    },
]
