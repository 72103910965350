
import TableItem from "@/interfaces/TableItem";
import axios, { AxiosResponse } from "axios";
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDAddTable extends Vue {
    @Prop() userId?: number;

    static componentName = "TDAddTable"

    creating = false;

    get text(): string {
        if (this.creating) {
            return 'Таблица создается';
        }

        switch (this.$router.currentRoute.name) {
            case 'tables':
                return 'Создать таблицу'
            case 'users':
                return 'Создать только таблицу'
            default:
                return 'Создать таблицу'
        }
    }

    get icon(): string {
        return this.creating ? 'three-dots' : 'clipboard-plus'
    }

    get animation(): string {
        return this.creating ? 'cylon' : ''
    }

    async addTable(): Promise<void> {
        const userId = this.userId;
        const newTable = await this.createTable(userId)

        await this.handleView(newTable, userId);
    }

    async handleView(newTable: TableItem|null, userId?: number): Promise<void> {
        if (!newTable) {
            return
        }

        if (!userId) {
            await this.$store.dispatch('addNewTable', newTable)
            return
        }

        await this.$store.dispatch('UwT/addTableInUsersWithTables', { userId, newTable })
    }

    async createTable(userId?: number): Promise<TableItem | null> {
        if (this.creating) {
            return null
        }
        this.creating = true;

        let result: (TableItem | null) = null;
        const url = await this.$store.dispatch('getFullUrl', 'tables');
        const userIdQuery = userId ? "userId=" + userId : ''
        const querySymbol = this.$store.getters.hashQuery ? '&' : '?'
        await axios.post(
            url + this.$store.getters.hashQuery + querySymbol + userIdQuery,
            null,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<TableItem>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 201:
                        result = res.data
                        break
                    case 403:
                        this.$store.state.alert = this.$store.state.text.tooManyTables
                        setTimeout(() => this.$store.state.alert = '', 5000)
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.creating = false;
                this.$store.state.newTableCreated = true;
            })
        return result;
    }
}
