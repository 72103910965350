
import WalletBalance from "@/components/Finances/WalletBalance.vue"
import NavLink from "@/interfaces/NavLink"
import User from "@/interfaces/User"
import router from "@/router"
import {Component, Vue} from 'vue-property-decorator'

@Component({
    components: {WalletBalance}
})
export default class HeaderLayout extends Vue {
    get user(): User {
        return this.$store.getters['Users/getUser']
    }

    get showUser(): boolean {
        return this.$store.getters['Users/hasUser'] &&
            this.$store.getters['Users/hasEmail'] &&
            !this.$store.getters['hasHash']
    }

    get showUserUpdate(): boolean {
        return this.$store.getters['Users/hasUser'] &&
            !this.user.email
    }

    logout(): void {
        this.$store.dispatch('Auth/logout')
        this.$store.dispatch('Users/logout')
        this.$store.dispatch('setLinks', [])
        this.$store.commit('SET_SHOW_HEADER', false)

        router.push({name: 'login'})
    }

    goToSettings(): void {
        router.push({name: 'settings'})
    }

    goToDateGenerator(): void {
        router.push({name: 'tools/dateGenerator'})
    }
}
