
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDRoleId extends Vue {
    @Prop() value?: string | number

    static componentName = 'TDRoleId'

    get valueText(): string {
        switch (this.value) {
            case 1:
                return 'Admin';
            case 3:
                return 'Service';
            case 4:
                return 'Manager';
            case 5:
                return 'Admin Lite';
            default:
                return 'User';
        }
    }
}
