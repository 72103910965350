
import { Component, Vue } from "vue-property-decorator"

@Component
export default class TDAddMessenger extends Vue {
    static componentName = "TDAddMessenger"

    async addTable(): Promise<void> {
        location.href = 'https://avito.ru/oauth?response_type=code'
            + '&client_id=0R3wamuGbq-LI01MbvQv'
            + '&state=messengers'
            + '&scope=messenger:read,messenger:write,user_balance:read,job:write,job:cv,'
            + 'job:vacancy,job:negotiations,user_operations:read,user:read,autoload:reports,'
            + 'items:info,items:apply_vas,short_term_rent:read,short_term_rent:write,stats:read,'
            + 'ratings:read,ratings:write';
    }
}
