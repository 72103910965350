
import { maxAdsSelectOption, maxAdsSelectOptions } from "@/types/generators"
import axios from "axios"
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TariffsPage extends Vue {
    data = ''

    targetPlatform = 'Avito'
    options = [] as maxAdsSelectOption[]
    maxAds = 100
    priceForAd = 0

    get option(): maxAdsSelectOption {
        const findIndex = this.options.findIndex((option: maxAdsSelectOption) => option.value === this.maxAds)
        if (findIndex === -1) {
            return { text: this.maxAds, value: this.maxAds, discount: this.options[findIndex].discount }
        }
        return this.options[findIndex]
    }

    get price(): string {
        return Math.floor(this.priceForAd * this.maxAds * (1 - (this.discount / 100))).toFixed(2)
    }

    get discount(): number {
        return this.option.discount
    }

    get profit(): string {
        return Math.floor(this.priceForAd * this.maxAds - Number(this.price)).toFixed(2)
    }

    async created(): Promise<void> {
        document.title = 'Расчёт стоимости'
        this.$store.state.msg = ''

        if (this.data !== '') {
            return
        }

        const res = await axios.get<string>('/data/tariffs.html')
        this.data = res.data

        this.$store.commit('SET_LOADING', true)
        Promise.all([
            this.$store.dispatch('Generators/setMaxAdsSelectOptions'),
        ])
            .then(() => {
                const options: maxAdsSelectOptions = this.$store.getters['Generators/getMaxAdsSelectOptions']
                this.options = options[this.targetPlatform]
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const prices: [
                    {
                        targetPlatform: string
                        price: number
                    }
                ] = options.prices
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.priceForAd = prices.find((priceItem) => priceItem.targetPlatform === this.targetPlatform).price
            })
            .finally(() => this.$store.commit('SET_LOADING', false))
    }
}
