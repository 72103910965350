
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoServicesRule from "@/interfaces/AvitoServicesRule";

@Component
export default class TDAvitoServicesServices extends Vue {
    @Prop() item?: AvitoServicesRule

    static componentName = 'TDAvitoServicesServices'

    get servicesText(): string {
        if (!this.item || !this.item.services) {
            return '';
        }

        const packageSplit: string[] = this.item.services.package.split('_');
        const packageName = packageSplit[0] + ' (' + packageSplit[1] + ' ' + (packageSplit[1] === '1' ? 'день)' : 'дней)');

        const extra: string[] = this.item.services.extra.map(serviceCode => {
            switch (serviceCode) {
                case 'xl':
                    return 'XL';
                case 'highlight':
                    return 'Выделение';
                default:
                    return '';
            }
        });

        const extraList = extra.filter(serviceName => serviceName !== '').join(', ');

        return packageName + (extraList !== '' ? ', ' + extraList : '');
    }
}
