import { render, staticRenderFns } from "./TDUserToken.vue?vue&type=template&id=84985212&scoped=true"
import script from "./TDUserToken.vue?vue&type=script&lang=ts"
export * from "./TDUserToken.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84985212",
  null
  
)

export default component.exports