
import TableItem from "@/interfaces/TableItem"
import { Component, Vue, Prop } from "vue-property-decorator"
import moment from "moment";
import Generator from "@/interfaces/Generator";
import {BvModalEvent} from "bootstrap-vue";
import axios, {AxiosResponse} from "axios";

@Component
export default class TDDate extends Vue {
    @Prop() item!: TableItem;

    static componentName = "TDDate"

    price = 0
    priceNextMonth = 0
    loading = false

    get calendarValue(): string {
        if (!this.item.dateExpired) {
            return '';
        }
        return moment.unix(this.item.dateExpired).format('YYYY-MM-DD');
    }

    get calendarClass(): string {
        if (!this.item.dateExpired) {
            return ''
        }
        const timeZoneFix = 10800;
        const nowInDays = Math.floor(((Date.now() / 1000) + timeZoneFix) / 86400)
        const expiredTimeInDays = Math.floor((this.item.dateExpired + timeZoneFix) / 86400)
        const nowInDaysSubDay = nowInDays - 1

        if (
            (expiredTimeInDays <= nowInDays) &&
            (expiredTimeInDays > nowInDaysSubDay)
        ) {
            return 'date-alert'
        }
        if (expiredTimeInDays <= nowInDaysSubDay) {
            return 'date-danger'
        }

        return ''
    }

  get showActivateOption(): boolean {
    return !(this.item.dateExpired > Math.floor(Date.now() / 1000))
  }

    get generators(): Generator[] {
        return this.item.generators ? this.item.generators : []
    }

    get generator(): Generator | null {
        const generator = this.generators
            .find((generator: Generator): boolean => {
                return generator.targetPlatform === 'Avito' ||
                    generator.targetPlatform === 'OZON' // marketplaces
            })
        if (!generator) {
          return null
        }

        return generator
    }

  async getPrice(nextMonth = false): Promise<void> {
    if (!nextMonth) {
      return
    }

    const { targetPlatform, maxAds, generatorGuid } = this.generator as Generator

    const url = await this.$store.dispatch('getFullUrl', 'prices');
    await axios.post<number>(
        url,
        {
          targetPlatform,
          maxAds,
          generatorGuid,
          nextMonth
        },
        { validateStatus: (): boolean => true }
    )
        .then((res) => {
          const statusCode = res.request.status
          switch (statusCode) {
            case 200:
              if (nextMonth) {
                this.priceNextMonth = res.data
                break
              }
              this.price = res.data
              break
            case 400:
              this.$bvModal.hide('purchase_max_ads_modal')
              break
            default:
              this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
          }
        })
        .catch(err => console.error(err))
  }

  resetModal(): void {
      if (!this.generator) {
          return
      }

      this.getPrice(true)
  }

  hiddenModal(): void {
      if (!this.generator) {
          return
      }

      this.price = 0
      this.priceNextMonth = 0
  }

  handleOk(bvModalEvt: BvModalEvent): void {
    // Prevent modal from closing
    bvModalEvt.preventDefault()
    // Trigger submit handler
    this.purchaseNow()
  }

  async purchaseNow(): Promise<void> {
    if (this.loading) {
      return
    }
    this.loading = true;

    const generator = this.generator
    const { targetPlatform, maxAds, generatorGuid, subscribed } = generator as Generator
    const tableId = this.item.tableId

    const url = await this.$store.dispatch('getFullUrl', 'transactions/maxAds');
    await axios.post<number | false>(
        url,
        {
          targetPlatform,
          maxAds,
          generatorGuid,
          subscribe: subscribed
        },
        { validateStatus: (): boolean => true }
    )
        .then((res: AxiosResponse) => {
          const statusCode = res.request.status
          switch (statusCode) {
            case 200:
              if (res.data !== false) {
                this.$bvModal.msgBoxOk(
                    'Успешно активировано.',
                    {
                      centered: true
                    }
                )
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.generator.subscribed = subscribed
                this.$store.commit('UPDATE_DATE_EXPIRED', { tableId, dateExpired: res.data })
                this.$store.dispatch('Wallet/setBalance')
              } else {
                this.$bvModal.msgBoxOk(
                    'На вашем балансе недостаточно средств!',
                    {
                      centered: true,
                      bodyTextVariant: 'danger'
                    }
                )
              }
              this.$bvModal.hide('purchaseNowModal'+this.item.tableId)
              break
            default:
              this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
          }
        })
        .catch(err => console.error(err))
        .finally(() => this.loading = false)
  }
}
