import RootState from "@/interfaces/RootState"
import InvitationsState from "@/interfaces/InvitationsState"
import { invitation } from "@/types/invitations"
import { ErrorResponse } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import { ActionContext, Module } from "vuex"

const Invitations: Module<InvitationsState, RootState> = {
    namespaced: true,
    state: {
        invitations: {} as invitation[]
    },
    mutations: {
        SET_INVITATIONS(state: InvitationsState, invitations: invitation[]): void {
            state.invitations = invitations
        },

        PUSH_NEW_INVITATION(state: InvitationsState, invitation: invitation): void {
            state.invitations.push(invitation)
        }
    },
    actions: {
        async setInvitations({ commit, dispatch, getters }: ActionContext<InvitationsState, RootState>): Promise<void> {
            if (getters.hasInvitations) {
                return
            }

            const url = await dispatch('getFullUrl', 'users/invitations', { root: true });
            await axios.get<invitation[] | ErrorResponse>(
                url,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 200:
                            commit('SET_INVITATIONS', res.data)
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        },

        addInvitation({ commit }: ActionContext<InvitationsState, RootState>, invitation: invitation): void {
            commit('PUSH_NEW_INVITATION', invitation)
        }
    },
    getters: {
        getInvitations(state: InvitationsState): invitation[] {
            return state.invitations
        },

        hasInvitations(state: InvitationsState): boolean {
            return state.invitations.length > 0
        },
    }
}

export default Invitations
