
import AvitoMonitoringTask from "@/interfaces/AvitoMonitoringTask"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDMonitoringPosition extends Vue {
    @Prop() index!: number;
    @Prop() item!: AvitoMonitoringTask;

    static componentName = "TDMonitoringPosition"

    get targetPosition(): number {
        return this.item['target_position']
    }
}
