
import UserInfoComponent from "@/components/UserInfo/UserInfoComponent.vue"
import User from "@/interfaces/User"
import Vue from "vue";
import Component from 'vue-class-component'

@Component({
    components: {
        UserInfoComponent
    }
})
export default class UserInfoPage extends Vue {
    user!: User

    async created(): Promise<void> {
        document.title = 'Информация пользователя'

        if (!this.$store.getters['Users/hasUser']) {
            return
        }

        if (this.$store.getters["Users/hasUserInfo"]) {
            await this.$store.dispatch('redirectToHome')
        }

        this.$store.state.msg = ''
        this.setUserInfo()
    }

    private setUserInfo(): void {
        this.user = this.$store.getters["Users/getUser"];
    }
}
