import User from "@/interfaces/User";
import UserTables from "@/interfaces/UserTables";

class TypeResolver {
    userTablesType(item: (User | UserTables)): UserTables {
        if ((('userId' in item) && ('tables' in item))) {
            return item
        }
        throw new TypeError('not UserTables given')
    }
    
    isUserTablesType(item: (User | UserTables)): boolean {
        return (('userId' in item) && ('tables' in item));
        
    }
    
    userType(item: (User | UserTables)): User {
        if ((('userId' in item) && ('name' in item))) {
            return item
        }
        throw new TypeError('not User given')
    }
    
    isUserType(item: (User | UserTables)): boolean {
        return (('userId' in item) && ('name' in item));
        
    }
}

export default new TypeResolver
