
import ButtonCreateInvitation from "@/components/Buttons/ButtonCreateInvitation.vue"
import TableList from "@/components/Finances/TableList.vue"
import { invitation } from "@/types/invitations"
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        TableList,
        ButtonCreateInvitation
    }
})
export default class FinancesInvitationsPage extends Vue {
    currentPage = 1
    perPage = 10
    perPageVariants = [
        { active: true, perPage: 10 },
        { active: false, perPage: 30 },
        { active: false, perPage: 50 },
        { active: false, perPage: 100 }
    ]

    get invitations(): invitation[] {
        return this.$store.getters['Invitations/getInvitations']
    }

    get referrals(): invitation[] {
        return this.$store.getters['Referrals/getReferrals']
    }

    get rows(): number {
        return this.referrals.length;
    }

    async created(): Promise<void> {
        document.title = 'Пригласительные'

        if (!this.$store.getters['Users/userIsPartner']) {
            this.$store.dispatch('redirectToHome', null, { root: true });
        }

        if (!this.$store.getters['Users/hasUser']) {
            return
        }

        this.$store.state.msg = ''
        this.$store.commit('SET_LOADING', true)
        await Promise.all([
            this.$store.dispatch('Invitations/setInvitations'),
            this.$store.dispatch('Referrals/setReferrals')
        ])
            .finally(() => this.$store.commit('SET_LOADING', false))
    }

    changePaginate(variant: { active: boolean, perPage: number }): void {
        this.perPageVariants.forEach((variant) => variant.active = false)
        this.perPage = variant.perPage
        variant.active = true
    }
}
