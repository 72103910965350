
import { Component, Vue, Prop } from "vue-property-decorator"
import ImagesTransformRule from "@/interfaces/ImagesTransformRule";

@Component
export default class TDImagesTransformStrength extends Vue {
    @Prop() item!: ImagesTransformRule

    static componentName = 'TDImagesTransformStrength'

    get text(): string {
        switch (this.item.randomize_images) {
            case 1:
                return 'слабая';
            case 2:
                return'средняя';
            case 3:
                return 'сильная';
        }

        return 'отключено';
    }
}
