import RootState from "@/interfaces/RootState"
import WalletState from "@/interfaces/WalletState"
import { ErrorResponse } from "@/types/types"
import { wallet } from "@/types/wallet"
import axios, { AxiosResponse } from "axios"
import { ActionContext, Module } from "vuex"

const Wallet: Module<WalletState, RootState> = {
    namespaced: true,
    state: {
        wallet: {} as wallet
    },
    mutations: {
        SET_WALLET(state: WalletState, wallet: wallet): void {
            state.wallet = wallet
        },
    },
    actions: {
        async setBalance({ commit, dispatch }: ActionContext<WalletState, RootState>): Promise<void> {
            const url = await dispatch('getFullUrl', 'wallet/balance', { root: true });
            await axios.get<number | ErrorResponse>(
                url,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 200:
                            dispatch(
                                'setWallet',
                                {
                                    balance: res.data
                                }
                            )
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        },

        setWallet({ commit }: ActionContext<WalletState, RootState>, wallet: wallet): void {
            commit('SET_WALLET', wallet)
        }
    },
    getters: {
        getBalance(state: WalletState): number {
            return state.wallet.balance
        },

        hasBalance(state: WalletState): boolean {
            return !!state.wallet.balance
        },
    }
}

export default Wallet
