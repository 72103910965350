
import AvitoMonitoringTask from "@/interfaces/AvitoMonitoringTask"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDMonitoringStatus extends Vue {
    @Prop() index!: number;
    @Prop() item!: AvitoMonitoringTask;

    static componentName = "TDMonitoringStatus"

    get activeTitle(): string {
        return this.item['active'] ? 'активна' : 'выключена'
    }

    get badgeClass(): string {
        return this.item['active'] ? 'badge-success' : 'badge-secondary';
    }
}
