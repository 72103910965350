
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoRepubHistory_v2 from "@/interfaces/AvitoRepubHistory_v2";

@Component
export default class TDAvitoRepubHistoryRandomize_v2 extends Vue {
    @Prop() item!: AvitoRepubHistory_v2

    static componentName = 'TDAvitoRepubHistoryRandomize_v2'

    get randomize(): string {
        let randomize: string[] = [];

        if (this.item.randomize_text) {
            randomize.push('Тексты');
        }

        if (this.item.randomize_images) {
            let strength;
            switch (this.item.randomize_images) {
                case 1:
                    strength = 'слабая';
                    break;
                case 2:
                    strength = 'средняя';
                    break;
                case 3:
                    strength = 'сильная';
                    break;
                default:
                    strength = 'неизвестная';
            }
            randomize.push(`Фото (${strength})`);
        }

        return randomize.length ? randomize.join(', ') : '';
    }
}
