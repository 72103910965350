
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ConditionsReferralsPage extends Vue {
    created(): void {
        document.title = 'Условия реферальной программы'
        this.$store.state.msg = ''
    }
}
