import ReferralsState from "@/interfaces/ReferralsState"
import RootState from "@/interfaces/RootState"
import { referral, referralsCounters, referralStatistics } from "@/types/referrals"
import { ErrorResponse } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import { ActionContext, Module } from "vuex"

const Referrals: Module<ReferralsState, RootState> = {
    namespaced: true,
    state: {
        loading: false,
        referrals: {} as referral[],
        referralsStatistics: [] as referralStatistics[],
        activeReferralsCount: 0,
        referralsCount: 0,
        partnersProfit: 0,
    },
    mutations: {
        SET_REFERRALS(state: ReferralsState, referrals: referral[]): void {
            state.referrals = referrals
        },
        SET_REFERRALS_STATISTICS(state: ReferralsState, referralsStatistics: referralStatistics[]): void {
            state.referralsStatistics = referralsStatistics
        },
        SET_REFERRALS_COUNT(state: ReferralsState, referralsCount: number): void {
            state.referralsCount = referralsCount
        },
        SET_ACTIVE_REFERRALS_COUNT(state: ReferralsState, activeReferralsCount: number): void {
            state.activeReferralsCount = activeReferralsCount
        },
        SET_PARTNERS_PROFIT(state: ReferralsState, partnersProfit: number): void {
            state.partnersProfit = partnersProfit
        }
    },
    actions: {
        async setReferralsStatistics(
            { commit, dispatch, getters }: ActionContext<ReferralsState, RootState>
        ): Promise<void> {
            if (getters.hasReferralsStatistics) {
                return
            }

            const url = await dispatch('getFullUrl', 'referrals', { root: true });
            await axios.get<referralStatistics[] | ErrorResponse>(
                url,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 200:
                            commit('SET_REFERRALS_STATISTICS', res.data)
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        },

        async setReferrals(
            { commit, dispatch, getters }: ActionContext<ReferralsState, RootState>
        ): Promise<void> {
            if (getters.hasReferrals) {
                return
            }

            const url = await dispatch('getFullUrl', 'referrals', { root: true });
            await axios.get<referral[] | ErrorResponse>(
                url,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 200:
                            commit('SET_REFERRALS', res.data)
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        },

        async setReferralsCounters(
            { commit, dispatch, getters }: ActionContext<ReferralsState, RootState>
        ): Promise<void> {
            if (getters.hasReferralsCounter && getters.hasActiveReferralsCounter) {
                return
            }

            const url = await dispatch('getFullUrl', 'referrals/counters', { root: true });
            await axios.get<referralsCounters>(
                url,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse<referralsCounters>) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 200:
                            commit('SET_REFERRALS_COUNT', res.data.referralsCounter)
                            commit('SET_ACTIVE_REFERRALS_COUNT', res.data.activeReferralsCounter)
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        },

        async setPartnerProfits(
            { commit, dispatch }: ActionContext<ReferralsState, RootState>
        ): Promise<void> {
            const url = await dispatch('getFullUrl', 'referrals/profit', { root: true });
            await axios.get<number>(
                url,
                { validateStatus: (): boolean => true }
            )
                .then((res: AxiosResponse<number>) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 200:
                            commit('SET_PARTNERS_PROFIT', res.data)
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        }
    },
    getters: {
        getReferrals(state: ReferralsState): referral[] {
            return state.referrals
        },

        hasReferrals(state: ReferralsState): boolean {
            return state.referrals.length > 0
        },

        getReferralsStatistics(state: ReferralsState): referralStatistics[] {
            return state.referralsStatistics
        },

        hasReferralsStatistics(state: ReferralsState): boolean {
            return state.referralsStatistics.length > 0
        },

        getReferralsCounter(state: ReferralsState): number {
            return state.referralsCount
        },

        hasReferralsCounter(state: ReferralsState): boolean {
            return !!state.referralsCount
        },

        getActiveReferralsCounter(state: ReferralsState): number {
            return state.activeReferralsCount
        },

        hasActiveReferralsCounter(state: ReferralsState): boolean {
            return !!state.activeReferralsCount
        },

        getPartnersProfit(state: ReferralsState): number {
            return state.partnersProfit
        }
    }
}

export default Referrals
