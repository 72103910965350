
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoServicesRule from "@/interfaces/AvitoServicesRule";

@Component
export default class TDAvitoServicesTitle extends Vue {
    @Prop() item?: AvitoServicesRule

    static componentName = 'TDAvitoServicesTitle'

    get title(): string {
        let title = this.item ? this.item.title : '';
        const maxLength = 100;

        if (title.length > maxLength) {
            const commaIndex = title.lastIndexOf(' ', maxLength);
            if (commaIndex !== -1) {
                title = title.substring(0, commaIndex) + ' ...';
            } else {
                title = title.substring(0, maxLength) + ' ...';
            }
        }

        return title;
    }
}
