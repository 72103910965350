
import PasswordForgotComponent from "@/components/Auth/PasswordForgotComponent.vue"
import Vue from "vue";
import Component from 'vue-class-component'

@Component({
    components: {
        PasswordForgotComponent
    }
})
export default class PasswordForgotPage extends Vue {
    async created(): Promise<void> {
        document.title = 'Восстановление пароля'

        this.$store.state.msg = ''
    }
}
