export default [
    {
        name: 'Ссылка',
        code: 'invitationHash',
    },
    {
        name: 'Партнерский %',
        code: 'income',
    },
    {
        name: 'Регистрации',
        code: 'registrations',
    },
    {
        name: 'Рефералы',
        code: 'referralsCounter',
    },
    {
        name: 'Активные',
        code: 'activeReferralsCounter',
    },
    {
        name: 'Примечание',
        code: 'invitationNotes',
    },
]
