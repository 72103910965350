import RootState from "@/interfaces/RootState"
import ImagesTransformState from "@/interfaces/ImagesTransformState"
import ImagesTransformRule from "@/interfaces/ImagesTransformRule"
import axios, { AxiosResponse } from "axios"
import { Module } from "vuex"

const ImagesTransformRules: Module<ImagesTransformState, RootState> = {
    namespaced: true,
    state: {
        imagesTransformRules: [] as ImagesTransformRule[],
        selectedTableId: null,
        selectedRowIndex: null,
    },
    mutations: {
        SET_AVITO_REPUB_RULES(state: ImagesTransformState, imagesTransformRules: ImagesTransformRule[]): void {
            state.imagesTransformRules = imagesTransformRules
        },
        SET_SELECTED_ROW_INDEX(state, rowIndex) {
            state.selectedRowIndex = rowIndex;
        },
        PUSH_NEW_RULE(state: ImagesTransformState, newRule: ImagesTransformRule): void {
            state.imagesTransformRules.push(newRule)
        },
        SET_SELECTED_TABLE_ID(state: ImagesTransformState, tableId: number | null): void {
            state.selectedTableId = tableId;
        },
    },
    actions: {
        async setImagesTransformRules({ commit, dispatch, rootState, rootGetters, state }): Promise<void> {
            const url = await dispatch('getFullUrl', 'images-transform', { root: true });
            await axios.get<ImagesTransformRule[]>(
                url + '/' + state.selectedTableId + rootGetters.hashQuery,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse<ImagesTransformRule[]>) => {
                    const statusCode = res.request.status;
                    switch (statusCode) {
                        case 200:
                            commit('SET_AVITO_REPUB_RULES', res.data);
                            rootState.hashValidated = true;
                            break;
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true });
                    }
                })
                .catch(err => console.error(err));
        },
    },
    getters: {
        getImagesTransformRules(state: ImagesTransformState): ImagesTransformRule[] {
            return state.imagesTransformRules
        },
        getImagesTransformRule: (state) => (id: number): ImagesTransformRule | undefined => {
            return state.imagesTransformRules.find((rule) => rule.id === id);
        },
        getSelectedRowIndex(state: ImagesTransformState): number | null {
            return state.selectedRowIndex
        },
        getSelectedTableId(state: ImagesTransformState): number | null {
            return state.selectedTableId
        },
    }
}

export default ImagesTransformRules
