
import TableColumn from "@/interfaces/TableColumn"
import {Vue, Prop, Component} from "vue-property-decorator";
import TableItemComponent from "@/components/TableList/TableItemComponent.vue"
import ImagesTransformRule from "@/interfaces/ImagesTransformRule";

@Component({
    components: {
        TableItemComponent,
    },
})
export default class ImagesTransformRowComponent extends Vue {
    @Prop() index!: number;
    @Prop() row?: ImagesTransformRule;
    @Prop() columns?: TableColumn[];
}
