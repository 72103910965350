
// @ts-nocheck
import {Component, Vue, Prop} from "vue-property-decorator"
import {Getter, Mutation} from "vuex-class";
import {maska} from 'maska'
import axios, {AxiosResponse} from "axios";
import { VueTagsInput, createTag, createTags } from "@johmun/vue-tags-input";
import AvitoRepubRule_v2 from "@/interfaces/AvitoRepubRule_v2";
import AvitoRepubRuleStat_v2 from "@/interfaces/AvitoRepubRuleStat_v2";

@Component({
    components: {
        VueTagsInput
    },
    directives: {
        maska
    }
})
export default class TDAddRepubRule_v2 extends Vue {
    @Prop() userId?: number;

    @Getter("AvitoRepub_v2/getSelectedRowIndex") selectedRowIndex!: number;

    @Getter("AvitoRepub_v2/getAvitoRepubRule") getAvitoRepubRule!: (
        id: number
    ) => AvitoRepubRule_v2 | undefined;

    @Mutation("AvitoRepub_v2/SET_SELECTED_ROW_INDEX") setSelectedRowIndex!: (
        rowIndex: number
    ) => void;

    static componentName = "TDAddRepubRule_v2"

    ruleData: AvitoRepubRule_v2 | null = null;
    showModal: boolean = false;
    wait: boolean = false;
    errorText: string = '';
    excludeVisible: boolean = false;

    title = '';
    city = '';
    title_exclude = '';
    city_exclude = '';
    id_exclude = '';
    sheet_exclude = '';

    defaultConditionData: AvitoRepubRuleStat_v2 = {
        use_all: true,
        day: null,
        views: null,
        contacts: null,
        favorites: null,
        conversions: null,
    }

    editFormVisible: boolean = false;
    editedItem = null;

    editConditionData: AvitoRepubRuleStat_v2 = {
        use_all: true,
        day: null,
        views: null,
        contacts: null,
        favorites: null,
        conversions: null,
    }

    defaultRuleData: AvitoRepubRule_v2 = {
        id: 0,
        enabled: true,
        table_id: 0,
        googleSheetId: "",
        any_titles: true,
        any_cities: true,
        cities: [],
        titles: [],
        cities_exclude: [],
        titles_exclude: [],
        ids_exclude: [],
        sheets_exclude: [],
        conditions: [],
        pub_days: 30,
        randomize_text: false,
        randomize_images: 0,
    };

    showConfirmationModal: boolean = false;

    get days(): number[] {
        const allDays = Array.from({ length: 29 }, (_, index) => index + 1);

        if (!this.ruleData || !this.ruleData.conditions || this.ruleData.conditions.length === 0) {
            return allDays;
        }

        const daysInRuleData = this.ruleData.conditions.map(item => item.day);
        let filtered = allDays.filter(day => !daysInRuleData.includes(day));

        if (this.editedItem !== null && this.editedItem !== 0) {
            filtered.push(this.editedItem);
        }

        filtered.sort((a, b) => a - b);

        return filtered;
    }

    confirmAction(): void {
        this.showConfirmationModal = false;
    }

    newRule(): void {
        this. excludeVisible = false;
        this.$store.commit("AvitoRepub_v2/SET_SELECTED_ROW_INDEX", 0);
    }

    resetRuleData(): void {
        this.$store.commit("AvitoRepub_v2/SET_SELECTED_ROW_INDEX", -1);
    }

    get modalTitle(): string {
        return this.ruleData && this.ruleData.id === 0 ? 'Новое правило' : 'Редактирование правила';
    }

    get saveButtonTitle(): string {
        return this.ruleData && this.ruleData.id === 0 ? 'Запустить правило' : 'Сохранить';
    }

    get disableSubmit(): boolean {
        return (!this.ruleData?.titles.length && !this.ruleData?.any_titles)
            || (!this.ruleData.cities.length && !this.ruleData?.any_cities)
            || !this.ruleData.conditions.length
            || this.wait;
    }

    get sortedStatParams() {
        if (!this.ruleData || !this.ruleData.conditions) return [];

        return [...this.ruleData.conditions].sort((a, b) => a.day - b.day);
    }

    isEmptyCondition(): boolean {
        return !this.editConditionData.views
        && !this.editConditionData.contacts
        && !this.editConditionData.favorites
        && !this.editConditionData.conversions;
    }

    get disableSaveCondition(): boolean {
        if (!this.editConditionData.day) {
            return true;
        }

        const editConditionDay = parseFloat(this.editConditionData.day);

        if (this.isEmptyCondition()) {
            return true;
        }

        if (!this.ruleData || !this.ruleData.conditions.length) {
            return false;
        }

        const previousDays = this.ruleData.conditions.filter(param => {
            const paramDay = param.day;
            return paramDay < editConditionDay
                && (this.editedItem === null || this.editedItem === 0 || paramDay !== this.editedItem);
        });

        const nextDays = this.ruleData.conditions.filter(param => {
            const paramDay = param.day;
            return paramDay > editConditionDay
                && (this.editedItem === null || this.editedItem === 0 || paramDay !== this.editedItem);
        });

        if (previousDays.length === 0 && nextDays.length === 0) {
            return false;
        }

        if (previousDays.length) {
            const maxValuesPrev = {
                views: Math.max(...previousDays.filter(param => param.views !== null).map(param => param.views)),
                contacts: Math.max(...previousDays.filter(param => param.contacts !== null).map(param => param.contacts)),
                favorites: Math.max(...previousDays.filter(param => param.favorites !== null).map(param => param.favorites)),
                conversions: Math.max(...previousDays.filter(param => param.conversions !== null).map(param => param.conversions)),
            };

            if (parseFloat(this.editConditionData.views) < maxValuesPrev.views ||
                parseFloat(this.editConditionData.contacts) < maxValuesPrev.contacts ||
                parseFloat(this.editConditionData.favorites) < maxValuesPrev.favorites ||
                parseFloat(this.editConditionData.conversions) < maxValuesPrev.conversions)
            {
                return true;
            }
        }

        if (nextDays.length) {
            const minValuesNext = {
                views: Math.min(...nextDays.filter(param => param.views !== null).map(param => param.views)),
                contacts: Math.min(...nextDays.filter(param => param.contacts !== null).map(param => param.contacts)),
                favorites: Math.min(...nextDays.filter(param => param.favorites !== null).map(param => param.favorites)),
                conversions: Math.min(...nextDays.filter(param => param.conversions !== null).map(param => param.conversions)),
            };

            if (parseFloat(this.editConditionData.views) > minValuesNext.views ||
                parseFloat(this.editConditionData.contacts) > minValuesNext.contacts ||
                parseFloat(this.editConditionData.favorites) > minValuesNext.favorites ||
                parseFloat(this.editConditionData.conversions) > minValuesNext.conversions) {
                return true;
            }
        }

        return false;
    }


    get randomizeImagesLabel(): array {
        const labels = [
            { text: 'отключено', description: '', color: '' },
            { text: 'слабая', description: 'На фотографиях будут небольшие изменения', color: 'color-min' },
            { text: 'средняя', description: 'На фотографиях могут быть видимые изменения', color: 'color-medium' },
            { text: 'сильная', description: 'На фотографиях могут быть существенные изменения', color: 'color-max' }
        ];
        return labels[this.ruleData.randomize_images];
    }


    limitValue(value, min, max) {
        if (value === '') {
            return '';
        }

        let numericValue = Number(value);

        if (isNaN(numericValue)) {
            return min.toString();
        }

        if (numericValue < min) {
            return min.toString();
        } else if (numericValue > max) {
            return max.toString();
        }

        return numericValue.toString();
    }

    newCondition(): void {
        this.editConditionData = { ...this.defaultConditionData };
        this.editedItem = 0;
        this.editFormVisible = true;
    }

    saveCondition(): void {
        const newConditionData = { ...this.editConditionData };

        if (this.editedItem && this.editedItem !== Number(newConditionData.day)) {
            const deletedIndex = this.ruleData?.conditions.findIndex(item => item.day === this.editedItem);
            if (deletedIndex !== -1) {
                this.ruleData?.conditions.splice(deletedIndex, 1);
            }
        }

        const existingIndex = this.ruleData?.conditions.findIndex(item => item.day === Number(newConditionData.day));

        if (existingIndex !== -1) {
            this.ruleData?.conditions.splice(existingIndex, 1);
        }

        this.ruleData?.conditions.push(newConditionData);

        this.editedItem = null;
        this.editFormVisible = false;
    }


    cancelSaveCondition(): void {
        this.editedItem = null;
        this.editFormVisible = false;
    }

    removeCondition(dayToDelete): void {
        if (this.ruleData) {
            const indexToDelete = this.ruleData.conditions.findIndex(condition => condition.day === dayToDelete);
            if (indexToDelete !== -1) {
                this.ruleData.conditions.splice(indexToDelete, 1);
                this.errorText = '';

                if (dayToDelete === this.editedItem) {
                    this.editedItem = null;
                    this.editFormVisible = false;
                }
            }
        }
    }

    editCondition(dayToEdit): void {
        if (this.ruleData) {
            const conditionToEdit = this.ruleData.conditions.find(condition => condition.day === dayToEdit);
            if (conditionToEdit) {
                this.editConditionData = { ...conditionToEdit };
                this.editedItem = dayToEdit;
                this.errorText = '';
                this.editFormVisible = true;
            }
        }
    }

    async saveRule(e): Promise<void> {
        e.preventDefault()

        this.wait = true;
        this.errorText = '';

        const rule = { ...this.ruleData };

        if (rule && rule.titles) {
            if (rule.titles) {
                rule.titles = rule.titles.map(title => title.text);
            }

            if (rule.cities) {
                rule.cities = rule.cities.map(city => city.text);
            }

            if (rule.cities_exclude) {
                rule.cities_exclude = rule.cities_exclude.map(cities_exclude => cities_exclude.text);
            }

            if (rule.titles_exclude) {
                rule.titles_exclude = rule.titles_exclude.map(titles_exclude => titles_exclude.text);
            }

            if (rule.ids_exclude) {
                rule.ids_exclude = rule.ids_exclude.map(ids_exclude => ids_exclude.text);
            }

            if (rule.sheets_exclude) {
                rule.sheets_exclude = rule.sheets_exclude.map(sheets_exclude => sheets_exclude.text);
            }
        }


        if (rule) {
            rule.table_id = this.$store.getters["AvitoRepub_v2/getSelectedTableId"];
        }

        let userId = this.userId;
        const url = await this.$store.dispatch('getFullUrl', 'avito-repub_v2');
        const userIdQuery = userId ? "userId=" + userId : ''
        const querySymbol = this.$store.getters.hashQuery ? '&' : '?'

        await axios.put<void>(
            url + this.$store.getters.hashQuery + querySymbol + userIdQuery,
            rule,
            {validateStatus: (): boolean => true}
        )
            .then<void, never>((res: AxiosResponse<void>) => {
                const statusCode = res.request.status

                if (statusCode === 200) {
                    var response = JSON.parse(res.request.response);
                    if (response.status == 'error') {
                        this.errorText = response.text;
                    } else {
                        this.showModal = false;
                        this.$store.dispatch('AvitoRepub_v2/setAvitoRepubRules');

                        this.$emit('add-new-rule', this.ruleData.id);

                        if (this.ruleData.id === 0) {
                            this.showConfirmationModal = true;
                        }
                    }
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.wait = false;
            })
    }


    mounted() {
        this.$watch(
            () => this.selectedRowIndex,
            (value: number) => {
                if (value === -1) {
                    return;
                }

                if (value === 0) {
                    this.ruleData = JSON.parse(JSON.stringify(this.defaultRuleData));
                } else {
                    let ruleData = JSON.parse(JSON.stringify(this.getAvitoRepubRule(value)));
                    ruleData.titles = createTags(Object.values(ruleData.titles))
                    ruleData.cities = createTags(Object.values(ruleData.cities))
                    ruleData.titles_exclude = createTags(Object.values(ruleData.titles_exclude))
                    ruleData.cities_exclude = createTags(Object.values(ruleData.cities_exclude))
                    ruleData.ids_exclude = createTags(Object.values(ruleData.ids_exclude))
                    ruleData.sheets_exclude = createTags(Object.values(ruleData.sheets_exclude))

                    if (ruleData.titles_exclude.length
                        || ruleData.cities_exclude.length
                        || ruleData.ids_exclude.length
                        || ruleData.sheets_exclude.length
                    ) {
                        this.excludeVisible = true;
                    }

                    this.ruleData = ruleData;
                }

                this.errorText = '';
                this.editFormVisible = false;

                this.showModal = !!this.ruleData;
            }
        );
    }
}
