
import Vue from "vue";
import Component from 'vue-class-component'

@Component
export default class PasswordSentPage extends Vue {
    async created(): Promise<void> {
        document.title = 'Восстановление пароля'

        this.$store.state.msg = ''

        if (this.$store.getters['Users/hasUser']) {
            this.$store.dispatch('redirectToHome')
        }
    }
}
