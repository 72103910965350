
import { Component, Vue, Prop } from "vue-property-decorator"
import User from "@/interfaces/User";
import axios, { AxiosResponse } from "axios";

@Component
export default class TDRefreshUserToken extends Vue {
    @Prop() item!: User;

    static componentName = "TDRefreshUserToken"

    wait = false;

    get animation(): string {
        return this.wait ? 'cylon' : ''
    }

    async refreshUserToken(userId: number): Promise<void> {
        if (this.wait) {
            return
        }
        this.wait = true;

        const url = await this.$store.dispatch('getFullUrl', 'users/' + userId + '/token');
        await axios.put<string>(
            url + this.$store.getters.hashQuery,
            null,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<string>) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    const token = res.data
                    this.$store.dispatch('UwT/changeUserTokenInUsersWithTables', { userId, token })
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.wait = false)
    }
}
