
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoRepubRule from "@/interfaces/AvitoRepubRule";

@Component
export default class TDAvitoRepubConditions_v2 extends Vue {
    @Prop() item?: AvitoRepubRule

    static componentName = 'TDAvitoRepubConditions_v2'
}
