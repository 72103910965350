
import TableList from "@/components/Finances/TableList.vue"
import { activity, statistics, statisticsForPeriod } from "@/types/statistics"
import axios, { AxiosResponse } from "axios"
import moment from "moment"
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        TableList,
    }
})
export default class FinancesStatisticsPage extends Vue {
    from = ''
    to = ''

    showActivities = true

    get activities(): activity[] {
        return this.$store.getters['Statistics/getStatistics'].activities
    }

    created(): void {
        document.title = 'Статистика'

        if (!this.$store.getters['Users/hasUser']) {
            return
        }

        this.$store.dispatch('Users/redirectIfNotAdmin')
            .then(async (res: boolean) => {
                if (res) {
                    return
                }

                this.$store.state.msg = ''
                this.$store.commit('SET_LOADING', true)
                Promise.all([
                    this.$store.dispatch('Statistics/setStatistics')
                ])
                    .finally(() => this.$store.commit('SET_LOADING', false))

                this.setDates()
            })
    }

    setDates(): void {
        this.from = moment().format('YYYY-MM-DD')
        this.to = moment().format('YYYY-MM-DD')
    }

    async choose(): Promise<void> {
        this.showActivities = false

        const from = this.from
        const to = this.to

        const url = await this.$store.dispatch('getFullUrl', 'statistics_v2/period');
        await axios.get<statisticsForPeriod>(
            url + '?from=' + from + '&to=' + to,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.update(res.data)
                        this.showActivities = true
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }

    update(statisticsForPeriod: statisticsForPeriod): void {
        const statistics: statistics = this.$store.getters['Statistics/getStatistics']

        statistics.activities.forEach((activity: activity, index: number) => {
            statistics.activities[index]['period'] = statisticsForPeriod[activity.code]
        })

        this.$store.commit('Statistics/SET_STATISTICS', statistics)

        //console.log(statistics.activities);
    }


    async download(url: string, defaultFileName: string): Promise<void> {
        this.showActivities = false;

        try {
            const response = await axios.get(url, { responseType: 'arraybuffer', validateStatus: (): boolean => true });

            const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
            const fileName = `${defaultFileName}_${timestamp}.xlsx`;

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        } catch (error: any) {
            const responseStatus = error.response?.status;
            console.error('Ошибка при загрузке файла:', error);
            this.$store.commit('SET_MSG_FROM_STATUS_CODE', responseStatus || 500);
        }

        this.showActivities = true;
    }

    async downloadUsersList(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'export/users');
        await this.download(url, 'users_list');
    }

    async downloadTablesList(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'export/tables');
        await this.download(url, 'tables_list');
    }
}
