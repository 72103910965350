export default [
    {
        name: 'Дата и время',
        code: 'created_at',
        class: 'd-flex align-items-center justify-content-center text-nowrap',
    },
    {
        name: 'Почта',
        code: 'email',
    },
    {
        name: 'Имя',
        code: 'name',
        class: 'd-flex align-items-center justify-content-center little text-nowrap',
    },
    {
        name: 'Номер',
        code: 'phoneNumber',
        class: 'd-flex align-items-center justify-content-center little text-nowrap',
    },
    {
        name: 'Связь',
        code: 'socialNetworkUrl',
        class: 'd-flex align-items-center justify-content-center little text-nowrap',
    },
    {
        name: 'Тип',
        code: 'type',
    },
    {
        name: 'Сумма',
        code: 'amount',
        class: 'coin d-flex align-items-center justify-content-center',
    },
]
