export default [
    {
        name: '',
        code: 'title',
        class: 'd-flex align-items-center ml-2 text-nowrap',
    },
    {
        name: 'Всего',
        code: 'all',
        class: 'd-flex align-items-center justify-content-center',
    },
    {
        name: 'В прошлом месяце',
        code: 'lastMonth',
        class: 'd-flex align-items-center justify-content-center',
    },
    {
        name: 'В этом месяце',
        code: 'month',
        class: 'd-flex align-items-center justify-content-center',
    },
    {
        name: 'Вчера',
        code: 'tomorrow',
        class: 'd-flex align-items-center justify-content-center',
    },
    {
        name: 'Сегодня',
        code: 'today',
        class: 'd-flex align-items-center justify-content-center',
    },
    {
        name: 'Период',
        code: 'period',
        class: 'd-flex align-items-center justify-content-center',
    },
]
