
import { credentialsForgot } from "@/types/auth"
import { ErrorResponse } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import { Vue, Component } from "vue-property-decorator";

@Component
export default class PasswordForgotComponent extends Vue {
    credentials: credentialsForgot = {
        email: ''
    }

    errorText = ''
    errorEmail = ''

    get validationEmail(): boolean {
        const { email } = this.credentials
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return email === '' || re.test(String(email).toLowerCase());
    }

    async onSubmit(): Promise<void> {
        if (this.credentials.email === '' || !this.validationEmail) {
            return
        }

        // if (this.$store.getters['Users/isUserBlocked']) {
        //     this.$store.commit('Users/SET_USER_IS_BLOCKED', false)
        // }

        const url = await this.$store.dispatch('getFullUrl', 'auth/passwordForgot');
        await axios.post<void | ErrorResponse>(
            url,
            this.credentials,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void | ErrorResponse>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.$router.push({ name: 'passwordSent', params: { email: this.credentials.email } })
                        break
                    case 403:
                        this.errorText = this.$store.state.errorMessages.blocked
                        break
                    case 404:
                        this.errorEmail = this.$store.state.validationErrors.userNotFound
                        break
                    case 429:
                        this.errorText = this.$store.state.errorMessages.tooManyRequests
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }
}
