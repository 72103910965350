
import TimePickerComponent from "@/components/Tools/TimePickerComponent.vue";
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        TimePickerComponent
    }
})
export default class DateGeneratorPage extends Vue {
    data = ''

    async created(): Promise<void> {
        document.title = 'Генератор дат'

    }
}
