
import TableList from "@/components/Finances/TableList.vue"
import { transaction } from "@/types/transactions"
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        TableList,
    }
})
export default class FinancesReferralsPage extends Vue {
    get referralsStatistics(): transaction[] {
        return this.$store.getters['Referrals/getReferralsStatistics']
    }

    created(): void {
        document.title = 'Партнеры'

        this.$store.dispatch('Users/redirectIfNotAdmin')
            .then(async (res: boolean) => {
                if (res) {
                    return
                }

                this.$store.state.msg = ''
                this.$store.commit('SET_LOADING', true)
                Promise.all([this.$store.dispatch('Referrals/setReferralsStatistics')])
                    .finally(() => this.$store.commit('SET_LOADING', false))
            })
    }
}
