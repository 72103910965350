
import ProfitPartnersWidget from "@/components/Finances/ProfitPartnersWidget.vue"
import ProfitWidget from "@/components/Finances/ProfitWidget.vue"
import ReferralsWidget from "@/components/Finances/ReferralsWidget.vue"
import TableList from "@/components/Finances/TableList.vue"
import WalletWidget from "@/components/Finances/WalletWidget.vue"
import TableColumn from "@/interfaces/TableColumn"
import { adminTransaction } from "@/types/transactions"
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        ReferralsWidget,
        ProfitWidget,
        TableList,
        WalletWidget,
        ProfitPartnersWidget,
    }
})
export default class FinancesPage extends Vue {
    currentPage = 1
    perPage = 10
    perPageVariants = [
        { active: true, perPage: 10 },
        { active: false, perPage: 30 },
        { active: false, perPage: 50 },
        { active: false, perPage: 100 }
    ]
    searchedText = ''
    loading = false
    timeout?: number
    filteredTransactions: adminTransaction[] = [];

    get icon(): string {
        return this.loading ? 'arrow-clockwise' : 'search'
    }

    get animation(): string {
        return this.loading ? 'spin' : ''
    }

    get rows(): number {
        return this.filteredTransactions.length
    }

    get columns(): TableColumn[] {
        if (this.$store.getters['Users/userIsAdmin']
            || this.$store.getters['Users/userIsAdminLite']
            || this.$store.getters['Users/userIsManager']
        ) {
            return this.$store.state.columns.adminTransactions
        }

        return this.$store.state.columns.transactions
    }

    get transactions(): adminTransaction[] {
        if (this.$store.getters['Users/userIsAdmin']
            || this.$store.getters['Users/userIsAdminLite']
            || this.$store.getters['Users/userIsManager']
        ) {
            return this.$store.getters['Transactions/getAdminTransactions']
        }

        return this.$store.getters['Transactions/getTransactions']
    }

    search(): void {
        clearTimeout(this.timeout)

        this.timeout = setTimeout(() => {
            setTimeout(() => this.loading = true)

            setTimeout(() => {
                const searchedText = this.searchedText.trim().toLowerCase()

                this.filteredTransactions = this.transactions
                    .filter((transaction): boolean => {
                        if (!transaction) {
                            return false
                        }

                        const { created_at, email, name, phoneNumber, socialNetworkUrl, type, amount } = transaction

                        return (!!created_at && created_at.toLowerCase().indexOf(searchedText) !== -1) ||
                            (!!email && email.toLowerCase().indexOf(searchedText) !== -1) ||
                            (!!name && name.toLowerCase().indexOf(searchedText) !== -1) ||
                            (!!phoneNumber && phoneNumber.toLowerCase().indexOf(searchedText) !== -1) ||
                            (!!socialNetworkUrl && socialNetworkUrl.toLowerCase().indexOf(searchedText) !== -1) ||
                            (!!type && type.toLowerCase().indexOf(searchedText) !== -1) ||
                            (!!amount && String(amount).indexOf(searchedText) !== -1);

                    })

                this.loading = false
            })
        }, 1000)
    }

    created(): void {
        document.title = 'Финансы'

        if (!this.$store.getters['Users/hasUser']) {
            return
        }

        this.$store.state.msg = ''
        this.$store.commit('SET_LOADING', true)
        Promise.all([this.$store.dispatch('Transactions/setTransactions')])
            .finally(() => {
                this.$store.commit('SET_LOADING', false)
                this.filteredTransactions = this.transactions;
            })
    }

    changePaginate(variant: { active: boolean, perPage: number }): void {
        this.perPageVariants.forEach((variant) => variant.active = false)
        this.perPage = variant.perPage
        variant.active = true
    }
}
