
import TDAddTable from "@/components/TableList/TableData/TDAddTable.vue";
import TableColumn from "@/interfaces/TableColumn"
import {Vue, Prop, Component} from "vue-property-decorator";
import TableItemComponent from "@/components/TableList/TableItemComponent.vue"
import TableListComponent from "@/components/TableList/TableListComponent.vue";
import User from "@/interfaces/User";
import UserTables from "@/interfaces/UserTables";
import TDAddTable_v4 from "@/components/TableList/TableData/TDAddTable_v4.vue";

@Component({
    components: {
        TDAddTable_v4,
        TDAddTable,
        TableItemComponent,
        TableListComponent,
    },
})
export default class UserRowComponent extends Vue {
    @Prop() index!: number;
    @Prop() row?: User|UserTables;
    @Prop() columns?: TableColumn[];
}
