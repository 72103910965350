
import {Vue, Prop, Component} from "vue-property-decorator";
import TableColumn from "@/interfaces/TableColumn"
import TableHeaderComponent from "@/components/TableList/TableHeaderComponent.vue";
import ImagesTransformRowComponent from "@/components/TableList/ImagesTransformRowComponent.vue";
import ImagesTransformRule from "@/interfaces/ImagesTransformRule";

@Component({
    components: {
        TableHeaderComponent,
        ImagesTransformTableListComponent,
        ImagesTransformRowComponent,
    },
})
export default class ImagesTransformTableListComponent extends Vue {
    @Prop() rules!: ImagesTransformRule[]
    @Prop() columns?: TableColumn[]
    @Prop() perPage?: number
    @Prop() currentPage?: number

    get itemsPaginated(): ImagesTransformRule[] {
        if (!!this.perPage && !!this.currentPage && (this.perPage < this.rules.length)) {
            const perPage = this.perPage

            const start = (this.currentPage - 1) * perPage
            const end = start + perPage

            return this.rules.slice(start, end)
        }

        return this.rules
    }
}
