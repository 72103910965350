
import LoginComponent from "@/components/Auth/LoginComponent.vue"
import Vue from "vue";
import Component from 'vue-class-component'

@Component({
    components: {
        LoginComponent
    }
})
export default class LoginPage extends Vue {
    async created(): Promise<void> {
        document.title = 'Вход в приложение сервиса автоматизации'

        this.$store.state.msg = ''

        if (this.$store.getters['Users/hasUser']) {
            await this.$store.dispatch('redirectToHome')
        }
    }
}
