
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoServicesRule from "@/interfaces/AvitoServicesRule";

@Component
export default class TDAvitoServicesRangeParam extends Vue {
    @Prop() item?: AvitoServicesRule

    static componentName = 'TDAvitoServicesRangeParam'

    get text(): string {
        if (this.item) {
            switch (this.item.range_param) {
                case 'views':
                    return 'Просмотры'
                case 'contacts':
                    return 'Контакты'
                case 'favorites':
                    return 'Избранное'
                case 'conversions':
                    return 'Конверсии'
                default:
                    return ''
            }
        } else {
            return '';
        }
    }
}
