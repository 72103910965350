
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoRepubHistory_v2 from "@/interfaces/AvitoRepubHistory_v2";

@Component
export default class TDAvitoRepubHistoryStats_v2 extends Vue {
    @Prop() item!: AvitoRepubHistory_v2

    static componentName = 'TDAvitoRepubHistoryStats_v2'

    get views() {
        return this.item.stats.views;
    }

    get contacts() {
        return this.item.stats.contacts;
    }

    get favorites() {
        return this.item.stats.favorites;
    }

    get conversions() {
        const value = this.item.stats.conversions;
        return Number.isInteger(value) ? value.toString() : value.toFixed(1);
    }
}
