<template>
    <div class="time-picker" style="width: 100%">
        <div class="card">
            <div class="card-header">
                <div class="text-start">Генератор дат</div>
            </div>
            <div class="p-2 w-100">
                <div class="row mx-0 mb-4">
                    <div class="col-lg-8 col-12 d-flex flex-column pl-0 pr-0 pr-lg-2">
                        <div class="d-flex align-items-stretch mb-4">
                            <div class="col-6 pl-0 pr-2">
                                <div class="d-flex flex-column">
                                    <label class="mb-1"> Начало </label>
                                    <div class="d-flex align-items-center">
                                        <b-form-datepicker
                                            id="example-datepicker"
                                            v-model="dateStart"
                                            class="mb-1"
                                            :date-format-options="{
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                              }"
                                            locale="ru"
                                            today-button
                                        ></b-form-datepicker>
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <label class="mb-1"> Конец </label>
                                    <div class="d-flex align-items-center">
                                        <b-form-datepicker
                                            id="example-datepicker_1"
                                            v-model="dateEnd"
                                            :date-format-options="{
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                              }"
                                            locale="ru"
                                        ></b-form-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 px-0">
                                <label class="mb-1"> Количество </label>
                                <b-input-group class="d-flex">
                                    <b-form-input v-model="amount" type="number" style="margin-bottom: 32px"></b-form-input>
                                </b-input-group>
                                <b-button-group class="w-100">
                                    <b-button
                                        @click="calculateResult"
                                        variant="success"
                                    > Сгенерировать даты начала
                                    </b-button>
                                </b-button-group>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="col-6 pt-5 pt-md-0 pl-0 pr-2">
                                <div class="d-flex pt-5 pt-md-0 mb-2rem">
                                    <b-button-group>
                                        <b-button
                                            v-for="(btn, idx) in weekDaysButtons"
                                            :key="idx"
                                            :pressed.sync="btn.state"
                                            variant="outline-primary"
                                            class="mb-3"
                                            v-model="chooseDay"
                                        >
                                            {{ btn.caption }}
                                        </b-button>
                                    </b-button-group>
                                </div>
                                <div
                                    class="hours-height left-margin d-flex mx-1 col-6 flex-column flex-wrap"
                                >
                                    <div
                                        v-for="(item, idx) in hoursButtons"
                                        :key="idx"
                                        class="checkbox-margin"
                                    >
                                        <b-form-checkbox
                                            :checked="item.state"
                                            class="m-lg-0 m-3"
                                            variant="outline-primary"
                                            @change="(checked) => chooseTime(item, checked)"
                                        >
                                            <span class="mx-1">{{ item.caption }}</span>
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 px-0">
                                <b-button-group class="w-100">
                                    <b-button
                                        @click="generateDatesEnd"
                                        variant="success"
                                    >Сгенерировать даты окончания
                                    </b-button>
                                </b-button-group>
                            </div>
                        </div>
                    </div>
                    <div class="result-height col-12 col-lg-4 d-flex px-0">
                        <div class="col-6 pl-0 pr-2">
                            <label class="mb-1 ">Даты публикации</label>
                            <b-form-textarea
                                id="textareaStart"
                                v-model="resultStart"
                                :placeholder="resultStartPlaceholder"
                                class="mb-4"
                                style="height:calc(100% - 70px); resize: none"
                            ></b-form-textarea>
                            <b-button-group class="d-flex justify-content-end">
                                <b-button
                                    v-clipboard:copy="resultStart"
                                    class="flex-grow-0"
                                > Скопировать
                                    <b-icon class="d-lg-none d-xl-inline-block" icon="files"></b-icon>
                                </b-button>
                            </b-button-group>
                        </div>
                        <div class="col-6 px-0">
                            <label class="mb-1">Даты окончания</label>
                            <b-form-textarea
                                id="textareaEnd"
                                v-model="resultEnd"
                                :placeholder="resultEndPlaceholder"
                                class=" mb-4"
                                style="height:calc(100% - 70px);resize: none"
                            ></b-form-textarea>
                            <b-button-group class="d-flex justify-content-end">
                                <b-button
                                    v-clipboard:copy="resultEnd"
                                    class="flex-grow-0"
                                > Скопировать
                                    <b-icon class="d-lg-none d-xl-inline-block" icon="files"></b-icon>
                                </b-button>
                            </b-button-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import moment from "moment";

export default {
    data() {
        return {
            dateStart: moment().format("YYYY-MM-DD"),
            dateEnd: moment().add(30, "days").format("YYYY-MM-DD"),
            amount: 100,
            resultStart: "",
            resultEnd: "",
            weekDaysButtons: [
                { id: 1, caption: "Пн", state: true },
                { id: 2, caption: "Вт", state: true },
                { id: 3, caption: "Ср", state: true },
                { id: 4, caption: "Чт", state: true },
                { id: 5, caption: "Пт", state: true },
                { id: 6, caption: "Сб", state: true },
                { id: 7, caption: "Вс", state: true },
            ],

            hoursButtons: [
                { caption: "00", state: false },
                { caption: "01", state: false },
                { caption: "02", state: false },
                { caption: "03", state: false },
                { caption: "04", state: false },
                { caption: "05", state: false },
                { caption: "06", state: false },
                { caption: "07", state: false },
                { caption: "08", state: true },
                { caption: "09", state: true },
                { caption: "10", state: true },
                { caption: "11", state: true },
                { caption: "12", state: true },
                { caption: "13", state: true },
                { caption: "14", state: true },
                { caption: "15", state: true },
                { caption: "16", state: true },
                { caption: "17", state: true },
                { caption: "18", state: true },
                { caption: "19", state: false },
                { caption: "20", state: false },
                { caption: "21", state: false },
                { caption: "22", state: false },
                { caption: "23", state: false },
            ],
            resultStartPlaceholder: "Чтобы сгенерировать даты публикации, выберите интервал в датах, часы, в которые актуально публиковать объявления, количество и нажмите \"Сгенерировать даты окончания\".\n Здесь появятся равномерно распределенные, даты и время публикации.",
            resultEndPlaceholder: "Чтобы сгенерировать даты окончания, добавьте или сгенерируйте даты публикации и нажмите \"Сгенерировать даты окончания\". Алгоритм автоматически прибавит 29 дней и 12 часов к датам.\nЭто сделано, чтобы объявление заранее ушло в архив и не опубликовалось повторно, в случае сбоев на стороне Авито.",
        }
    },
    computed: {
        parsedDateStart() {
            return moment(this.dateStart)
        },
        parsedDateEnd() {
            return moment(this.dateEnd)
        }
    },
    methods: {
        chooseTime(item, checked) {
            const found = this.hoursButtons.find((i) => item.caption === i.caption);
            found.state = checked;
        },
        chooseDay(item, checked) {
            const found = this.weekDaysButtons.find((i) => item.caption === i.caption)
            found.state = checked
        },
        calculateResult() {
            const chosenWeekDays = this.weekDaysButtons
                .filter((i) => i.state)
                .map(i => i.id)
            const chosenHours = this.hoursButtons
                .filter((i) => i.state)
                .map(i => i.caption)
            if (!this.validate(chosenWeekDays, chosenHours)) {
                return
            }

            const dates = new Map()
            let currentDate = this.getNextWeekDayDate(this.dateStart, chosenWeekDays)

            while (!moment(currentDate).isAfter(this.dateEnd)) {
                currentDate = this.getNextWeekDayDate(currentDate, chosenWeekDays)
                dates.set(currentDate, this.getDatesForHoursInDay(currentDate, chosenHours))
                currentDate.add(1, 'day')
            }

            let countOfDates = 0;
            for (let hoursInDays of dates.values()) {
                countOfDates += hoursInDays.length
            }

            let interval = Math.max(60 / (this.amount / countOfDates), 1);

            let minute = 0;
            const resultDates = [];
            for (let hoursInDays of dates.values()) {
                for (let hour of hoursInDays) {
                    while (minute < 60 && (resultDates.length < this.amount)) {
                        let momentInHour = moment(hour).minute(Math.round(minute))
                        resultDates.push(momentInHour.format("DD.MM.YYYY HH:mm"))
                        minute += interval
                    }
                    minute -= 60;
                }
            }
            this.resultStart = resultDates.join("\n")
        },
        generateDatesEnd() {
            const dates = this.resultStart.split("\n")
            let error = false
            let errorLine = 0
            const output = dates.map((item, index) => {
                if (error) {
                    return
                }

                item = item.trim()
                if (item === '') {
                    return ''
                }
                let parsedDate = moment(item, "DD.MM.YYYY HH:mm")
                if (!parsedDate.isValid()) {
                    parsedDate = moment(item)
                    if (!parsedDate.isValid()) {
                        error = true
                        errorLine = index + 1
                    }
                }
                return parsedDate
                    .add(30, "days")
                    .subtract(12, 'hours')
                    .format("DD.MM.YYYY HH:mm")
            })
            if (error) {
                this.resultEnd = "Ошибка на строчке " + errorLine
                return
            }
            this.resultEnd = output.join("\n")
        },
        validate(chosenWeekDays, chosenHours) {
            if (chosenWeekDays.length === 0) {
                this.resultStart = "Выберите по крайней мере 1 день недели";
                return false
            }
            if (chosenHours.length === 0) {
                this.resultStart = "Выберите по крайней мере 1 час";
                return false
            }
            if (!this.dateStart) {
                this.resultStart = "Укажите дату начала";
                return false
            }
            if (!this.dateEnd) {
                this.resultStart = "Укажите дату конца";
                return false
            }
            if (this.amount <= 0 || this.amount > 10000) {
                this.resultStart = "Количество должно быть в интервале от 1 до 10000";
                return false
            }
            if (this.parsedDateStart.isAfter(this.parsedDateEnd)) {
                this.resultStart = "Дата начала не должна быть больше даты конца"
                return false
            }

            return true
        },
        getNextWeekDayDate(dateStart, chosenWeekDays) {
            const startWeekDay = moment(dateStart).isoWeekday()
            const tomorrow = moment(dateStart).add(1, 'days')
            if (chosenWeekDays.includes(startWeekDay)) {
                return moment(dateStart)
            }
            return this.getNextWeekDayDate(tomorrow, chosenWeekDays)
        },
        getDatesForHoursInDay(currentDate, chosenHours) {
            return chosenHours.map(i => moment(currentDate).hour(i).minute(0))
        },
    },
};
</script>

<style scoped lang="scss">
.time-picker {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    .btn.btn-outline-primary.active {
        background-color: #28a5a8;
    }

    .btn.btn-outline-primary.active:hover {
        background-color: #28a5a8;
        color: white;
    }

    .btn.btn-outline-primary:hover {
        background-color: white;
        color: #0d6efd;
    }

    .btn-outline-primary:not(:disabled):not(.disabled).active {
        border-color: #31c5c9;
    }

    .btn.btn-success {
        border-color: #28a5a8;
        background-color: #28a5a8;
    }

    .col-padding-r-3 {
        padding-left: 0;
        padding-right: 12px;
    }

    .result-height {
        height: 600px
    }

    .hours-height {
        height: 288px
    }

    @media(min-width: 992px) {
        .checkbox-margin {
            margin-right: 24px;
        }
    }
    @media(max-width: 991px) {
        .left-margin {
            margin-left: -16px !important;
        }

        .result-height {
            height: 350px
        }
    }
    @media(max-width: 767px) {
        .result-height {
            height: 420px
        }
    }
    @media(max-width: 575px) {
        .result-height {
            height: 600px
        }

        .hours-height {
            height: 342px
        }
    }
}
</style>
