export default [
    {
        name: 'title',
        code: 'title',
        class: 'd-flex align-items-center ml-2 text-nowrap',
    },
    {
        name: 'value',
        code: 'value',
        class: 'd-flex align-items-center justify-content-end mr-2',
    },
]
