
import {Vue, Prop, Component} from "vue-property-decorator";
import TableColumn from "@/interfaces/TableColumn"
import TableHeaderComponent from "@/components/TableList/TableHeaderComponent.vue";
import AvitoRepubHistoryRowComponent_v2 from "@/components/TableList/AvitoRepubHistoryRowComponent_v2.vue";
import AvitoRepubHistory_v2 from "@/interfaces/AvitoRepubHistory_v2";

@Component({
    components: {
        TableHeaderComponent,
        AvitoRepubTableListComponent_v2,
        AvitoRepubHistoryRowComponent_v2,
    },
})
export default class AvitoRepubTableListComponent_v2 extends Vue {
    @Prop() history!: AvitoRepubHistory_v2[]
    @Prop() columns?: TableColumn[]
    @Prop() perPage?: number
    @Prop() currentPage?: number

    get itemsPaginated(): AvitoRepubHistory_v2[] {
        if (!!this.perPage && !!this.currentPage && (this.perPage < this.history.length)) {
            const perPage = this.perPage

            const start = (this.currentPage - 1) * perPage
            const end = start + perPage

            return this.history.slice(start, end)
        }

        return this.history
    }
}
