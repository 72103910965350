
import { Vue, Component, Prop } from 'vue-property-decorator';
import TreeNode from "@/components/Tree/TreeNode.vue";


@Component({
    components: {TreeNode}
})
export default class TreeView extends Vue {
    @Prop({ type: Object, required: true }) nodes!: any[];
    @Prop({ type: Array, required: true }) selectedNodes!: string[];

    handleSelectTreeCategory(data) {
        this.$emit('selectTreeCategory', data);
    }
}
