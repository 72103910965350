
import _ from "lodash-es";
import vSelect from "vue-select"
import { BFormTags } from 'bootstrap-vue'
import "vue-select/dist/vue-select.css"
import { Vue, Component, Prop, Watch } from "vue-property-decorator"

@Component({
    components: {
        vSelect,
        BFormTags
    }
})

export default class MonitoringTaskEditComponent extends Vue {
    @Prop() taskId!: number;

    allDays = [
        {label: 'пн', code: '1'},
        {label: 'вт', code: '2'},
        {label: 'ср', code: '3'},
        {label: 'чт', code: '4'},
        {label: 'пт', code: '5'},
        {label: 'сб', code: '6'},
        {label: 'вс', code: '7'}
    ];

    avitoPackagesOptions = [
        {label: 'Подсветка', code: 'hl'},
        {label: 'Размер XL', code: 'xl'},
        {label: 'X2 (1 день)', code: 'x2_1'},
        {label: 'X2 (7 дней)', code: 'x2_7'},
        {label: 'X5 (1 день)', code: 'x5_1'},
        {label: 'X5 (7 дней)', code: 'x5_7'},
        {label: 'X10 (1 день)', code: 'x10_1'},
        {label: 'X10 (7 дней)', code: 'x10_7'}
    ];

    targetPositionOptions = Array.from({length: 50}, (_, i) => i + 1);

    emptyTask = {
        id: null,
        project_id: this.projectId,
        active: false,
        name: '',
        url: '',
        items: [],
        target_position: 5,
        days: ['1', '2', '3', '4', '5', '6', '7'],
        time_intervals: [],
        notifications: true,
        packages_list: [],
        packages_max_applying: 1,
        packages_max_sum: 100,
        telegram_secret: ''
    };

    submited = false;

    editedTaskData = _.clone(this.emptyTask);

    get submitNameError(): boolean | null {
        if (this.submited && this.emptyTask.name.length === 0) {
            return false;
        }
        return null;
    }

    get submitUrlError(): boolean | null {
        if (this.submited && !(/https?:\/\/(www\.)?avito.ru/.test(this.emptyTask.url))) {
            return false;
        }
        return null;
    }

    get submitItemsError(): boolean | null {
        if (this.submited && this.emptyTask.items.length === 0) {
            return false;
        }
        return null;
    }

     get projectId(): number | null {
        return this.$store.getters['AvitoMonitoring/getProjectId']
    }

    get editedTaskId(): number | null {
        return this.$store.getters['AvitoMonitoring/getEditedTaskId']
    }

    get showTaskEdit(): boolean {
        return (this.$store.getters['AvitoMonitoring/getEditedTaskId'] !== null);
    }

    itemsValidator(item: string): boolean {
        return /^\d+$/.test(item)
    }

    selectableDays (option: any): boolean {
        return _.indexOf(this.editedTaskData.days, option.code) === -1;
    }

    selectablePackages (option: any): boolean {
        if (/^x\d/.test(option.code) && _.filter (this.editedTaskData.packages_list, (o: any) => /^x\d/.test(o)).length) {
            return false;
        }

        return _.indexOf(this.editedTaskData.packages_list, option.code) <= -1;
    }

    get taskEnabledText(): string {
        return this.editedTaskData.active ? 'Задача активна' : 'Задача выключена';
    }

    get notificationsEnabledText(): string {
        return this.editedTaskData.notifications ? 'Уведомления включены' : 'Уведомления выключены';
    }

    get validationError(): string {
        return this.$store.getters['AvitoMonitoring/getValidationError']
    }

    get copy(): string {
        return String(this.editedTaskData.telegram_secret)
    }

    wait = false

    onCancel(): void {
        this.$store.commit('AvitoMonitoring/SET_AVITO_MONITORING_EDITED_TASK_ID', null)
    }

    async onSubmit(): Promise<void> {
        this.submited = true;

        if (this.submitNameError === false || this.submitUrlError === false || this.submitItemsError === false) {
            return
        }

        if (this.wait) {
            return
        }
        this.wait = true;

        Promise.all([this.$store.dispatch('AvitoMonitoring/editTask', this.editedTaskData)])
            .finally(() => {
                this.wait = false;
                this.submited = false;
            })
    }

    created(): void {

    }

    @Watch('editedTaskId')
    onChildChanged() {
        if (this.editedTaskId !== null) {
            var data = this.editedTaskId > 0
                ? this.$store.getters['AvitoMonitoring/getEditedTaskData']
                : _.clone(this.emptyTask);

            Vue.set(this.editedTaskData, 'id', data.id);
            Vue.set(this.editedTaskData, 'name', data.name);
            Vue.set(this.editedTaskData, 'url', data.url);
            Vue.set(this.editedTaskData, 'items', data.items);
            Vue.set(this.editedTaskData, 'target_position', data.target_position);
            Vue.set(this.editedTaskData, 'days', data.days);
            Vue.set(this.editedTaskData, 'time_intervals', data.time_intervals);
            Vue.set(this.editedTaskData, 'notifications', data.notifications);
            Vue.set(this.editedTaskData, 'packages_list', data.packages_list);
            Vue.set(this.editedTaskData, 'packages_max_applying', data.packages_max_applying);
            Vue.set(this.editedTaskData, 'packages_max_sum', data.packages_max_sum);
            Vue.set(this.editedTaskData, 'telegram_secret', data.telegram_secret);
        }
    }
}
