
import CoordinatesGeneratorComponent from "@/components/Tools/CoordinatesGeneratorComponent.vue";
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        CoordinatesGeneratorComponent
    }
})
export default class CoordinatesGeneratorPage extends Vue {

    async created(): Promise<void> {
        document.title = 'Генератор координат'

    }
}
