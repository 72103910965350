
import { invitation } from "@/types/invitations"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDReferralsNotes extends Vue {
    @Prop() item!: invitation;

    static componentName = "TDReferralsNotes"
}
