
import router from "@/router"
import { Vue, Component } from "vue-property-decorator";

@Component
export default class WalletBalance extends Vue {
    get balance(): string {
        return this.$store.getters["Wallet/getBalance"]
    }

    get showWallet(): boolean {
        return (router.currentRoute.name !== 'userInfo') &&
            this.$store.getters['Users/hasUser'] &&
            this.$store.getters['Users/hasVerifyEmail'] &&
            !this.$store.getters['hasHash']
    }

    created(): void {
        if (this.showWallet) {
            this.$store.dispatch('Wallet/setBalance')
        }
    }
}
