import RootState from "@/interfaces/RootState"
import TransactionsState from "@/interfaces/TransactionsState"
import { adminTransaction, transaction } from "@/types/transactions"
import { ErrorResponse } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import { ActionContext, Module } from "vuex"

const Transactions: Module<TransactionsState, RootState> = {
    namespaced: true,
    state: {
        loading: false,
        transactions: [] as transaction[],
        adminTransactions: [] as adminTransaction[],
    },
    mutations: {
        SET_TRANSACTIONS(state: TransactionsState, transactions: transaction[]): void {
            state.transactions = transactions
        },
        SET_ADMIN_TRANSACTIONS(state: TransactionsState, adminTransactions: adminTransaction[]): void {
            state.adminTransactions = adminTransactions
        },
    },
    actions: {
        async setTransactions(
            { dispatch, rootGetters }: ActionContext<TransactionsState, RootState>
        ): Promise<void> {
            if (rootGetters['Users/userIsAdmin']
                || rootGetters['Users/userIsAdminLite']
                || rootGetters['Users/userIsManager']
            ) {
                await dispatch('setAdminTransactions')
                return
            }

            await dispatch('setClientTransactions')
        },

        async setClientTransactions(
            { commit, dispatch, getters }: ActionContext<TransactionsState, RootState>
        ): Promise<void> {
            if (getters.hasTransactions) {
                return
            }

            const url = await dispatch('getFullUrl', 'transactions', { root: true });
            await axios.get<transaction[] | ErrorResponse>(
                url,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 200:
                            commit('SET_TRANSACTIONS', res.data)
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        },

        async setAdminTransactions(
            { commit, dispatch, getters }: ActionContext<TransactionsState, RootState>
        ): Promise<void> {
            if (getters.hasAdminTransactions) {
                return
            }

            const url = await dispatch('getFullUrl', 'transactions', { root: true });
            await axios.get<adminTransaction[] | ErrorResponse>(
                url,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 200:
                            commit('SET_ADMIN_TRANSACTIONS', res.data)
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        }
    },
    getters: {
        getTransactions(state: TransactionsState): transaction[] {
            return state.transactions
        },
        hasTransactions(state: TransactionsState): boolean {
            return state.transactions.length > 0
        },
        getAdminTransactions(state: TransactionsState): adminTransaction[] {
            return state.adminTransactions
        },
        hasAdminTransactions(state: TransactionsState): boolean {
            return state.adminTransactions.length > 0
        }
    }
}

export default Transactions
