
import {Component, Vue, Prop} from "vue-property-decorator"
import {Getter, Mutation} from "vuex-class";
import AvitoRepubRule from "@/interfaces/AvitoRepubRule";
import {maska} from 'maska'
import axios, {AxiosResponse} from "axios";

@Component({
    components: {},
    directives: {
        maska
    }
})
export default class TDAddRepubRule extends Vue {
    @Prop() userId?: number;

    @Getter("AvitoRepub/getSelectedRowIndex") selectedRowIndex!: number;

    @Getter("AvitoRepub/getAvitoRepubRule") getAvitoRepubRule!: (
        id: number
    ) => AvitoRepubRule | undefined;

    @Mutation("AvitoRepub/SET_SELECTED_ROW_INDEX") setSelectedRowIndex!: (
        rowIndex: number
    ) => void;

    static componentName = "TDAddRepubRule"

    ruleData: AvitoRepubRule | null = null;
    showModal: boolean = false;
    wait: boolean = false;
    errorText: string = '';

    defaultRuleData: AvitoRepubRule = {
        id: 0,
        table_id: 0,
        googleSheetId: "",
        city: "",
        title: "",
        repub_stat: {
            use_all: true,
            views: {value: 0, use: true},
            contacts: {value: 0, use: true},
            favorites: {value: 0, use: true},
            conversions: {value: 0, use: true},
        },
        delete_stat: {
            use_all: true,
            views: {value: 0, use: true},
            contacts: {value: 0, use: true},
            favorites: {value: 0, use: true},
            conversions: {value: 0, use: true},
        },
        pub_days: 30,
        days_to_delete: 1,
        randomize_text: false,
    };

    showTooltipTitle: boolean = false;
    showTooltipCity: boolean = false;
    showTooltipPubDays: boolean = false;
    showTooltipRepub: boolean = false;
    showTooltipRepubUseAll: boolean = false;
    showTooltipRepubUseOne: boolean = false;
    showTooltipDelete: boolean = false;
    showTooltipDaysToDelete: boolean = false;
    showTooltipDeleteUseAll: boolean = false;
    showTooltipDeleteUseOne: boolean = false;

    showConfirmationModal: boolean = false;

    toggleTooltipTitle(): void {
        this.showTooltipTitle = !this.showTooltipTitle;
    }

    toggleTooltipCity(): void {
        this.showTooltipCity = !this.showTooltipCity;
    }

    toggleTooltipPubDays(): void {
        this.showTooltipPubDays = !this.showTooltipPubDays;
    }

    toggleTooltipRepub(): void {
        this.showTooltipRepub = !this.showTooltipRepub;
    }

    toggleTooltipRepubUseAll(): void {
        this.showTooltipRepubUseAll = !this.showTooltipRepubUseAll;
    }

    toggleTooltipRepubUseOne(): void {
        this.showTooltipRepubUseOne = !this.showTooltipRepubUseOne;
    }

    toggleTooltipDelete(): void {
        this.showTooltipDelete = !this.showTooltipDelete;
    }

    toggleTooltipDaysToDelete(): void {
        this.showTooltipDaysToDelete = !this.showTooltipDaysToDelete;
    }


    toggleTooltipDeleteUseAll(): void {
        this.showTooltipDeleteUseAll = !this.showTooltipDeleteUseAll;
    }

    toggleTooltipDeleteUseOne(): void {
        this.showTooltipDeleteUseOne = !this.showTooltipDeleteUseOne;
    }

    confirmAction(): void {
        this.showConfirmationModal = false;
    }

    newRule(): void {
        this.$store.commit("AvitoRepub/SET_SELECTED_ROW_INDEX", 0);
    }

    resetRuleData(): void {
        this.$store.commit("AvitoRepub/SET_SELECTED_ROW_INDEX", -1);
    }

    get modalTitle(): string {
        return this.ruleData && this.ruleData.id === 0 ? 'Новое правило' : 'Редактирование правила';
    }

    get saveButtonTitle(): string {
        return this.ruleData && this.ruleData.id === 0 ? 'Запустить правило' : 'Сохранить';
    }

    get disableSubmit(): boolean {
        return !this.ruleData?.title || !this.ruleData?.city || this.wait;
    }

    limitValue(obj: any, propName: string, min: number, max: number): void {
        let value = Number(obj[propName]);

        if (isNaN(value)) {
            value = min;
        } else {
            if (value < min) {
                value = min;
            } else if (value > max) {
                value = max;
            }
        }

        obj[propName] = value;
    }

    async saveRule(e): Promise<void> {
        e.preventDefault()

        this.wait = true;
        this.errorText = '';

        var rule = this.ruleData

        if (rule) {
            rule.table_id = this.$store.getters["AvitoRepub/getSelectedTableId"];
        }

        console.log(rule);

        const url = await this.$store.dispatch('getFullUrl', 'avito-repub');
        await axios.put<void>(
            url + this.$store.getters.hashQuery,
            rule,
            {validateStatus: (): boolean => true}
        )
            .then<void, never>((res: AxiosResponse<void>) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    var response = JSON.parse(res.request.response);
                    if (response.status == 'error') {
                        this.errorText = response.text;
                    } else {
                        this.showModal = false;
                        this.$store.dispatch('AvitoRepub/setAvitoRepubRules');
                        this.$emit('add-new-rule');

                        if (this.ruleData.id === 0) {
                            this.showConfirmationModal = true;
                        }
                    }
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.wait = false;
            })
    }

    mounted() {
        this.$watch(
            () => this.selectedRowIndex,
            (newValue: number, oldValue: number) => {
                if (newValue === -1) {
                    return;
                }

                if (newValue === 0) {
                    this.ruleData = JSON.parse(JSON.stringify(this.defaultRuleData));
                } else {
                    this.ruleData = JSON.parse(JSON.stringify(this.getAvitoRepubRule(newValue))) || null;
                }

                this.errorText = '';

                this.showModal = !!this.ruleData;
            }
        );
    }
}
