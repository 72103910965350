
import {Vue, Prop, Component} from "vue-property-decorator";

//import * as external from '@/plugins/external.js'

@Component
export default class CoordinatesGeneratorComponent extends Vue {

    /*created(): void {

    }*/

    mounted(): void {
        var scripts = [
            "https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=b0be2641-3d8b-4595-b5c5-a2c6aa3e24bc"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

    }
}
