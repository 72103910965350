
import UserInfoComponent from "@/components/UserInfo/UserInfoComponent.vue"
import User from "@/interfaces/User"
import { credentialsForgot } from "@/types/auth"
import { ErrorResponse } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import { Component, Vue } from 'vue-property-decorator'

@Component({
    components: {
        UserInfoComponent
    }
})
export default class UserSettings extends Vue {
    user!: User
    client_secret = ''
    wait = false

    credentials: credentialsForgot = {
        email: this.$store.getters['Users/getUser'].email
    }

    get showClientSecret(): boolean {
        return !this.wait
    }

    get copy(): string {
        return String(this.client_secret)
    }

    async created(): Promise<void> {
        document.title = 'Настройки'

        if (!this.$store.getters['Users/hasUser']) {
            return
        }

        this.$store.state.msg = ''
        this.setUserInfo()
        await this.getClientSecret()
    }

    setUserInfo(): void {
        this.user = this.$store.getters["Users/getUser"];
    }

    async getClientSecret(): Promise<void> {
        if (this.wait) {
            return
        }
        this.wait = true

        const url = await this.$store.dispatch('getFullUrl', 'users/accessClient');
        await axios.get<string>(
            url,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<string>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.client_secret = res.data
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.wait = false)
    }

    async storeClientSecret(): Promise<void> {
        if (this.wait) {
            return
        }
        this.wait = true

        const url = await this.$store.dispatch('getFullUrl', 'users/accessClient');
        await axios.post<string>(
            url,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<string>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.client_secret = res.data
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.wait = false)
    }

    async saveInfo(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'users/' + this.user.userId);
        await axios.put<User>(
            url,
            this.user,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<User>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.$bvModal.msgBoxOk(
                            'Сохранено',
                            {
                                centered: true
                            }
                        )
                        break
                    default:
                        this.$bvModal.msgBoxOk(
                            'Что-то пошло не так',
                            {
                                centered: true,
                                bodyTextVariant: 'danger'
                            }
                        )
                }
            })
            .catch(err => console.error(err))
    }

    async changePassword(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'auth/passwordForgot');
        await axios.post<void | ErrorResponse>(
            url,
            this.credentials,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void | ErrorResponse>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.$bvModal.msgBoxOk(
                            'Письмо с инструкциями для восстановления пароля было отправлено на ' +
                            this.$store.getters['Users/getUser'].email,
                            {
                                centered: true
                            }
                        )
                        break
                    default:
                        this.$bvModal.msgBoxOk(
                            'Что-то пошло не так',
                            {
                                centered: true,
                                bodyTextVariant: 'danger'
                            }
                        )
                }
            })
            .catch(err => console.error(err))
    }
}
