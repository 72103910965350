
import { invitation } from "@/types/invitations"
import axios, { AxiosResponse } from "axios"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDInvitationNotes extends Vue {
    @Prop() item!: invitation;

    static componentName = "TDInvitationNotes"

    wait = false

    async onSubmit(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'users/invitations/' + this.item.invitationHash + '/notes');

        await axios.put(url, { notes: this.item.notes })
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                if (statusCode !== 201) {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }
}
