
import { UserWithTables } from "@/types/types"
import {Vue, Prop, Component} from "vue-property-decorator";
import TableColumn from "@/interfaces/TableColumn"
import TableItemComponent from "./TableItemComponent.vue"
import TableHeaderComponent from "@/components/TableList/TableHeaderComponent.vue";
import UserRowComponent from '@/components/TableList/UserRowComponent.vue'

@Component({
    components: {
        TableHeaderComponent,
        TableItemComponent,
        TableListComponent,
        UserRowComponent,
    },
})
export default class TableListComponent extends Vue {
    @Prop() userWithTables!: UserWithTables
    @Prop() columns?: TableColumn[]
    @Prop() perPage?: number
    @Prop() currentPage?: number

    get itemsPaginated(): UserWithTables {
        if (!!this.perPage && !!this.currentPage && (this.perPage < this.userWithTables.length)) {
            const perPage = this.perPage * 2

            const start = (this.currentPage - 1) * perPage
            const end = start + perPage
            const items = this.userWithTables.slice(start, end)

            /*if (items.length < perPage) {
                const stub = Array((perPage - items.length) / 2).fill(null)
                return [ ...items, ...stub]
            }*/

            return items
        }

        return this.userWithTables
    }
}
