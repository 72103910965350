
import { invitation } from "@/types/invitations"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({})
export default class TDInvitationLink extends Vue {
    @Prop() item!: invitation;

    static componentName = "TDInvitationLink"

    get link(): string {
        return this.$store.state.appConfig.siteRoot + 'invitations/' + this.item.invitationHash
            + '?utm_source=service&utm_medium=referals'
    }
}
