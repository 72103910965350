
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoServicesRule from "@/interfaces/AvitoServicesRule";

@Component
export default class TDAvitoServicesSchedule extends Vue {
    @Prop() item?: AvitoServicesRule

    static componentName = 'TDAvitoServicesSchedule'

    get days(): string {
        if (!this.item || !this.item.schedule) {
            return '';
        }

        const { schedule } = this.item;

        return schedule.days.map(day => {
            const daysMap = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
            return daysMap[day - 1];
        }).join(', ');
    }

    get hours(): string {
        if (!this.item || !this.item.schedule) {
            return '';
        }

        return this.item.schedule.hours.join(', ');
    }
}
