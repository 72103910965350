
import { Component, Vue, Prop } from "vue-property-decorator"
import ImagesTransformRule from "@/interfaces/ImagesTransformRule";

@Component
export default class TDImagesTransformIdsExclude extends Vue {
    @Prop() item!: ImagesTransformRule

    static componentName = 'TDImagesTransformIdsExclude'

    get formattedIdsExclude(): string {
        if (!this.item || typeof this.item.ids_exclude !== 'object') return '';

        let result = '';
        for (let key in this.item.ids_exclude) {
            if (this.item.ids_exclude.hasOwnProperty(key)) {
                result += this.item.ids_exclude[key] + ', ';
            }
        }

        result = result.slice(0, -2);

        return result;
    }
}
