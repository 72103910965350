
import UpdateComponent from "@/components/Auth/UpdateComponent.vue"
import Vue from "vue";
import Component from 'vue-class-component'

@Component({
    components: {
        UpdateComponent
    }
})
export default class UserUpdatePage extends Vue {
    async created(): Promise<void> {
        document.title = 'Обновление'

        if (this.$store.getters["Users/hasEmail"]) {
            await this.$store.dispatch('redirectToHome')
        }

        this.$store.state.msg = ''
    }


}
