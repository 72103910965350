
import axios from "axios"
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class UslugaPage extends Vue {
    data = ''

    async created(): Promise<void> {
        document.title = 'Информация по доставке товара/услуги'
        this.$store.state.msg = ''

        if (this.data !== '') {
            return
        }

        const res = await axios.get<string>('/data/usluga.html')
        this.data = res.data
    }
}
