
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDText extends Vue {
    @Prop() contentClass?: string
    @Prop() value?: string | number

    static componentName = 'TDText'

    default = 'd-flex align-items-center justify-content-center text-nowrap'

    get classString(): string {
        if (this.contentClass) {
          if (!this.value &&
              this.value !== 0 &&
              this.contentClass.indexOf('coin') >= 0) {
              return 'coin-stub d-flex align-items-center justify-content-end'
            }
            return this.contentClass
        }

        return this.default
    }
}
