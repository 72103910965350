
import Generator from "@/interfaces/Generator"
import axios, { AxiosResponse } from "axios";
import { BvModal, BvModalEvent } from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator"

@Component
export default class AdminPurchaseMaxAds extends Vue {
    $refs!: {
        form: HTMLFormElement
    }
    $bvModal!: BvModal

    loading = false

    generator = {} as Generator
    price = 0

    get showText(): boolean {
        return !this.loading
    }

    get text(): string {
        return 'Подарить клиенту сумму в <span class="text-my-primary">' + this.price +
            '</span> монет?'
    }

    resetModal(): void {
        this.generator = this.$store.getters['Generators/getSelectedGenerator']

        this.getPrice()
    }

    async getPrice(): Promise<void> {
        if (this.loading) {
            return
        }
        this.loading = true

        const { targetPlatform, maxAds, generatorGuid } = this.generator

        const url = await this.$store.dispatch('getFullUrl', 'prices');
        await axios.post<number>(
            url,
            {
                targetPlatform,
                maxAds,
                generatorGuid
            },
            { validateStatus: (): boolean => true }
        )
            .then((res) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.price = res.data
                        break
                    case 400:
                        this.$bvModal.hide('purchase_max_ads_modal')
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.loading = false)
    }

    hiddenModal(): void {
        this.$store.dispatch('Generators/resetSelected')
        this.price = 0
    }

    handleOk(bvModalEvt: BvModalEvent): void {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.purchase()
    }

    async purchase(): Promise<void> {
        if (this.loading) {
            return
        }
        this.loading = true;

        const generator = this.generator
        const { targetPlatform, maxAds, generatorGuid, subscribed } = generator
        const tableId = this.$store.getters['Generators/getSelectedTableId']

        const url = await this.$store.dispatch('getFullUrl', 'transactions/maxAds');
        await axios.post<number | false>(
            url,
            {
                targetPlatform,
                maxAds,
                generatorGuid,
                subscribed
            },
            { validateStatus: (): boolean => true }
        )
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        if (res.data !== false) {
                            this.$bvModal.msgBoxOk(
                                'Успешно выполнена смена тарифа.',
                                {
                                    centered: true
                                }
                            )
                            this.$store.dispatch('Generators/setSelected', {
                                generator,
                                originalMaxAds: maxAds,
                                tableId,
                                dateExpired: res.data
                            })
                            this.$store.commit('UPDATE_DATE_EXPIRED', { tableId, dateExpired: res.data })
                            this.$store.dispatch('Wallet/setBalance')
                        } else {
                            this.$bvModal.msgBoxOk(
                                'Что-то пошло не так',
                                {
                                    centered: true,
                                    bodyTextVariant: 'danger'
                                }
                            )
                        }
                        this.$bvModal.hide('admin_purchase_max_ads_modal')
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.loading = false)
    }
}
