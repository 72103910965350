export default [
    {
        name: 'Колонка',
        code: 'colName',
    },
    {
        name: 'Тег',
        code: 'tag',
    },
    {
        name: 'Внутренний тег',
        code: 'innerTag',
    },
    {
        name: 'Примечание',
        code: 'comment',
    },
    {
        name: '',
        code: 'save_tag',
    },
]
