
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class TableCreateGapiAuthPage extends Vue {
    created(): void {
        document.title = 'Авторизация Google для создания таблицы'

        const code = this.$route.query.code;

        if (code) {
            if (window.opener) {
                window.opener.postMessage({ authCode: code });
            }

            window.close();
        }
    }
}
