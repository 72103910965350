import Generator from "@/interfaces/Generator"
import RootState from "@/interfaces/RootState"
import GeneratorState from "@/interfaces/GeneratorState"
import { maxAdsSelectOptions, selectedGenerator } from "@/types/generators"
import { ErrorResponse } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import { ActionContext, Module } from "vuex"

const Generators: Module<GeneratorState, RootState> = {
    namespaced: true,
    state: {
        maxAdsSelectOptions: undefined,
        selected: {} as selectedGenerator
    },
    mutations: {
        SET_MAX_ADS_SELECT_OPTIONS(state: GeneratorState, maxAdsSelectOptions: maxAdsSelectOptions): void {
            state.maxAdsSelectOptions = maxAdsSelectOptions
        },

        SET_SELECTED(state: GeneratorState, selected: selectedGenerator): void {
            state.selected = selected
        }
    },
    actions: {
        async setMaxAdsSelectOptions(
            { state, commit, dispatch, rootGetters }: ActionContext<GeneratorState, RootState>
        ): Promise<void> {
            if (state.maxAdsSelectOptions) {
                return
            }

            const url = await dispatch('getFullUrl', 'config/maxAds', { root: true });
            await axios.get<maxAdsSelectOptions | ErrorResponse>(
                url + rootGetters.hashQuery,
                { validateStatus: (): boolean => true }
            )
                .then((res: AxiosResponse) => {
                        const statusCode = res.request.status
                        switch (statusCode) {
                            case 200:
                                commit('SET_MAX_ADS_SELECT_OPTIONS', res.data)
                                break
                            default:
                                commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                        }
                    }
                )
                .catch(err => console.error(err))
        },

        async setSelected(
            { commit }: ActionContext<GeneratorState, RootState>,
            { generator, originalMaxAds, tableId, dateExpired }: selectedGenerator
        ): Promise<void> {
            commit('SET_SELECTED', {
                generator,
                originalMaxAds,
                tableId,
                dateExpired
            })
        },

        resetSelected({ state }: ActionContext<GeneratorState, RootState>): void {
            state.selected.generator.maxAds = state.selected.originalMaxAds
            state.selected = {} as selectedGenerator
        },

        updateSubscribedMaxAdsSelected({ state }: ActionContext<GeneratorState, RootState>, subscribedMaxAds: number): void {
            state.selected.generator.subscribedMaxAds = subscribedMaxAds
        },

        updateSubscribedSelected({ state }: ActionContext<GeneratorState, RootState>, subscribed: boolean): void {
            state.selected.generator.subscribed = subscribed
        },
    },
    getters: {
        getMaxAdsSelectOptions(state: GeneratorState): maxAdsSelectOptions | undefined {
            return state.maxAdsSelectOptions
        },

        getSelectedGenerator(state: GeneratorState): Generator {
            return state.selected.generator
        },

        getSelectedTableId(state: GeneratorState): number {
            return state.selected.tableId
        },

        getSelectedOriginalMaxAds(state: GeneratorState): number {
            return state.selected.originalMaxAds
        },

        getSelectedSubscribed(state: GeneratorState): boolean {
            return state.selected.generator.subscribed
        },

        getSelectedDateExpired(state: GeneratorState): number {
            return state.selected.dateExpired
        },

        hasSelected(state: GeneratorState): boolean {
            return !!state.selected.generator
        }
    }
}

export default Generators
