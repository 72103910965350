
/* eslint-disable */
import TableDetailComponent from "@/components/TableDetail/TableDetailComponent.vue"
import TableItem from "@/interfaces/TableItem"
import { TableDetail } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
    components: { TableDetailComponent }
})
export default class TDGoogleSheetLink extends Vue {
    @Prop() item!: TableItem;

    static componentName = "TDGoogleSheetLink"

    public showDetail = false
    public tableDetail: TableDetail | null = null;
    private wait = false

    private baseXmlUrl = 'http://xml.adviz.pro/';

    get isMarketplace(): boolean {
        return this.item.type === 'marketplaces'
    }

    get iconClass(): string {
        return this.opened ? 'chevron-up' : 'chevron-down'
    }

    get opened(): boolean {
        return this.showDetail && (this.tableDetail !== null)
    }

    public async toggleTokensDetail(): Promise<void> {
        this.showDetail = !this.showDetail

        if (this.tableDetail === null) {
            if (this.wait) {
                return
            }
            this.wait = true;

            this.tableDetail = await this.getTableDetail(this.item.tableGuid)
            this.wait = false
        }
    }

    private async getTableDetail(tableGuid: string): Promise<TableDetail|null> {
        let tableDetail = null

        const url = await this.$store.dispatch('getFullUrl', 'tables/' + tableGuid);
        await axios.get(
            url + this.$store.getters.hashQuery,
            { validateStatus: (): boolean => true }
        )
            .then((res) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    tableDetail = res.data
                }

                this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
            })
            .catch(err => console.error(err))

        return tableDetail
    }
}
