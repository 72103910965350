
import { Component, Vue, Prop } from "vue-property-decorator"
import axios, { AxiosResponse } from "axios";
import AvitoServicesRule from "@/interfaces/AvitoServicesRule";

@Component
export default class TDAvitoServicesSave extends Vue {
    @Prop() item!: AvitoServicesRule;

    static componentName = "TDAvitoServicesSave"

    wait = false;

    async editRule(): Promise<void> {
        this.$store.commit("AvitoServices/SET_SELECTED_ROW_INDEX", this.item.id);
    }

    async deleteRule(id: number): Promise<void> {
        if (this.wait) {
            return;
        }
        this.wait = true;

        const url = await this.$store.dispatch('getFullUrl', 'avito-services/' + id);
        await axios.delete(
            url + this.$store.getters.hashQuery)
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    this.$store.dispatch('AvitoServices/setAvitoServicesRules');
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.wait = false)
    }
}
