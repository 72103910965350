
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoRepubRule from "@/interfaces/AvitoRepubRule";

@Component
export default class TDAvitoRepubTitle extends Vue {
    @Prop() item?: AvitoRepubRule

    static componentName = 'TDAvitoRepubTitle'

    get title(): string {
        let title = this.item ? this.item.title : '';
        const maxLength = 100;

        if (title.length > maxLength) {
            const commaIndex = title.lastIndexOf(' ', maxLength);
            if (commaIndex !== -1) {
                title = title.substring(0, commaIndex) + ' ...';
            } else {
                title = title.substring(0, maxLength) + ' ...';
            }
        }

        return title;
    }
}
