
import { Component, Vue, Prop } from "vue-property-decorator"
import ImagesTransformRule from "@/interfaces/ImagesTransformRule";

@Component
export default class TDImagesTransformTitles extends Vue {
    @Prop() item!: ImagesTransformRule

    static componentName = 'TDImagesTransformTitles'

    get formattedTitles(): string {
        if (!this.item) return '';
        if (this.item.any_titles) return 'Все';
        if (!this.item.titles || this.item.titles.length === 0) return '';
        return this.item.titles.join(', ');
    }

    get formattedTitlesExclude(): string {
        if (!this.item) return '';
        if (!this.item.titles_exclude || this.item.titles_exclude.length === 0) return '';
        return this.item.titles_exclude.join(', ');
    }
}
