
import User from "@/interfaces/User"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDTokenShort extends Vue {
    @Prop() index!: number;
    @Prop() item!: User
    @Prop() contentClass?: string

    static componentName = "TDTokenShort"

    get tokenShort(): string | number {
        if (!this.item.token) {
            return ''
        }

        return this.item.token.substr(-5, 5)
    }

    get classString(): string {
        return this.contentClass ? this.contentClass : 'd-flex align-items-center justify-content-center'
    }
}
