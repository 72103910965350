
import { Component, Vue, Prop } from "vue-property-decorator"
import ImagesTransformRule from "@/interfaces/ImagesTransformRule";

@Component
export default class TDImagesTransformEnabled extends Vue {
    @Prop() item!: ImagesTransformRule

    static componentName = 'TDImagesTransformEnabled'
}
