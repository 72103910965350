export default [
    {
        name: 'Заголовок',
        code: 'avitoServicesTitle',
    },
    {
        name: 'Город',
        code: 'avitoServicesCity',
    },
    {
        name: 'Критерий',
        code: 'avitoServicesRangeParam',
    },
    {
        name: 'Услуги',
        code: 'avitoServicesServices',
    },
    {
        name: 'Дата начала',
        code: 'avitoServicesDateStart',
    },
    {
        name: 'Дата окончания',
        code: 'avitoServicesDateExpired',
    },
    {
        name: 'Бюджет',
        code: 'avitoServicesBudget',
    },
    {
        name: 'Расписание',
        code: 'avitoServicesSchedule',
    },
    {
        name: '',
        code: 'avitoServicesSave',
    },
]
