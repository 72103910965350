
import TableItem from "@/interfaces/TableItem"
import { Component, Vue, Prop } from "vue-property-decorator"
import Generator from "@/interfaces/Generator"

@Component
export default class TDAdminGenerators extends Vue {
    @Prop() item!: TableItem;

    static componentName = "TDAdminGenerators"

    get generators(): Generator[] {
        return this.item.generators ? this.item.generators : []
    }
}
