import RootState from "@/interfaces/RootState"
import StatisticsState from "@/interfaces/StatisticsState"
import { activity, statistics } from "@/types/statistics"
import axios, { AxiosResponse } from "axios"
import { ActionContext, Module } from "vuex"

const Statistics: Module<StatisticsState, RootState> = {
    namespaced: true,
    state: {
        statistics: {} as statistics,
    },
    mutations: {
        SET_STATISTICS(state: StatisticsState, statistics: statistics): void {
            state.statistics = statistics
        },
    },
    actions: {
        async setStatistics(
            { commit, dispatch }: ActionContext<StatisticsState, RootState>
        ): Promise<void> {
            const url = await dispatch('getFullUrl', 'statistics_v2', { root: true });
            await axios.get<statistics>(
                url,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse<statistics>) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 200:
                            commit('SET_STATISTICS', res.data)
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        },
    },
    getters: {
        getStatistics(state: StatisticsState): statistics {
            return state.statistics
        },
        hasStatistics(state: StatisticsState): boolean {
            return 'general' in state.statistics
        },
        getStatisticsActivities(state: StatisticsState): activity[] {
            return state.statistics.activities
        },
    }
}

export default Statistics
