
import TableColumn from "@/interfaces/TableColumn"
import {Vue, Prop, Component} from "vue-property-decorator";
import TableItemComponent from "@/components/TableList/TableItemComponent.vue"
import AvitoServicesRule from "@/interfaces/AvitoServicesRule";

@Component({
    components: {
        TableItemComponent,
    },
})
export default class AvitoServicesRowComponent extends Vue {
    @Prop() index!: number;
    @Prop() row?: AvitoServicesRule;
    @Prop() columns?: TableColumn[];
}
