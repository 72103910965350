
import TableItem from "@/interfaces/TableItem"
import User from "@/interfaces/User"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDIndex extends Vue {
    @Prop() index!: number;
    @Prop() item!: User | TableItem

    static componentName = "TDIndex"

    get number(): string | number {
        return this.index + 1
    }
}
