
import axios, { AxiosResponse } from "axios"
import { Component, Vue } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class SmsTemplatesPage extends Vue {
    template1dLeft: string = '';
    template1dExpired: string = '';
    templateNewRegistration: string = '';
    template1dTestLeft: string = '';

    saveDataTitle: string = ''
    saveDataMessage: string = ''

    characterCountMap: { [key: string]: number } = {};


    updateCharacterCount(key: string): void {
        this.characterCountMap[key] = this[key].length;
    }


    characterCount(key: string): number {
        return this.characterCountMap[key] || 0;
    }


    blockCount(key: string): number {
        const characterCount = this.characterCount(key);
        return Math.ceil(characterCount / 70);
    }


    saveButtonDisabled(): boolean {
        return this.template1dLeft.length === 0 || this.template1dExpired.length === 0
            || this.templateNewRegistration.length === 0 || this.template1dTestLeft.length === 0;
    }


    async created(): Promise<void> {
        document.title = 'Шаблоны уведомлений'

        this.$store.state.msg = ''
        this.$store.commit('SET_LOADING', true)
        Promise.all([
            this.getTemplatesData()
        ])
            .finally(() => this.$store.commit('SET_LOADING', false))
    }


    private async getTemplatesData(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'sms/templates');
        await axios.get(
            url + this.$store.getters.hashQuery,
            { validateStatus: (): boolean => true }
        )
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    if (res.data) {
                        if (res.data['1dLeft'] !== undefined) {
                            this.template1dLeft = res.data['1dLeft'];
                            this.updateCharacterCount('template1dLeft');
                        }

                        if (res.data['1dExpired'] !== undefined) {
                            this.template1dExpired = res.data['1dExpired'];
                            this.updateCharacterCount('template1dExpired');
                        }


                        if (res.data['newRegistration'] !== undefined) {
                            this.templateNewRegistration = res.data['newRegistration'];
                            this.updateCharacterCount('templateNewRegistration');
                        }


                        if (res.data['1dTestLeft'] !== undefined) {
                            this.template1dTestLeft = res.data['1dTestLeft'];
                            this.updateCharacterCount('template1dTestLeft');
                        }
                    }
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }


    private async saveData(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'sms/templates');
        await axios.post(
            url + this.$store.getters.hashQuery,
            {
                '1dLeft': this.template1dLeft,
                '1dExpired': this.template1dExpired,
                'newRegistration': this.templateNewRegistration,
                '1dTestLeft': this.template1dTestLeft,
            }
        )
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    this.saveDataTitle = 'Сохранено';
                    this.saveDataMessage = 'Шаблоны успешно сохранены';
                    this.$bvModal.show('saveDataModal');
                } else {
                    this.saveDataTitle = 'Ошибка';
                    this.saveDataMessage = 'Не удалось сохранить шаблоны. Попробуйте позже';
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }
}
