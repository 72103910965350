
import PasswordResetComponent from "@/components/Auth/PasswordResetComponent.vue"
import Vue from "vue";
import Component from 'vue-class-component'

@Component({
    components: {
        PasswordResetComponent
    }
})
export default class PasswordResetPage extends Vue {
    async created(): Promise<void> {
        document.title = 'Изменение пароля'

        this.$store.state.msg = ''
    }
}
