
import { Vue, Component } from "vue-property-decorator";

@Component
export default class ProfitPartnersWidget extends Vue {
    get hasPartnersProfit(): boolean {
        return this.partnersProfit > 0
    }

    get partnersProfit(): number | string {
        return this.$store.getters['Referrals/getPartnersProfit']
    }

    created(): void {
        this.$store.dispatch('Referrals/setPartnerProfits')
    }
}
