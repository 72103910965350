
import TableColumn from "@/interfaces/TableColumn"
import {Vue, Prop, Component} from "vue-property-decorator";
import TableItemComponent from "@/components/TableList/TableItemComponent.vue"
import AvitoRepubHistory_v2 from "@/interfaces/AvitoRepubHistory_v2";

@Component({
    components: {
        TableItemComponent,
    },
})
export default class AvitoRepubHistoryRowComponent_v2 extends Vue {
    @Prop() index!: number;
    @Prop() row?: AvitoRepubHistory_v2;
    @Prop() columns?: TableColumn[];
}
