export default [
    {
        name: '',
        code: 'title',
        class: 'd-flex align-items-center ml-2 text-nowrap',
    },
    {
        name: 'Пользователей',
        code: 'count',
        class: 'd-flex align-items-center justify-content-center',
    },
    {
        name: 'Ср. активность (мес.)',
        code: 'month_avg',
        class: 'd-flex align-items-center justify-content-center',
    },
]
