
import { referralStatistics } from "@/types/referrals"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDReferrals extends Vue {
    @Prop() item!: referralStatistics;

    static componentName = 'TDReferrals'

    get referrals(): string {
        const { referralsCount, activeReferralsCount } = this.item
        return referralsCount + ' / ' + activeReferralsCount
    }
}
