import RootState from "@/interfaces/RootState"
import AvitoRepubState_v2 from "@/interfaces/AvitoRepubState_v2"
import AvitoRepubRule_v2 from "@/interfaces/AvitoRepubRule_v2"
import axios, { AxiosResponse } from "axios"
import { Module } from "vuex"

const AvitoRepubRules_v2: Module<AvitoRepubState_v2, RootState> = {
    namespaced: true,
    state: {
        avitoRepubRules: [] as AvitoRepubRule_v2[],
        selectedTableId: null,
        selectedRowIndex: null,
    },
    mutations: {
        SET_AVITO_REPUB_RULES(state: AvitoRepubState_v2, avitoRepubRules: AvitoRepubRule_v2[]): void {
            state.avitoRepubRules = avitoRepubRules
        },
        SET_SELECTED_ROW_INDEX(state, rowIndex) {
            state.selectedRowIndex = rowIndex;
        },
        PUSH_NEW_RULE(state: AvitoRepubState_v2, newRule: AvitoRepubRule_v2): void {
            state.avitoRepubRules.push(newRule)
        },
        SET_SELECTED_TABLE_ID(state: AvitoRepubState_v2, tableId: number | null): void {
            state.selectedTableId = tableId;
        },
    },
    actions: {
        async setAvitoRepubRules({ commit, dispatch, rootState, rootGetters, state }): Promise<void> {
            const url = await dispatch('getFullUrl', 'avito-repub_v2', { root: true });
            await axios.get<AvitoRepubRule_v2[]>(
                url + '/' + state.selectedTableId + rootGetters.hashQuery,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse<AvitoRepubRule_v2[]>) => {
                    const statusCode = res.request.status;
                    switch (statusCode) {
                        case 200:
                            commit('SET_AVITO_REPUB_RULES', res.data);
                            rootState.hashValidated = true;
                            break;
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true });
                    }
                })
                .catch(err => console.error(err));
        },
    },
    getters: {
        getAvitoRepubRules(state: AvitoRepubState_v2): AvitoRepubRule_v2[] {
            return state.avitoRepubRules
        },
        getAvitoRepubRule: (state) => (id: number): AvitoRepubRule_v2 | undefined => {
            return state.avitoRepubRules.find((rule) => rule.id === id);
        },
        getSelectedRowIndex(state: AvitoRepubState_v2): number | null {
            return state.selectedRowIndex
        },
        getSelectedTableId(state: AvitoRepubState_v2): number | null {
            return state.selectedTableId
        },
    }
}

export default AvitoRepubRules_v2
