import { render, staticRenderFns } from "./TDAvitoRepubActions.vue?vue&type=template&id=628282fb&scoped=true"
import script from "./TDAvitoRepubActions.vue?vue&type=script&lang=ts"
export * from "./TDAvitoRepubActions.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "628282fb",
  null
  
)

export default component.exports