
import ButtonShowTables from "@/components/Buttons/ButtonShowTables.vue"
import AdminPurchaseMaxAds from "@/components/Modal/AdminPurchaseMaxAds.vue"
import User from "@/interfaces/User"
import UserTables from "@/interfaces/UserTables"
import Vue from "vue";
import Component from 'vue-class-component'
import UsersTableListComponent from "@/components/TableList/UsersTableListComponent.vue";
import TableColumn from "@/interfaces/TableColumn";
import axios, { AxiosResponse } from "axios";
import TableItem from "@/interfaces/TableItem";
import moment from "moment";
// eslint-disable-next-line
import {UsersActionContext} from "@/types/types";

@Component({
    components: {
        AdminPurchaseMaxAds,
        ButtonShowTables,
        UsersTableListComponent
    }
})
export default class ArchivedUsersPage extends Vue {
    columns: TableColumn[] = []
    currentPage = 1
    perPage = 10
    perPageVariants = [
        { active: true, perPage: 10 },
        { active: false, perPage: 30 },
        { active: false, perPage: 50 },
        { active: false, perPage: 100 }
    ]
    searchedText = ''
    loading = false
    timeout?: number

    get icon(): string {
        return this.loading ? 'arrow-clockwise' : 'search'
    }

    get animation(): string {
        return this.loading ? 'spin' : ''
    }

    get rows(): number {
        return this.$store.state.UwT.userWithTables.length / 2
    }

    search(): void {
        clearTimeout(this.timeout)

        this.timeout = setTimeout(() => {
            setTimeout(() => this.loading = true)

            setTimeout(() => {
                if (!this.searchedText.trim()) {
                    this.$store.commit('UwT/SET_USERS_WITH_TABLES', this.$store.state.UwT.userWithTablesNoFlat.flat())
                    return;
                }

                const searchedText = this.searchedText.trim().toLowerCase()

                const userWithTables = this.$store.state.UwT.userWithTablesNoFlat
                    .filter(([user, userTables]: [user: User, userTables: UserTables]): boolean => {
                        if (!user || !userTables) {
                            return false
                        }

                        const { userId, name, phoneNumber, socialNetworkUrl, notes, email } = user
                        if (
                            (!!userId && String(userId).indexOf(searchedText) !== -1) ||
                            (!!email && email.toLowerCase().indexOf(searchedText) !== -1) ||
                            (!!notes && notes.toLowerCase().indexOf(searchedText) !== -1) ||
                            (!!name && name.toLowerCase().indexOf(searchedText) !== -1) ||
                            (!!phoneNumber && phoneNumber.toLowerCase().indexOf(searchedText) !== -1) ||
                            (!!socialNetworkUrl && socialNetworkUrl.toLowerCase().indexOf(searchedText) !== -1)
                        ) {
                            return true
                        }

                        const tables = userTables.tables.filter((table: TableItem): boolean => {
                            const { googleSheetId, tableNotes, dateExpired, generators } = table;

                            moment.locale('ru');
                            const dateExpiredFormat = moment.unix(dateExpired).format('DD MMM YYYY');

                            const generatorMatches = generators.some((generator) => {
                                return generator.generatorGuid.toLowerCase().indexOf(searchedText) !== -1;
                            });

                            return (
                                generatorMatches ||
                                (!!googleSheetId && googleSheetId.toLowerCase().indexOf(searchedText) !== -1) ||
                                (!!tableNotes && tableNotes.toLowerCase().indexOf(searchedText) !== -1) ||
                                (!!dateExpiredFormat && dateExpiredFormat.toLowerCase().indexOf(searchedText) !== -1)
                            );
                        });

                        return tables.length > 0
                    })
                    .flat()

                this.$store.commit('UwT/SET_USERS_WITH_TABLES', userWithTables)

                this.loading = false
            })
        }, 1000)
    }

    async created(): Promise<void> {
        document.title = 'Архивированные пользователи'

        this.$store.dispatch('Users/redirectIfNotAdminOrManager')
            .then(async (res: boolean) => {
                if (res) {
                    return
                }

                this.$store.state.msg = ''
                this.$store.commit('SET_LOADING', true)
                await Promise.all([
                    this.$store.dispatch('Generators/setMaxAdsSelectOptions'),
                    this.getTables(),
                    this.$store.dispatch('Users/setUsers'),
                    this.getMarketplaces()
                ])
                await this.$store.dispatch('UwT/setUserWithTablesArchived')
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.$store.commit('SET_LOADING', false)
            })
    }

    private async getTables(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'tables');
        await axios.get<TableItem[]>(
            url + this.$store.getters.hashQuery,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<TableItem[]>) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    this.setTables(res);
                    this.$store.state.hashValidated = true;
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }

    private setTables(res: AxiosResponse<TableItem[]>): void {
        this.$store.commit('SET_TABLES', res.data)
        this.$store.commit('SORT_TABLES_BY', { sort: 'dateExpired', desc: true })
    }

    private async getMarketplaces(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'marketplaces');
        await axios.get<TableItem[]>(
            url + this.$store.getters.hashQuery,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<TableItem[]>) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    this.setMarketplaces(res);
                    this.$store.state.hashValidated = true;
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }

    private setMarketplaces(res: AxiosResponse<TableItem[]>): void {
        this.$store.commit('SET_MARKETPLACES', res.data)
        this.$store.commit('SORT_MARKETPLACES_BY', { sort: 'dateExpired', desc: true })
    }

    changePaginate(variant: { active: boolean, perPage: number }): void {
        this.perPageVariants.forEach((variant) => variant.active = false)
        this.perPage = variant.perPage
        variant.active = true
    }
}
