export default [
    {
        name: 'Почта',
        code: 'email',
    },
    {
        name: 'Имя',
        code: 'name',
    },
    {
        name: 'Номер',
        code: 'phoneNumber',
    },
    {
        name: 'Связь',
        code: 'socialNetworkUrl',
    },
    {
        name: 'Всего заработано',
        code: 'totalProfit',
        class: 'coin d-flex align-items-center justify-content-end',
    },
    {
        name: 'Баланс',
        code: 'balanceTransaction',
    },
    {
        name: 'Рефералы / Активные',
        code: 'referralsAndActiveReferrals',
    },
    {
        name: 'Статус',
        code: 'referralIncome',
    },
]
