
import { Vue, Component } from "vue-property-decorator";
import AvitoRepubTableListComponent from "@/components/TableList/AvitoRepubTableListComponent.vue";
import TableColumn from "@/interfaces/TableColumn";
import TDAddRepubRule from "@/components/TableList/TableData/TDAddRepubRule.vue";
import AvitoRepubRule from "@/interfaces/AvitoRepubRule";
import axios, { AxiosResponse } from "axios";
import TableItem from "@/interfaces/TableItem";

@Component({
    components: {
        AvitoRepubTableListComponent,
        TDAddRepubRule
    }
})
export default class AvitoRepubPage extends Vue {
    columns: TableColumn[] = [];
    currentPage = 1;
    perPage = 10;
    perPageVariants = [
        { active: true, perPage: 10 },
        { active: false, perPage: 30 },
        { active: false, perPage: 50 },
        { active: false, perPage: 100 }
    ];
    searchedText = "";
    filterText = "";
    loading = false;
    timeout?: number;
    tableOptions: { tableId: string, googleSheetId: string }[] = [];

    get icon(): string {
        return this.loading ? "arrow-clockwise" : "search";
    }

    get animation(): string {
        return this.loading ? "spin" : "";
    }

    get rows(): number {
        return this.filteredRules.length;
    }

    get rules(): AvitoRepubRule[] {
        return this.$store.getters["AvitoRepub/getAvitoRepubRules"];
    }

    get selectedTableId(): number | null {
        return this.$store.getters["AvitoRepub/getSelectedTableId"];
    }

    set selectedTableId(tableId: number | null) {
        this.$store.commit("AvitoRepub/SET_SELECTED_TABLE_ID", tableId);
    }

    get filteredRules(): AvitoRepubRule[] {
        let filteredRules = this.rules;

        if (this.filterText !== "") {
            filteredRules = filteredRules.filter((item): boolean => {
                if (!item) {
                    return false;
                }

                const { city, title } = item;

                return (
                    (!!city && city.toLowerCase().indexOf(this.filterText) !== -1) ||
                    (!!title && title.toLowerCase().indexOf(this.filterText) !== -1)
                );
            });
        }

        if (this.selectedTableId !== null) {
            filteredRules = filteredRules.filter(
                (item) => item.table_id === this.selectedTableId
            );
        }

        return filteredRules;
    }


    search(): void {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            setTimeout(() => (this.loading = true));

            setTimeout(() => {
                this.filterText = this.searchedText.trim().toLowerCase();

                this.loading = false;
            });
        }, 1000);
    }


    async created(): Promise<void> {
        document.title = "Правила перепубликации объявлений Авито";

        if (!this.$store.getters["Users/hasUser"]) {
            return;
        }

        this.$store.state.alert = "";
        this.$store.state.msg = "";

        this.$store.commit("SET_LOADING", true);
        await Promise.all([
            this.$store.dispatch("AvitoRepub/setAvitoRepubRules"),
            this.getTables()
        ]).finally(() => this.$store.commit("SET_LOADING", false));
    }


    private async getTables(): Promise<void> {
        const url = await this.$store.dispatch("getFullUrl", "tables");
        await axios
            .get<TableItem[]>(url + this.$store.getters.hashQuery, {
                validateStatus: (): boolean => true
            })
            .then((res: AxiosResponse<TableItem[]>) => {
                const statusCode = res.request.status;
                if (statusCode === 200) {
                    this.setTableOptions(res);
                    this.$store.state.hashValidated = true;
                } else {
                    this.$store.commit("SET_MSG_FROM_STATUS_CODE", statusCode);
                }
            })
            .catch((err) => console.error(err));
    }


    private setTableOptions(res: AxiosResponse<TableItem[]>): void {
        const uniqueOptions: { [key: string]: string } = {};

        res.data.forEach((item) => {
            const avitoGenerator = item.generators.find(generator => generator.targetPlatform === 'Avito');

            if (avitoGenerator && avitoGenerator.maxAds > 5000 && item.googleSheetId && item.tableId) {
                const key = item.tableId;
                const value = item.googleSheetId;
                uniqueOptions[key] = value;
            }
        });

        this.tableOptions = Object.keys(uniqueOptions).map((key) => ({
            tableId: key,
            googleSheetId: uniqueOptions[key]
        }));

        const selectedTableId = this.$store.getters["AvitoRepub/getSelectedTableId"];
        if (selectedTableId === null && this.tableOptions.length > 0) {
            this.changeTable(this.tableOptions[0].tableId);
        }
    }


    changeTable(tableId: string): void {
        this.selectedTableId = parseInt(tableId);
    }


    addNewRule(): void {
        this.currentPage = Math.ceil(this.rows / this.perPage);
    }


    changePaginate(variant: { active: boolean; perPage: number }): void {
        this.perPageVariants.forEach((variant) => (variant.active = false));
        this.perPage = variant.perPage;
        variant.active = true;
    }
}
