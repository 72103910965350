
import TableItem from "@/interfaces/TableItem"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDPremiumSwitch extends Vue {
    @Prop() item!: TableItem;

    static componentName = "TDPremiumSwitch"
}
