
import { Component, Vue, Prop } from "vue-property-decorator"
import MessengersItem from "@/interfaces/MessengersItem";

@Component
export default class TDMessengerToken extends Vue {
    @Prop() item!: MessengersItem;

    static componentName = "TDMessengerToken"

    get copy(): string {
        return String(this.item.telegram_token)
    }
}
