
import { Component, Vue } from "vue-property-decorator"

@Component
export default class ButtonCreateMonitoringTask extends Vue {
    get enabledNewTask(): boolean {
        return (this.$store.getters['AvitoMonitoring/getEditedTaskId'] === null);
    }

    createTask(): void {
        this.$store.dispatch('AvitoMonitoring/createTask')
    }
}
