
import { ErrorResponse } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BannerLayout extends Vue {
    newEmail = '';
    responseText = '';

    resetModal(): void {
        this.newEmail = ''
    }

    async resendVerify(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'email/resendVerify');
        await axios.post<void | ErrorResponse>(
            url,
            null,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void | ErrorResponse>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.$store.state.verifySent = true
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }

    async emailChange(): Promise<void> {
        if (this.newEmail === '') {
            return;
        }

        const url = await this.$store.dispatch('getFullUrl', 'email/change');

        await axios.post<void | ErrorResponse>(
            url,
            { email: this.newEmail },
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void | ErrorResponse>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 201:
                        this.responseText = 'Адрес E-mail изменен на ' + this.newEmail
                            + '. На этот адрес отправлена ссылка с подтверждением.';
                        break
                    default:
                        this.responseText = 'Не удалось изменить адрес E-mail, проверьте введенные данные и повторите попытку.'
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }

                this.$bvModal.show('modal-change-email-response')
            })
            .catch(err => console.error(err))
    }

    get showBanner(): boolean {
        return !this.$store.state.verifySent &&
            this.$store.getters['Users/hasUser'] &&
            this.$store.getters['Users/hasUserInfo'] &&
            this.$store.getters['Users/hasEmail'] &&
            !this.$store.getters['Users/hasVerifyEmail']
    }
}
