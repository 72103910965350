
import { Component, Vue, Prop } from "vue-property-decorator"
import MessengersItem from "@/interfaces/MessengersItem";
import axios, {AxiosResponse} from "axios";

@Component
export default class TDMessengerDelete extends Vue {
    @Prop() item!: MessengersItem;

    static componentName = "TDMessengerDelete"

    wait = false

    async onSubmit(): Promise<void> {
        if (this.wait) {
            return
        }
        this.wait = true;

        this.$store.commit('SET_LOADING', true)
        Promise.all([
            this.refreshToken()
        ])
            .finally(() => this.$store.commit('SET_LOADING', false))

        this.wait = false;
    }

    private async refreshToken(): Promise<void> {
        var url = await this.$store.dispatch('getFullUrl', 'messengers/' + this.item.id);

        await axios.delete(url)
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                if (statusCode === 201) {
                    this.$store.commit('UPDATE_MESSENGER_TOKEN', res.data)
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))

        url = await this.$store.dispatch('getFullUrl', 'messengers');

        await axios.get<MessengersItem[]>(url)
            .then((res: AxiosResponse<MessengersItem[]>) => {
                const statusCode = res.request.status
                if (statusCode === 201) {
                    this.$store.commit('SET_MESSENGERS', res.data)
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }
}
