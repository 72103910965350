
import TableColumn from "@/interfaces/TableColumn"
import {Vue, Prop, Component} from "vue-property-decorator";
import TableItemComponent from "@/components/TableList/TableItemComponent.vue"
import AvitoRepubRule_v2 from "@/interfaces/AvitoRepubRule_v2";

@Component({
    components: {
        TableItemComponent,
    },
})
export default class AvitoRepubRowComponent_v2 extends Vue {
    @Prop() index!: number;
    @Prop() row?: AvitoRepubRule_v2;
    @Prop() columns?: TableColumn[];
}
