
import AdminPurchaseMaxAds from "@/components/Modal/AdminPurchaseMaxAds.vue"
import PurchaseMaxAds from "@/components/Modal/PurchaseMaxAds.vue"
import TDAddMarketplace from "@/components/TableList/TableData/TDAddMarketplace.vue"
import TableListComponent from "@/components/TableList/TableListComponent.vue";
import TableItem from "@/interfaces/TableItem";
import axios, { AxiosResponse } from "axios";
import Vue from "vue";
import Component from 'vue-class-component'

@Component({
    components: {
        PurchaseMaxAds,
        AdminPurchaseMaxAds,
        TableListComponent,
        TDAddMarketplace
    }
})
export default class MarketplacesPage extends Vue {
    currentPage = 1
    perPage = 10
    perPageVariants = [
        { active: true, perPage: 10 },
        { active: false, perPage: 30 },
        { active: false, perPage: 50 },
        { active: false, perPage: 100 }
    ]

    get rows(): number {
        return this.$store.state.marketplaces.length
    }

    created(): void {
        document.title = 'Маркетплейсы'

        if (!this.$store.getters['Users/hasUser']) {
            return
        }

        this.$store.state.alert = ''

        this.$store.state.msg = ''
        this.$store.commit('SET_LOADING', true)
        Promise.all([
            this.$store.dispatch('Generators/setMaxAdsSelectOptions'),
            this.getTables()
        ])
            .finally(() => this.$store.commit('SET_LOADING', false))
    }

    private async getTables(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'marketplaces');
        await axios.get<TableItem[]>(
            url + this.$store.getters.hashQuery,
            { validateStatus: (): boolean => true }
        )
            .then((res: AxiosResponse<TableItem[]>) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    this.setTables(res);
                    this.$store.state.hashValidated = true;
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }

    private setTables(res: AxiosResponse<TableItem[]>): void {
        this.$store.commit('SET_MARKETPLACES', res.data)
        this.$store.commit('SORT_MARKETPLACES_BY', { sort: 'dateExpired', desc: true })
    }

    changePaginate(variant: { active: boolean, perPage: number }): void {
        this.perPageVariants.forEach((variant) => variant.active = false)
        this.perPage = variant.perPage
        variant.active = true
    }
}
