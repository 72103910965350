
import axios, { AxiosResponse } from "axios";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class TDImportTableData extends Vue {
    @Prop() tableId?: number;

    static componentName = "TDImportTableData";

    inProgress = false;

    sourceType: string = 'link';
    importLink: string = '';
    importFile: File | null = null;

    formModalOpened: boolean = false;

    resultModalOpened = false;
    resultModalText = "";

    get sourceOptions() {
        return [
            { text: 'Ссылка', value: 'link' },
            { text: 'Файл', value: 'file' },
        ];
    }

    get icon(): string {
        return this.inProgress ? 'three-dots' : 'download';
    }

    get animation(): string {
        return this.inProgress ? 'cylon' : '';
    }

    get buttonText(): string {
        return this.inProgress ? 'Данные загружаются' : 'Импорт данных XML / Excel';
    }

    get canImport(): boolean {
        if (this.sourceType === 'link') {
            return !!this.importLink && this.isValidUrl(this.importLink);
        }
        return !!this.importFile;
    }

    isValidUrl(url: string): boolean {
        try {
            new URL(url);
            return true;
        } catch {
            return false;
        }
    }

    handleFileChange(event: Event) {
        const target = event.target as HTMLInputElement;
        this.importFile = target.files ? target.files[0] : null;
    }

    async importData() {
        if (this.inProgress) {
            return null;
        }
        this.inProgress = true;

        const formData = new FormData();
        if (this.sourceType === 'link') {
            formData.append('source', this.importLink);
        } else if (this.importFile) {
            formData.append('source', this.importFile);
        }
        formData.append('tableId', String(this.tableId));

        const url = await this.$store.dispatch('getFullUrl', 'tables/import-data');

        try {
            const response: AxiosResponse = await axios.post(url, formData);
            if (response.data.status == 'ok') {
                this.showResultModal('Импорт успешно завершен');
            } else {
                this.showResultModal(response.data.message);
            }
        } catch (error) {
            console.log(error);
            this.showResultModal('Не удалось загрузить данные');
        } finally {
            this.inProgress = false;
            this.formModalOpened = false;
        }
    }

    showFormModal() {
        this.importLink = '';
        this.importFile = null;
        this.formModalOpened = true;
    }

    showResultModal(message: string) {
        this.resultModalText = message;
        this.resultModalOpened = true;
    }
}
