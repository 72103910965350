
import User from "@/interfaces/User"
import Autopayment from "@/components/Finances/Autopayment.vue"
import axios, { AxiosResponse } from "axios"
import { BvModal, BvModalEvent } from "bootstrap-vue"
import { Vue, Component } from "vue-property-decorator";

@Component({
    components: {
        Autopayment
    }
})
export default class WalletWidget extends Vue {
    $refs!: {
        form: HTMLFormElement,
        tinkoffPay: HTMLFormElement,
    }
    $bvModal!: BvModal

    tinkoffCreated = false
    orderId = ''
    amount = 1;
    amountState: (boolean | null) = null;
    processing = false;

    get user(): User {
        return this.$store.getters['Users/getUser']
    }

    get title(): string {
        if (this.$store.getters['Users/userIsAdmin']) {
            return 'Всего выручки'
        }

        return 'Баланс'
    }

    get receipt(): string {
        return JSON.stringify({
            Email: this.user.email,
            Phone: this.user.phoneNumber,
            EmailCompany: 'company@adviz.pro',
            Taxation: 'usn_income',
            Items: [
                {
                    Name: 'Сервис автоматизации ADVIZ',
                    Price: this.amount * 100,
                    Quantity: 1,
                    Amount: this.amount * 100,
                    PaymentMethod: 'full_payment',
                    PaymentObject: 'payment',
                    Tax: 'none',
                }
            ],
        });
    }

    resetModal(): void {
        if (!this.tinkoffCreated){
            const script = document.createElement('script')
            script.setAttribute('src', "https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js")
            document.head.appendChild(script)
            this.tinkoffCreated = true
        }

        this.amount = 5000;
        this.amountState = null
    }

    handleOk(bvModalEvt: BvModalEvent): void {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
    }

    async handleSubmit(): Promise<void> {
        if (!this.checkFormValidity()) {
            return
        }

        await this.createOrder()
            .then(() => {
                if (this.orderId === '') {
                    this.$bvModal.msgBoxOk(
                        'Что-то пошло не так. Попробуйте еще раз.',
                        {
                          centered: true,
                          bodyTextVariant: 'danger'
                        }
                    )
                    this.$bvModal.hide('buyCurrency')
                    return
                }
                // @ts-expect-error tinkoff
                // eslint-disable-next-line no-undef
                pay(this.$refs.tinkoffPay)
                    .then(() => {
                        this.$nextTick(() => {
                          this.$bvModal.hide('buyCurrency')
                        })
                    })
            })
    }

    checkFormValidity(): boolean {
        const valid = this.$refs.form.checkValidity()
        this.amountState = valid
        return valid
    }

    async createOrder(): Promise<void> {
        if (this.processing) {
            return
        }
        this.processing = true

        const url = await this.$store.dispatch('getFullUrl', 'transactions/orders');
        await axios.post<string>(
            url,
            null,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.orderId = res.data
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.processing = false)
    }
}
