
import { Component, Vue, Prop } from "vue-property-decorator"
import TableItem from "@/interfaces/TableItem";
import axios from "axios";

@Component
export default class TDDeleteTable extends Vue {
    @Prop() item!: TableItem;

    static componentName = "TDDeleteTable"

    async deleteUser (): Promise<void> {
        const { tableId, tableGuid } = this.item
        const entity = this.item.type

        const url = await this.$store.dispatch('getFullUrl', entity + '/' + tableGuid);
        const userId = this.$props.item.userId;
        await axios.delete(url + this.$store.getters.hashQuery)
            .then(() => {
                this.$store.dispatch('UwT/filterTablesInUsersWithTables', { tableId, userId })
            })
            .catch(err => console.error(err))
    }
}
