
// @ts-nocheck
import {Component, Vue, Prop} from "vue-property-decorator"
import {Getter, Mutation} from "vuex-class";
import {maska} from 'maska'
import axios, {AxiosResponse} from "axios";
import { VueTagsInput, createTags } from "@johmun/vue-tags-input";
import ImagesTransformRule from "@/interfaces/ImagesTransformRule";

@Component({
    components: {
        VueTagsInput
    },
    directives: {
        maska
    }
})
export default class TDAddImagesTransformRule extends Vue {
    @Prop() userId?: number;

    @Getter("ImagesTransform/getSelectedRowIndex") selectedRowIndex!: number;

    @Getter("ImagesTransform/getImagesTransformRule") getImagesTransformRule!: (
        id: number
    ) => ImagesTransformRule | undefined;

    @Mutation("ImagesTransform/SET_SELECTED_ROW_INDEX") setSelectedRowIndex!: (
        rowIndex: number
    ) => void;

    static componentName = "TDAddImagesTransformRule"

    ruleData: ImagesTransformRule | null = null;
    showModal: boolean = false;
    wait: boolean = false;
    errorText: string = '';
    excludeVisible: boolean = false;

    title = '';
    city = '';
    title_exclude = '';
    city_exclude = '';
    id_exclude = '';
    sheet_exclude = '';

    editFormVisible: boolean = false;

    defaultRuleData: ImagesTransformRule = {
        id: 0,
        enabled: true,
        table_id: 0,
        googleSheetId: "",
        any_titles: true,
        any_cities: true,
        cities: [],
        titles: [],
        cities_exclude: [],
        titles_exclude: [],
        ids_exclude: [],
        sheets_exclude: [],
        randomize_images: 1,
    };

    showConfirmationModal: boolean = false;

    newRule(): void {
        this. excludeVisible = false;
        this.$store.commit("ImagesTransform/SET_SELECTED_ROW_INDEX", 0);
    }

    resetRuleData(): void {
        this.$store.commit("ImagesTransform/SET_SELECTED_ROW_INDEX", -1);
    }

    get modalTitle(): string {
        return this.ruleData && this.ruleData.id === 0 ? 'Новое правило' : 'Редактирование правила';
    }

    get saveButtonTitle(): string {
        return this.ruleData && this.ruleData.id === 0 ? 'Запустить правило' : 'Сохранить';
    }

    get disableSubmit(): boolean {
        return (!this.ruleData?.titles.length && !this.ruleData?.any_titles)
            || (!this.ruleData.cities.length && !this.ruleData?.any_cities)
            || this.wait;
    }


    get randomizeImagesLabel(): array {
        const labels = [
            {},
            { text: 'слабая', description: 'На фотографиях будут небольшие изменения', color: 'color-min' },
            { text: 'средняя', description: 'На фотографиях могут быть видимые изменения', color: 'color-medium' },
            { text: 'сильная', description: 'На фотографиях могут быть существенные изменения', color: 'color-max' }
        ];
        return labels[this.ruleData.randomize_images];
    }


    async saveRule(e): Promise<void> {
        e.preventDefault()

        this.wait = true;
        this.errorText = '';

        const rule = { ...this.ruleData };

        if (rule && rule.titles) {
            if (rule.titles) {
                rule.titles = rule.titles.map(title => title.text);
            }

            if (rule.cities) {
                rule.cities = rule.cities.map(city => city.text);
            }

            if (rule.cities_exclude) {
                rule.cities_exclude = rule.cities_exclude.map(cities_exclude => cities_exclude.text);
            }

            if (rule.titles_exclude) {
                rule.titles_exclude = rule.titles_exclude.map(titles_exclude => titles_exclude.text);
            }

            if (rule.ids_exclude) {
                rule.ids_exclude = rule.ids_exclude.map(ids_exclude => ids_exclude.text);
            }

            if (rule.sheets_exclude) {
                rule.sheets_exclude = rule.sheets_exclude.map(sheets_exclude => sheets_exclude.text);
            }
        }


        if (rule) {
            rule.table_id = this.$store.getters["ImagesTransform/getSelectedTableId"];
        }

        let userId = this.userId;
        const url = await this.$store.dispatch('getFullUrl', 'images-transform');
        const userIdQuery = userId ? "userId=" + userId : ''
        const querySymbol = this.$store.getters.hashQuery ? '&' : '?'

        await axios.put<void>(
            url + this.$store.getters.hashQuery + querySymbol + userIdQuery,
            rule,
            {validateStatus: (): boolean => true}
        )
            .then<void, never>((res: AxiosResponse<void>) => {
                const statusCode = res.request.status

                if (statusCode === 200) {
                    var response = JSON.parse(res.request.response);
                    if (response.status == 'error') {
                        this.errorText = response.text;
                    } else {
                        this.showModal = false;
                        this.$store.dispatch('ImagesTransform/setImagesTransformRules');

                        this.$emit('add-new-rule', this.ruleData.id);

                        if (this.ruleData.id === 0) {
                            this.showConfirmationModal = true;
                        }
                    }
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.wait = false;
            })
    }


    mounted() {
        this.$watch(
            () => this.selectedRowIndex,
            (value: number) => {
                if (value === -1) {
                    return;
                }

                if (value === 0) {
                    this.ruleData = JSON.parse(JSON.stringify(this.defaultRuleData));
                } else {
                    let ruleData = JSON.parse(JSON.stringify(this.getImagesTransformRule(value)));
                    ruleData.titles = createTags(Object.values(ruleData.titles))
                    ruleData.cities = createTags(Object.values(ruleData.cities))
                    ruleData.titles_exclude = createTags(Object.values(ruleData.titles_exclude))
                    ruleData.cities_exclude = createTags(Object.values(ruleData.cities_exclude))
                    ruleData.ids_exclude = createTags(Object.values(ruleData.ids_exclude))
                    ruleData.sheets_exclude = createTags(Object.values(ruleData.sheets_exclude))

                    if (ruleData.titles_exclude.length
                        || ruleData.cities_exclude.length
                        || ruleData.ids_exclude.length
                        || ruleData.sheets_exclude.length
                    ) {
                        this.excludeVisible = true;
                    }

                    this.ruleData = ruleData;
                }

                this.errorText = '';
                this.editFormVisible = false;

                this.showModal = !!this.ruleData;
            }
        );
    }
}
