
import { TableDetail } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import moment from "moment"
import { Component, Vue, Prop } from "vue-property-decorator"
import TDAddTable from "@/components/TableList/TableData/TDAddTable.vue";
import TDAddTableCategory from "@/components/TableList/TableData/TDAddTableCategory.vue";
import TDImportTableData from "@/components/TableList/TableData/TDImportTableData.vue";

@Component({
    components: {TDAddTableCategory, TDImportTableData, TDAddTable}
})
export default class TableDetailComponent extends Vue {
    @Prop() table!: TableDetail;

    public calendarValue?: string;

    wait = false

    created(): void {
        if (!this.table.dateExpired)
            return;

        this.calendarValue = moment.unix(this.table.dateExpired).format('YYYY-MM-DD');
    }

    async onSubmitTokens(): Promise<void> {
        if (this.wait) {
            return
        }
        this.wait = true;

        const url = await this.$store.dispatch('getFullUrl', 'tables/' + this.table.tableGuid + '/tokens');
        await axios.put<void>(
            url + this.$store.getters.hashQuery,
            this.table,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void>) => {
                const statusCode = res.request.status;
                if (statusCode === 200) {
                    // const token = res.data
                    //this.$store.dispatch('UwT/changeTableDateExpired', { userId, token })
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.wait = false)
    }


    showPopupGoogleSheetId(): void {
        this.table.googleSheetId =
            this.table.googleSheetId.replace(/.*\/d\/([0-9a-zA-Z-_]{33,44}).*/, '$1');

        const test = /[0-9a-zA-Z-_]{33,44}/.test(this.table.googleSheetId);

        if (!test) {
            this.$bvModal.msgBoxOk(
                'Вставьте полностью ссылку на таблицу или введите ее идентификатор в следующем формате: '
                    + '1xESmdIU5Zb6YLsh5XYcxXp7sUfxhQO_pEMzFafVl6U',
                {
                    title: 'Ошибка при заполнении данных',
                    centered: true
                }
            )
        } else {
            this.$bvModal.show('googleSheetId' + this.table.tableId);
        }
    }


    async onSubmitGoogleSheetId(): Promise<void> {
        if (this.wait) {
            return
        }
        this.wait = true;

        const url = await this.$store.dispatch('getFullUrl', 'tables/' + this.table.tableGuid + '/googleSheetId');
        await axios.put<void>(
            url + this.$store.getters.hashQuery,
            this.table,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void>) => {
                const statusCode = res.request.status;
                if (statusCode === 200) {
                    this.$store.commit('UPDATE_GOOGLE_SHEET_ID', { tableId: this.table.tableId,
                        googleSheetId: this.table.googleSheetId })
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.wait = false)
    }
}
