
import {Component, Vue, Prop} from "vue-property-decorator"
import User from "@/interfaces/User";

@Component
export default class TDUserToken extends Vue {
    @Prop() item!: User

    static componentName = "TDUserToken"

    get url(): string {
        return this.$store.state.appConfig.siteRoot + '?hash=' + this.item.token
    }
}
