
import { Component, Vue, Prop } from "vue-property-decorator"
import ImagesTransformRule from "@/interfaces/ImagesTransformRule";

@Component
export default class TDImagesTransformCities extends Vue {
    @Prop() item!: ImagesTransformRule

    static componentName = 'TDImagesTransformCities'

    get formattedCities(): string {
        if (!this.item) return '';
        if (this.item.any_cities) return 'Все';
        if (!this.item.cities || this.item.cities.length === 0) return '';
        return this.item.cities.join(', ');
    }

    get formattedCitiesExclude(): string {
        if (!this.item) return '';
        if (!this.item.cities_exclude || this.item.cities_exclude.length === 0) return '';
        return this.item.cities_exclude.join(', ');
    }
}
