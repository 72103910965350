
import Vue from "vue";
import Component from 'vue-class-component'
import HomeButtonsComponent from "@/components/HomeButtonsComponent.vue"

@Component({
    components: {
        HomeButtonsComponent
    }
})
export default class HomePage extends Vue {
    created(): void {
        document.title = 'XML Генератор';

        this.$store.dispatch('redirectToHome')
    }
}
