
import User from "@/interfaces/User"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDAdminText extends Vue {
    @Prop() item!: User
    @Prop() code!: keyof User

    static componentName = "TDAdminText"
}
