
import ButtonCreateMonitoringTask from "@/components/Buttons/ButtonCreateMonitoringTask.vue"
import MonitoringTaskEditComponent from "@/components/Monitoring/MonitoringTaskEditComponent.vue"
import TableList from "@/components/Monitoring/MonitoringTableList.vue"
import AvitoMonitoringTask from "@/interfaces/AvitoMonitoringTask";
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        TableList,
        ButtonCreateMonitoringTask,
        MonitoringTaskEditComponent
    }
})
export default class AvitoMonitoringTasksPage extends Vue {
    currentPage = 1
    perPage = 10
    perPageVariants = [
        { active: true, perPage: 10 },
        { active: false, perPage: 30 },
        { active: false, perPage: 50 },
        { active: false, perPage: 100 }
    ]

    get tasks(): AvitoMonitoringTask[] {
        return this.$store.getters['AvitoMonitoring/getTasks']
    }

    get tasksCount(): AvitoMonitoringTask[] {
        return this.$store.getters['AvitoMonitoring/getTasksCount']
    }

    async created(): Promise<void> {
        document.title = 'Задачи мониторинга'

        if (!this.$store.getters['Users/hasUser']) {
            return
        }

        this.$store.commit('AvitoMonitoring/SET_AVITO_MONITORING_PROJECT_ID', this.$router.currentRoute.params.projectid)

        this.$store.state.msg = ''
        this.$store.commit('SET_LOADING', true)
        Promise.all([this.$store.dispatch('AvitoMonitoring/setTasks')])
            .finally(() => this.$store.commit('SET_LOADING', false))
    }

    changePaginate(variant: { active: boolean, perPage: number }): void {
        this.perPageVariants.forEach((variant) => variant.active = false)
        this.perPage = variant.perPage
        variant.active = true
    }
}
