
import TableItem from "@/interfaces/TableItem"
import { Component, Vue, Prop } from "vue-property-decorator"
import axios, { AxiosResponse } from "axios"

@Component
export default class TDDeleteTable extends Vue {
    @Prop() item!: TableItem;

    static componentName = "TDAdminTableActions"

    wait = false

    get animation(): string {
        return this.wait ? 'cylon' : ''
    }

    async deleteUser (): Promise<void> {
        const { tableId, tableGuid } = this.item
        const entity = this.item.type

        const url = await this.$store.dispatch('getFullUrl', entity + '/' + tableGuid);
        const userId = this.$props.item.userId;
        await axios.delete(url + this.$store.getters.hashQuery)
            .then(() => {
                this.$store.dispatch('UwT/filterTablesInUsersWithTables', { tableId, userId })
            })
            .catch(err => console.error(err))
    }

    async updateTable(): Promise<void> {
        if (this.wait) {
            return
        }
        this.wait = true;

        await Promise.all([this.sendUpdateTable()])
            .finally(() => this.wait = false)
    }

    async sendUpdateTable(): Promise<void> {
        const data = this.item;
        const entity = this.item.type

        const url = await this.$store.dispatch('getFullUrl', entity + '/' + this.item.tableGuid);
        await axios.put<void>(
            url + this.$store.getters.hashQuery,
            data,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void>) => {
                const statusCode = res.request.status;
                if (statusCode === 200) {
                    // const token = res.data
                    // this.$store.dispatch('UwT/changeTableDateExpired', { userId, token })
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }
}
