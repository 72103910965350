export default [
    {
        name: 'Дата обработки',
        code: 'avitoRepubHistoryCreated_v2',
    },
    {
        name: 'День',
        code: 'avitoRepubHistoryPubDay_v2',
    },
    {
        name: 'Avito ID',
        code: 'avitoRepubHistoryAvitoId_v2',
    },
    {
        name: 'ID',
        code: 'avitoRepubHistoryAdId_v2',
    },
    {
        name: 'Дата публикации',
        code: 'avitoRepubHistoryAdDate_v2',
    },
    {
        name: 'Уникализация',
        code: 'avitoRepubHistoryRandomize_v2',
    },
    {
        name: 'Статистика объявления',
        code: 'avitoRepubHistoryStats_v2',
    },
    {
        name: 'Условия',
        code: 'avitoRepubHistoryConditions_v2',
    },
]
