
import RegisterComponent from "@/components/Auth/RegisterComponent.vue"
import router from "@/router"
import Vue from "vue";
import Component from 'vue-class-component'

@Component({
    components: {
        RegisterComponent
    }
})
export default class RegisterPage extends Vue {
    get invitation(): string {
        return router.currentRoute.params.invitation
    }

    async created(): Promise<void> {
        document.title = 'Регистрация'

        this.$store.state.msg = ''

        if (this.$store.getters['Users/hasUser']) {
            await this.$store.dispatch('redirectToHome')
        }

        window.ym(95006984, 'hit', '/register');
    }


}
