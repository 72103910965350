
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoServicesRule from "@/interfaces/AvitoServicesRule";

@Component
export default class TDAvitoServicesBudget extends Vue {
    @Prop() item?: AvitoServicesRule

    static componentName = 'TDAvitoServicesBudget'

    get budget(): string {
        if (this.item) {
            return (this.item.budget_type === 'summ' ? 'Бюджет: ' : 'Кол-во применений: ')
                + this.item.budget_amount
                + (this.item.budget_type === 'summ' ? ' руб.' : '')
        }

        return '';
    }

    get period(): string {
        if (!this.item) {
            return '';
        }

        const periodText = this.formatPeriodText(this.item.budget_period);
        return `Период: ${this.item.budget_period} ${periodText}`;
    }

    formatPeriodText(days: number): string {
        if (days % 10 === 1 && days !== 11) {
            return 'день';
        } else if ([2, 3, 4].includes(days % 10) && ![12, 13, 14].includes(days % 100)) {
            return 'дня';
        } else {
            return 'дней';
        }
    }
}
