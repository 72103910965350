import { render, staticRenderFns } from "./ArchivedUsersPage.vue?vue&type=template&id=4c9c1e7c&scoped=true"
import script from "./ArchivedUsersPage.vue?vue&type=script&lang=ts"
export * from "./ArchivedUsersPage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c9c1e7c",
  null
  
)

export default component.exports