
import {Vue, Prop, Component} from "vue-property-decorator";
import TableColumn from "@/interfaces/TableColumn"
import TableHeaderComponent from "@/components/TableList/TableHeaderComponent.vue";
import AvitoServicesRowComponent from '@/components/TableList/AvitoServicesRowComponent.vue'
import AvitoServicesRule from '@/interfaces/AvitoServicesRule';

@Component({
    components: {
        TableHeaderComponent,
        AvitoServicesTableListComponent,
        AvitoServicesRowComponent,
    },
})
export default class AvitoServicesTableListComponent extends Vue {
    @Prop() rules!: AvitoServicesRule[]
    @Prop() columns?: TableColumn[]
    @Prop() perPage?: number
    @Prop() currentPage?: number

    get itemsPaginated(): AvitoServicesRule[] {
        if (!!this.perPage && !!this.currentPage && (this.perPage < this.rules.length)) {
            const perPage = this.perPage

            const start = (this.currentPage - 1) * perPage
            const end = start + perPage

            return this.rules.slice(start, end)
        }

        return this.rules
    }
}
