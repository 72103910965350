
import TableItem from "@/interfaces/TableItem";
import axios, { AxiosResponse } from "axios";
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDAddMarketplace extends Vue {
    @Prop() userId?: number;

    static componentName = "TDAddMarketplace"

    creating = false;

    get text(): string {
        switch (this.$router.currentRoute.name) {
            case 'tables':
                return this.$store.state.text.buttonAddMarketplace
            case 'users':
                return this.$store.state.text.buttonAddMarketplaceAdmin
            default:
                return 'Создать таблицу'
        }
    }

    get icon(): string {
        return this.creating ? 'three-dots' : 'clipboard-plus'
    }

    get animation(): string {
        return this.creating ? 'cylon' : ''
    }

    async addMarketplace(): Promise<void> {
        const userId = this.userId;
        const newTable = await this.createMarketplace(userId);
        
        await this.handleView(newTable, userId);
    }
    
    async handleView(newTable: TableItem|null, userId?: number): Promise<void> {
        if (!newTable) {
            return
        }

        if (!userId) {
            await this.$store.dispatch('addNewMarketplace', newTable)
            return
        }

        await this.$store.dispatch('UwT/addTableInUsersWithTables', { userId, newTable })
    }

    async createMarketplace(userId?: number): Promise<TableItem | null> {
        if (this.creating) {
            return null
        }
        this.creating = true;

        let result: (TableItem | null) = null;
        const url = await this.$store.dispatch('getFullUrl', 'marketplaces');
        const userIdQuery = userId ? "&userId=" + userId : ''
        await axios.post(
            url + this.$store.getters.hashQuery + userIdQuery,
            null,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<TableItem>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 201:
                        result = res.data
                        break
                    case 403:
                        this.$store.state.alert = this.$store.state.text.tooManyMarketplaces
                        setTimeout(() => this.$store.state.alert = '', 5000)
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.creating = false;
                this.$store.state.newTableCreated = true;
            })
        return result;
    }
}
