
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoRepubRule from "@/interfaces/AvitoRepubRule";

@Component
export default class TDAvitoRepubParams extends Vue {
    @Prop() item?: AvitoRepubRule

    static componentName = 'TDAvitoRepubParams'
}
