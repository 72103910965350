
import { credentialsReset } from "@/types/auth"
import { ErrorResponse } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import { Vue, Component } from "vue-property-decorator";

@Component
export default class PasswordResetComponent extends Vue {
    credentials: credentialsReset = {
        email: '',
        password: '',
        password_confirmation: '',
        token: ''
    }

    errorText = ''
    errorEmail = ''

    get validationPassword(): boolean {
        const { password } = this.credentials
        return password === '' || password.length >= 8
    }

    get validationPasswordConformation(): boolean {
        const { password, password_confirmation } = this.credentials
        return password_confirmation === '' || password === password_confirmation
    }

    created(): void {
        const email = this.$router.currentRoute.query.email
        const token = this.$router.currentRoute.query.token

        if (!email || !token) {
            this.$store.commit('SET_MSG', 'Ссылка для восстановления пароля не корректна.')
            return
        }

        this.credentials.token = String(token)
        this.credentials.email = String(email)
    }

    async onSubmit(): Promise<void> {
        if (!this.validationPassword || !this.validationPasswordConformation) {
            return
        }

        const url = await this.$store.dispatch('getFullUrl', 'auth/passwordReset');
        await axios.post<void | ErrorResponse>(
            url,
            this.credentials,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void | ErrorResponse>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.$router.push({ name: 'login' })
                        break
                    case 422:
                        this.errorEmail = this.$store.state.validationErrors.emailAlreadyTaken
                        break
                    case 403:
                        this.errorText = 'Ссылка для восстановления пароля не корректна или истек срок ее действия'
                        break
                    case 404:
                        this.errorEmail = this.$store.state.validationErrors.userNotFound
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }
}
