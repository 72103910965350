export default [
    {
        name: 'Включено',
        code: 'avitoRepubEnable_v2',
    },
    {
        name: 'Заголовки',
        code: 'avitoRepubTitles_v2',
    },
    {
        name: 'Города',
        code: 'avitoRepubCities_v2',
    },
    {
        name: 'Исключить ID',
        code: 'avitoRepubIdsExclude_v2',
    },
    {
        name: 'Исключить вкладки',
        code: 'avitoRepubSheetsExclude_v2',
    },
    {
        name: 'Публикация',
        code: 'avitoRepubPubDays_v2',
    },
    {
        name: 'Уникализировать',
        code: 'avitoRepubRandomize_v2',
    },
    {
        name: 'Условия',
        code: 'avitoRepubConditions_v2',
    },
    {
        name: '',
        code: 'avitoRepubActions_v2',
    },
]
