
import { authToken, credentials } from "@/types/auth"
import { ErrorResponse } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import { Vue, Component } from "vue-property-decorator";
import NavLink from "@/interfaces/NavLink";

@Component
export default class LoginComponent extends Vue {
    credentials: credentials = {
        email: '',
        password: ''
    }

    errorText = ''
    wait = false

    async onSubmit(): Promise<void> {
        if (this.credentials.email === '' || this.credentials.password === '') {
            return
        }

        if (this.wait) {
            return
        }
        this.wait = true;

        const url = await this.$store.dispatch('getFullUrl', 'auth/login');
        await axios.post<authToken | ErrorResponse>(
            url,
            this.credentials,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.$store.dispatch('Auth/saveToken', res.data)

                        this.$store.dispatch('Users/setUserInfo')
                            .then(() => {
                                if (this.$store.getters['Users/userIsService']) {
                                    this.$store.dispatch('Auth/logout')
                                    this.$store.dispatch('Users/logout')
                                    this.$store.dispatch('setLinks', [])
                                    this.$store.commit('SET_SHOW_HEADER', false)

                                    this.errorText = 'Login as a service account is not allowed'
                                    return
                                }

                                if (!this.$store.getters['Users/hasUserInfo']) {
                                    this.$store.dispatch('Users/redirectToUserInfo')
                                    return
                                }

                                this.$store.dispatch('setHeaderLinks')
                                this.$store.commit('SET_SHOW_HEADER', true)
                                this.$store.dispatch('redirectToHome')
                            })
                        break
                    case 403:
                        if (this.checkBlocked(res.data)) {
                            this.errorText = this.$store.state.errorMessages.blocked
                            break
                        }

                        this.errorText = this.$store.state.errorMessages.wrongCredentials
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.wait = false)
    }

    checkBlocked(errorResponse: ErrorResponse): boolean {
        return !!errorResponse.error && !!errorResponse.error.code && (errorResponse.error.code === 'BLOCKED')
    }
}
