
import { Component, Vue, Prop } from "vue-property-decorator"
import { Getter, Mutation } from "vuex-class";
import AvitoServicesRule from "@/interfaces/AvitoServicesRule";
import { maska } from 'maska'
import axios, { AxiosResponse } from "axios";
import moment from "moment";

@Component({
    components: {},
    directives: {
        maska
    }
})
export default class TDAddServicesRule extends Vue {
    @Prop() userId?: number;

    @Getter("AvitoServices/getSelectedRowIndex") selectedRowIndex!: number;

    @Getter("AvitoServices/getAvitoServicesRule") getAvitoServicesRule!: (
        id: number
    ) => AvitoServicesRule | undefined;

    @Mutation("AvitoServices/SET_SELECTED_ROW_INDEX") setSelectedRowIndex!: (
        rowIndex: number
    ) => void;

    static componentName = "TDAddServicesRule"

    ruleData: AvitoServicesRule | null = null;
    showModal: boolean = false;
    wait: boolean = false;
    errorText: string = '';
    dateStart: Date | null = null;
    dateExpired: Date | null = null;

    defaultRuleData: AvitoServicesRule = {
        id: 0,
        table_id: 0,
        googleSheetId: "",
        city: "",
        title: "",
        range_param: "views",
        selection_type: "manual",
        services: {
            "package": "x2_1",
            "extra": [ "xl", "highlight" ]
        },
        date_start: moment().unix(),
        date_expired: moment().add(30, 'days').unix(),
        budget_type: "summ",
        budget_amount: 1,
        budget_period: 7,
        schedule: {
            "days": [ 1, 2, 3, 4, 5, 6, 7 ],
            "hours": [ 10, 11, 12 ],
        },
    };

    weekDaysButtons = [
        { id: 1, caption: "Пн" },
        { id: 2, caption: "Вт" },
        { id: 3, caption: "Ср" },
        { id: 4, caption: "Чт" },
        { id: 5, caption: "Пт" },
        { id: 6, caption: "Сб" },
        { id: 7, caption: "Вс" },
    ]


    newRule(): void {
        this.$store.commit("AvitoServices/SET_SELECTED_ROW_INDEX", 0);
    }


    resetRuleData(): void {
        this.$store.commit("AvitoServices/SET_SELECTED_ROW_INDEX", -1);
    }


    get modalTitle(): string {
        return this.ruleData && this.ruleData.id === 0 ? 'Новое правило' : 'Редактирование правила';
    }


    get disableSubmit(): boolean {
        return !this.ruleData?.title
            || !this.ruleData?.city
            || this.ruleData.schedule.days.length === 0
            || (this.ruleData.selection_type === 'manual' && this.ruleData.schedule.hours.length === 0)
            || this.wait;
    }


    limitValue(obj: any, propName: string, min: number, max: number): void {
        let value = Number(obj[propName]);

        if (isNaN(value)) {
            value = min;
        } else {
            if (value < min) {
                value = min;
            } else if (value > max) {
                value = max;
            }
        }

        obj[propName] = value;
    }


    isDaySelected(dayId) {
        return this.ruleData && this.ruleData.schedule.days.includes(dayId);
    }

    toggleDaySelection(dayId) {
        if (this.ruleData) {
            const index = this.ruleData.schedule.days.indexOf(dayId);
            if (index !== -1) {
                this.ruleData.schedule.days.splice(index, 1);
            } else {
                this.ruleData.schedule.days.push(dayId);
            }
        }
    }


    isHourSelected(hour) {
        return this.ruleData && this.ruleData.schedule.hours.includes(hour);
    }


    toggleHourSelection(hour) {
        if (this.ruleData) {
            const index = this.ruleData.schedule.hours.indexOf(hour);
            if (index !== -1) {
                this.ruleData.schedule.hours.splice(index, 1);
            } else {
                this.ruleData.schedule.hours.push(hour);
            }
        }
    }


    updateDateStart(newDate) {
        if (this.ruleData) {
            this.ruleData.date_start = moment(newDate, 'YYYY-MM-DD').unix();
        }
    }

    updateDateExpired(newDate) {
        if (this.ruleData) {
            this.ruleData.date_expired = moment(newDate, 'YYYY-MM-DD').unix();
        }
    }

    async saveRule(e): Promise<void> {
        e.preventDefault()

        this.wait = true;
        this.errorText = '';

        var rule = this.ruleData

        if (rule) {
            rule.table_id = this.$store.getters["AvitoServices/getSelectedTableId"];
        }

        const url = await this.$store.dispatch('getFullUrl', 'avito-services');
        await axios.put<void>(
            url + this.$store.getters.hashQuery,
            rule,
            {validateStatus: (): boolean => true}
        )
            .then<void, never>((res: AxiosResponse<void>) => {
                const statusCode = res.request.status
                if (statusCode === 200) {
                    var response = JSON.parse(res.request.response);
                    if (response.status == 'error') {
                        this.errorText = response.text;
                    } else {
                        this.showModal = false;
                        this.$store.dispatch('AvitoServices/setAvitoServicesRules');
                        this.$emit('add-new-rule');
                    }
                } else {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.wait = false)
    }


    mounted() {
        this.$watch(
            () => this.selectedRowIndex,
            (newValue: number, oldValue: number) => {
                if (newValue === -1) {
                    return;
                }

                if (newValue === 0) {
                    this.ruleData = JSON.parse(JSON.stringify(this.defaultRuleData));
                } else {
                    this.ruleData = JSON.parse(JSON.stringify(this.getAvitoServicesRule(newValue))) || null;
                }

                this.dateStart = this.ruleData ? new Date(this.ruleData.date_start * 1000) : null;
                this.dateExpired = this.ruleData ? new Date(this.ruleData.date_expired * 1000) : null;

                this.errorText = '';

                this.showModal = !!this.ruleData;
            }
        );
    }
}
