
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoRepubRule_v2 from "@/interfaces/AvitoRepubRule_v2";

@Component
export default class TDAvitoRepubPubDays_v2 extends Vue {
    @Prop() item!: AvitoRepubRule_v2

    static componentName = 'TDAvitoRepubPubDays_v2'

    get days(): string {
        return this.item.pub_days === 30 ? '30 дней' : '31 день';
    }
}
