
import TableItem from "@/interfaces/TableItem"
import { Component, Vue, Prop } from "vue-property-decorator"
import moment from "moment";

@Component
export default class TDAdminDate extends Vue {
    @Prop() item!: TableItem;

    static componentName = "TDAdminDate"

    get calendarValue(): string {
        if (!this.item.dateExpired) {
            return '';
        }
        return moment.unix(this.item.dateExpired).format('YYYY-MM-DD');
    }

    get calendarClass(): string {
        if (!this.item.dateExpired) {
            return ''
        }
        const timeZoneFix = 10800;
        const nowInDays = Math.floor(((Date.now() / 1000) + timeZoneFix) / 86400)
        const expiredTimeInDays = Math.floor((this.item.dateExpired + timeZoneFix) / 86400)
        const nowInDaysSubDay = nowInDays - 1

        if (
            (expiredTimeInDays <= nowInDays) &&
            (expiredTimeInDays > nowInDaysSubDay)
        ) {
            return 'date-alert'
        }
        if (expiredTimeInDays <= nowInDaysSubDay) {
            return 'date-danger'
        }

        return ''
    }

    updateItem(date: string): void {
        this.item.dateExpired = moment(date).unix()
    }
}
