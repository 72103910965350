
import router from "@/router"
import { ErrorResponse } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {}
})
export default class InvitationsPage extends Vue {
    async created(): Promise<void> {
        const invitation = router.currentRoute.params.hash
        await this.checkInvitation(invitation)
    }

    async checkInvitation(invitation: string): Promise<void> {
        this.$store.commit('SET_LOADING', true)

        const url = await this.$store.dispatch('getFullUrl', 'users/invitations/' + invitation);
        await axios.get<void | ErrorResponse>(
            url,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void | ErrorResponse>) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        router.push({ name: 'register', params: { invitation: invitation }, query: this.$route.query })
                        break
                    case 404:
                        this.$store.commit('SET_MSG', 'Некорректная пригласительная ссылка.')
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.$store.commit('SET_LOADING', false))
    }
}
