import RootState from "@/interfaces/RootState"
import TagsState from "@/interfaces/TagsState"
import Tag from "@/interfaces/Tag"
import axios, { AxiosResponse } from "axios"
import { Module } from "vuex"

const Tags: Module<TagsState, RootState> = {
    namespaced: true,
    state: {
        tagsAvito: [] as Tag[]
    },
    mutations: {
        SET_AVITO_TAGS(state: TagsState, tagsAvito: Tag[]): void {
            state.tagsAvito = tagsAvito
        },
        PUSH_NEW_TAG(state: TagsState, newTag: Tag): void {
            state.tagsAvito.push(newTag)
        },
        SORT_AVITO_TAGS_TABLE_BY(
            state: TagsState,
            sort: keyof Tag
        ): void {
            state.tagsAvito.sort((a: Tag, b: Tag) => {
                const bElement = b[sort];
                const aElement = a[sort];
                return ((bElement ? bElement : 0) > (aElement ? aElement : 0)) ? -1 : 1;
            })
        },
    },
    actions: {
        async setTagsAvito({ commit, dispatch, rootState, rootGetters }): Promise<void> {
            const url = await dispatch('getFullUrl', 'tags/avito', { root: true });
            await axios.get<Tag[]>(
                url + rootGetters.hashQuery,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse<Tag[]>) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 200:
                            commit('SET_AVITO_TAGS', res.data)
                            commit('SORT_AVITO_TAGS_TABLE_BY', 'id')
                            rootState.hashValidated = true;
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        },

        addEmptyTag({ commit }): void {
            commit('PUSH_NEW_TAG', { id: 0, tag: "", comment: "", colName: "", innerTag: "" })
        },
    },
    getters: {
        getTagsAvito(state: TagsState): Tag[] {
            return state.tagsAvito
        },
        issetTagsAvitoNewTag(state: TagsState): boolean {
            return state.tagsAvito.map(el => el.id).includes(0);
        },
    }
}

export default Tags
