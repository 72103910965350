
import ProfitPartnersWidget from "@/components/Finances/ProfitPartnersWidget.vue"
import ProfitWidget from "@/components/Finances/ProfitWidget.vue"
import ReferralsWidget from "@/components/Finances/ReferralsWidget.vue"
import TableList from "@/components/Finances/TableList.vue"
import WalletWidget from "@/components/Finances/WalletWidget.vue"
import TableColumn from "@/interfaces/TableColumn"
import { adminTransaction } from "@/types/transactions"
import { Component, Vue } from 'vue-property-decorator';
import AdminUser from "@/interfaces/AdminUser";

@Component({
    components: {
        ReferralsWidget,
        ProfitWidget,
        TableList,
        WalletWidget,
        ProfitPartnersWidget,
    }
})
export default class AdministratorsUsersPage extends Vue {
    currentPage = 1
    perPage = 10
    perPageVariants = [
        { active: true, perPage: 10 },
        { active: false, perPage: 30 },
        { active: false, perPage: 50 },
        { active: false, perPage: 100 }
    ]
    loading = false
    timeout?: number
    filteredTransactions: adminTransaction[] = [];

    get icon(): string {
        return this.loading ? 'arrow-clockwise' : 'search'
    }

    get animation(): string {
        return this.loading ? 'spin' : ''
    }

    get rows(): number {
        return this.$store.getters['Users/getAdminUsers'].length
    }

    get columns(): TableColumn[] {
        return this.$store.state.columns.adminUser
    }

    get items(): AdminUser[] {
        return this.$store.getters['Users/getAdminUsers']
    }

    async created(): Promise<void> {
        document.title = 'Администраторы и менеджеры'

        this.$store.dispatch('Users/redirectIfNotAdmin')
            .then(async (res: boolean) => {
                if (res) {
                    return
                }

                this.$store.state.msg = ''
                this.$store.commit('SET_LOADING', true)
                await Promise.all([
                    this.$store.dispatch('Users/setAdminUsers'),
                ])
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.$store.commit('SET_LOADING', false)
            })
    }

    changePaginate(variant: { active: boolean, perPage: number }): void {
        this.perPageVariants.forEach((variant) => variant.active = false)
        this.perPage = variant.perPage
        variant.active = true
    }
}
