
import {Component, Vue, Prop} from "vue-property-decorator"
import User from "@/interfaces/User";

@Component
export default class TDShowTable extends Vue {
    @Prop() item!: User;

    static componentName = "TDShowTable"

    opened = false;

    toggleTable(item: User): void {
        this.$store.dispatch('UwT/toggleShowTable', item.userId)
        this.opened = !this.opened;
    }

    get iconClass(): string {
        return this.opened ? 'chevron-up' : 'chevron-down'
    }
}
