
import AvitoMonitoringTask from "@/interfaces/AvitoMonitoringTask"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDMonitoringNotifications extends Vue {
    @Prop() index!: number;
    @Prop() item!: AvitoMonitoringTask;

    static componentName = "TDMonitoringNotifications"

    get activeTitle(): string {
        return this.item['notifications'] ? '+' : '-'
    }

    get iconClass(): string {
        return this.item['notifications'] ? 'success' : 'secondary';
    }
}
