
import TableItem from "@/interfaces/TableItem"
import axios, { AxiosResponse } from "axios"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDTableNotes extends Vue {
    @Prop() item!: TableItem;

    static componentName = "TDTableNotes"

    wait = false

    get animation(): string {
        return this.wait ? 'cylon' : ''
    }

    async onSubmit(): Promise<void> {
        const data = this.item;
        const entity = this.item.type

        const url = await this.$store.dispatch('getFullUrl', entity + '/' + this.item.tableGuid);
        await axios.put<void>(
            url + this.$store.getters.hashQuery,
            data,
            { validateStatus: (): boolean => true }
        )
            .then<void, never>((res: AxiosResponse<void>) => {
                const statusCode = res.request.status;
                if (statusCode !== 200) {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }
}
