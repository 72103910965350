
import { Component, Vue, Prop } from "vue-property-decorator"
import AvitoRepubRule from "@/interfaces/AvitoRepubRule";

@Component
export default class TDAvitoRepubCity extends Vue {
    @Prop() item?: AvitoRepubRule

    static componentName = 'TDAvitoRepubCity'

    get city(): string {
        let city = this.item ? this.item.city : '';
        const maxLength = 100;

        if (city.length > maxLength) {
            const commaIndex = city.lastIndexOf(',', maxLength);
            if (commaIndex !== -1) {
                city = city.substring(0, commaIndex) + ' ...';
            } else {
                city = city.substring(0, maxLength) + ' ...';
            }
        }

        return city;
    }
}
