import RootState from "@/interfaces/RootState"
import AvitoServicesState from "@/interfaces/AvitoServicesState"
import AvitoServicesRule from "@/interfaces/AvitoServicesRule"
import axios, { AxiosResponse } from "axios"
import { Module } from "vuex"

const AvitoServicesRules: Module<AvitoServicesState, RootState> = {
    namespaced: true,
    state: {
        avitoServicesRules: [] as AvitoServicesRule[],
        selectedRowIndex: null,
        selectedTableId: null
    },
    mutations: {
        SET_AVITO_SERVICES_RULES(state: AvitoServicesState, avitoServicesRules: AvitoServicesRule[]): void {
            state.avitoServicesRules = avitoServicesRules
        },
        SET_SELECTED_ROW_INDEX(state, rowIndex) {
            state.selectedRowIndex = rowIndex;
        },
        PUSH_NEW_RULE(state: AvitoServicesState, newRule: AvitoServicesRule): void {
            state.avitoServicesRules.push(newRule)
        },
        SET_SELECTED_TABLE_ID(state: AvitoServicesState, tableId: number | null): void {
            state.selectedTableId = tableId;
        },
    },
    actions: {
        async setAvitoServicesRules({ commit, dispatch, rootState, rootGetters }): Promise<void> {
            const url = await dispatch('getFullUrl', 'avito-services', { root: true });
            await axios.get<AvitoServicesRule[]>(
                url + rootGetters.hashQuery,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse<AvitoServicesRule[]>) => {
                    const statusCode = res.request.status;
                    switch (statusCode) {
                        case 200:
                            commit('SET_AVITO_SERVICES_RULES', res.data);
                            rootState.hashValidated = true;
                            break;
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true });
                    }
                })
                .catch(err => console.error(err));
        },
    },
    getters: {
        getAvitoServicesRules(state: AvitoServicesState): AvitoServicesRule[] {
            return state.avitoServicesRules
        },
        getAvitoServicesRule: (state) => (id: number): AvitoServicesRule | undefined => {
            return state.avitoServicesRules.find((rule) => rule.id === id);
        },
        getSelectedRowIndex(state: AvitoServicesState): number | null {
            return state.selectedRowIndex
        },
        getSelectedTableId(state: AvitoServicesState): number | null {
            return state.selectedTableId
        },
    }
}

export default AvitoServicesRules
