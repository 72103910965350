
import {Vue, Prop, Component} from "vue-property-decorator";
import TableColumn from "@/interfaces/TableColumn"
import TableHeaderComponent from "@/components/TableList/TableHeaderComponent.vue";
import AvitoRepubRule from '@/interfaces/AvitoRepubRule';
import AvitoRepubRowComponent_v2 from "@/components/TableList/AvitoRepubRowComponent_v2.vue";

@Component({
    components: {
        TableHeaderComponent,
        AvitoRepubTableListComponent_v2,
        AvitoRepubRowComponent_v2,
    },
})
export default class AvitoRepubTableListComponent_v2 extends Vue {
    @Prop() rules!: AvitoRepubRule[]
    @Prop() columns?: TableColumn[]
    @Prop() perPage?: number
    @Prop() currentPage?: number

    get itemsPaginated(): AvitoRepubRule[] {
        if (!!this.perPage && !!this.currentPage && (this.perPage < this.rules.length)) {
            const perPage = this.perPage

            const start = (this.currentPage - 1) * perPage
            const end = start + perPage

            return this.rules.slice(start, end)
        }

        return this.rules
    }
}
