
import { Vue, Component } from "vue-property-decorator";

@Component
export default class ReferralsWidget extends Vue {
    get title(): string {
        if (this.$store.getters['Users/userIsAdmin']
            || this.$store.getters['Users/userIsAdminLite']
            || this.$store.getters['Users/userIsManager']
        ) {
            return 'Партнеры / Активные'
        }

        return 'Рефералы / Активные'
    }

    get activeReferrals(): number {
        return this.$store.getters['Referrals/getActiveReferralsCounter']
    }

    get referrals(): number {
        return this.$store.getters['Referrals/getReferralsCounter']
    }

    created(): void {
        this.$store.dispatch('Referrals/setReferralsCounters')
    }
}
