
import { Component, Vue } from "vue-property-decorator"

@Component
export default class ButtonShowTables extends Vue {
    opened = false;

    showTables(): void {
        this.opened = !this.opened
        this.$store.dispatch('UwT/toggleShowTableAll', this.opened)
    }

    get iconClass(): string {
        return this.opened ? 'chevron-up' : 'chevron-down'
    }
}
