export default [
    {
        name: '#',
        code: 'index',
    },
    {
        name: 'Таблица',
        code: 'googleSheetUrl',
    },
    {
        name: 'Дата оплаты',
        code: 'dateExpired',
    },
    {
        name: 'Тариф',
        code: 'maxAds',
    },
    {
        name: 'Примечание',
        code: 'marketplacesNotes',
    },
]
