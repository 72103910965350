
import MessengersItem from "@/interfaces/MessengersItem";
import axios, { AxiosResponse } from "axios"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDMessengerNotes extends Vue {
    @Prop() item!: MessengersItem;

    static componentName = "TDMessengerNotes"

    wait = false

    async onSubmit(): Promise<void> {
        const url = await this.$store.dispatch('getFullUrl', 'messengers/' + this.item.id + '/comment');

        await axios.put(url, this.item)
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                if (statusCode !== 201) {
                    this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }
}
