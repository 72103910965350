
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class VideosPage extends Vue {
    created(): void {
        document.title = 'Видеоинструкции'
        this.$store.state.msg = ''
    }
}
